import { pageHandler } from "@/lib/pages-handler";
import { useRouter } from "next/router";
import { getAwardsById } from "@/dato-api/awards";
import { getRatingsById } from "@/dato-api/ratings";
import { getProduct, getPortfolioDetails } from "@/dato-api/product";
import { getProductIdBySlug } from "@/dato-api/product";
import dynamic from "next/dynamic";
import {
	buildHrefLangsList,
	buildPortfolioSlug,
	getPortfolioSlug,
	getRealPortfolioSlug,
} from "@/lib/functions";
import { useLayoutEffect, useRef, useState } from "react";
import Blocks from "@/components/Organisms";
import { ProductDetail } from "@/components/Organisms/Product";
import { fetchComponentRelations } from "@/lib/api";
import { SectionFeaturedProduct } from "@/components/Organisms/ImageVideo";
import HrefLangs from "@/components/Atoms/hrefLangs";
import OgUrl from "@/components/Atoms/ogUrl";

const HeroProduct = dynamic(() =>
	import("@/components/Organisms/Hero/HeroProduct").then((c) => c.HeroProduct)
);

const ProductRelated = dynamic(() =>
	import("@/components/Organisms/Product/ProductRange").then(
		(c) => c.ProductRelated
	)
);

const CollectionRelated = dynamic(() =>
	import("@/components/Organisms/Product/ProductRange").then(
		(c) => c.CollectionRelated
	)
);

export default function WinePage({ data, relations }) {
	const router = useRouter();
	const { query } = router;
	const { locale } = router;
	const [detailSize, setDetailSize] = useState();
	const detailRef = useRef(null);

	const portfolioSlug = getPortfolioSlug(data.brandsProduct, locale);

	if (query.slug?.length > 1) {
		//Get main product collection & product //
		const collectionProduct = data.brandsProduct?.productsCollection?.filter(
			(collection) =>
				collection.productsDefinitionCollection.some(
					(product) => product.product.id == data.product.id
				)
		);

		var cRelated = collectionProduct?.[0]?.productsDefinitionCollection?.filter(
			(x) => x.product.id == data.product.id
		);

		/* console.log("cRELATED", cRelated); */

		//Get main product list related //
		var related =
			data.brandsProduct?.productsList?.[0]?.productsDefinition?.filter(
				(x) => x.product.id == data.product.id
			);
		/* console.log("RELATED", related); */

		// get Inner product list related parent product //
		var innerRelated =
			data.brandsProduct?.productsList?.[0]?.productsDefinition?.filter((x) =>
				x.relatedProducts.some((y) => y.id == data.product.id)
			);

		// filter products collection to show based on hideHome value //
		const collectionToSlider = data?.brandsProduct?.productsCollection?.filter(
			(x) => x.hideHome == false
		);

		var collectionRelated = collectionToSlider?.filter((x) =>
			x.productsCollection.some((y) => y.id === data.product.id)
		);

		/* const listToSlider =
			data.brandsProduct?.productsList?.[0]?.productsDefinition?.filter(
				(x) => x.hide == related[0]?.hide
			); */

		var allMainProducts =
			data.brandsProduct?.productsList?.[0]?.productsDefinition?.filter(
				(x) => x.product.id !== data.product.id
			);

		var mapperMain = allMainProducts?.map((x) => x.product);
	}

	useLayoutEffect(() => {
		setDetailSize(detailRef.current.clientHeight);
	}, []);

	const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);
	const randomRelated = shuffle(data.brandsProduct?.relatedSections).slice(
		data.brandsProduct?.relatedSections?.length - 1
	);

	const buyLink = data.product?.sku?.[0]?.buyNow?.filter((link) =>
		link?.url?.includes("http")
	);

	let productDescriptionToShow = "";

	if (data.brandsProduct.showDescription) {
		productDescriptionToShow = data.product?.productDescriptionShort;
	} else {
		productDescriptionToShow = data.product?.productDescription;
	}

	return (
		<>
			<HrefLangs links={data.hrefLangs} />
			<OgUrl
				url={
					data.hrefLangs.filter((link) => link.hrefLang === locale)?.[0]?.href
				}
			/>
			<HeroProduct
				locale={locale}
				id={data.product?.id}
				addLogo={[
					related?.[0]?.addLogoToProduct ?? cRelated?.[0]?.addLogoToProduct,
					related?.[0]?.companionAsset ?? cRelated?.[0]?.companionAsset,
				]}
				swapTitle={[
					related?.[0]?.swapProductTitleWithLogo ??
						cRelated?.[0]?.swapProductTitleWithLogo,
					related?.[0]?.logoLabel ?? cRelated?.[0]?.logoLabel,
					related?.[0]?.logoAsset ?? cRelated?.[0]?.logoAsset,
				]}
				showUmbrellaBrand={data.brandsProduct?.showUmbrellaBrand}
				umbrellaTitle={data.product.productBrand.title}
				buttonConfig={data.brandsProduct?.productDetailButtonConfig}
				hideDescription={
					related?.[0]?.hideDescription ?? cRelated?.[0]?.hideDescription
				}
				hideTechnicalSheet={data.product.outsystemVersionId}
				ctaGeo={data.brandsProduct.ctaGeolocation}
				color={data.brandsProduct.heroTextColor?.color}
				title={data.product?.productName}
				description={productDescriptionToShow}
				showImage={data?.brandsProduct?.showImage}
				bg={data.product?.productBrand.image}
				definitionBg={related ?? cRelated}
				innerRelated={innerRelated}
				image={
					data?.product?.factSheetImage ||
					data.product?.sku[0]?.skuImageGallery[0]
				}
				download={data.product?.technicalSheet}
				btnLabel={buyLink?.[0]?.label?.label}
				buy={buyLink?.[0]?.url}
				iframeBuy={data.product?.sku[0]?.buyNow}
				pixel={[
					data.siteConfig?.gtmId,
					data.siteConfig?.googleAnalyticsId,
					data.siteConfig?.metaPixelId,
				]}
				follow={data.brandsProduct.follow}
				detailSize={detailSize}
				bgColor={
					data.brandsProduct.detailBgColor ||
					related?.[0]?.color ||
					cRelated?.[0]?.color
				}
				align={data.brandsProduct.mobileProductAlignment}
				mobileColor={data.brandsProduct.mobileProductDetailColor}
				showMyCertificates={
					(related?.[0]?.showCertificates ?? cRelated?.[0]?.showCertificates) ||
					innerRelated?.[0]?.showCertificates
				}
				isVeganCertified={data.product?.veganCertified}
				icon={[
					data.brandsProduct.buyButtonIcon,
					data.brandsProduct.technicalSheetButtonIcon,
					data.brandsProduct.veganCertificateButtonIcon,
				]}
				overlay={[
					data.brandsProduct.addShadowOverlay,
					data.brandsProduct.overlayShadowColor,
				]}
				underline={[
					data.brandsProduct.addTitleUnderline,
					data.brandsProduct.customUnderlineAsset,
					data.brandsProduct.underlineColor,
				]}
			/>

			<ProductDetail
				buttonConfig={data.brandsProduct?.productDetailButtonConfig}
				locale={locale}
				query={query}
				portfolioSlug={portfolioSlug}
				related={related ?? cRelated}
				relatedConf={[
					data.brandsProduct?.addCustomRelatedLinks,
					data.brandsProduct?.relatedLinks,
				]}
				innerRelated={innerRelated}
				forwardedRef={detailRef}
				highlight={
					related?.[0]?.highlightColor ?? cRelated?.[0]?.highlightColor
				}
				gallery={[
					related?.[0]?.addProductGallery ?? cRelated?.[0]?.addProductGallery,
					related?.[0]?.gallery ?? cRelated?.[0]?.gallery,
					related?.[0]?.hideGalleryLabel ?? cRelated?.[0]?.hideGalleryLabel,
				]}
				dropdown={data.brandsProduct.showRelatedDropdownMenu}
				bgDropdownColor={data.brandsProduct.backgroundDropdownColor}
				iconDropdownColor={data.brandsProduct.iconDropdownColor}
				bgColor={
					data.brandsProduct.detailBgColor ||
					related?.[0]?.color ||
					cRelated?.[0]?.color
				}
				options={data.brandsProduct}
				specific={related?.[0]?.color ?? cRelated?.[0]?.color}
				color={data.brandsProduct.productDetailTextColor?.color}
				titleColor={data.brandsProduct.productDetailTitlesColor?.color}
				tasting={data.product?.tasting}
				harvestYear={data.product?.harvestYear}
				winemaking={data.product?.winemaking}
				winemaker={data?.product?.winemakerBlender}
				maturation={data.product?.maturation}
				store={data.product?.store}
				serve={data.product?.serve}
				enjoy={data?.product?.enjoy}
				productOrigin={data.product?.productOrigin}
				productRegion={data.product?.productRegion}
				productCountry={data.product?.productCountry}
				productType={data.product?.productTypeGeneric}
				productColour={data.product?.productColour}
				productCategory={data.product?.productCategory}
				alcoholContent={data.product?.alcoholContentByVolume20}
				totalSugars={data.product?.totalSugars}
				ph={data.product?.ph}
				alcoholVolume={data.product?.alcoholContentByVolume20Specification}
				acidity={data.product?.totalAcidityTartaricAcid}
				aciditySpecification={
					data.product?.totalAcidityTartaricAcidSpecification
				}
				id={data.product.id}
				sugars={data.product?.totalSugars}
				sugarsSpecification={data.product?.totalSugarsSpecification}
				detailBg={data.brandsProduct.nutritionalTableBackground}
				bottles={data.product?.producedBottles}
				vegan={data.product?.suitableForVegan}
				vegetarian={data.product?.suitableForVegetarian}
				sulfites={data.product?.containsSulfites}
				specificNutritional={[
					data.brandsProduct.showProductSpecificNutritionalInformation,
					data.product?.alcoholFor60ml,
					data.product?.sugarsFor60ml,
					data.product?.energyValueKcalFor60ml,
					data.product?.energyValueKjFor60ml,
					data.product?.alcoholFor30ml,
					data.product?.sugarsFor30ml,
					data.product?.energyValueKcalFor30ml,
					data.product?.energyValueKjFor30ml,
				]}
				nutritionalInfoFor100={[
					data.product?.alcohol,
					data.product?.sugars,
					data.product?.energyValueKcal,
					data.product?.energyValueKj,
					data.brandsProduct.nutritionalTableLayout,
					data.brandsProduct.nutritionalTableBackground,
				]}
				energetic={data.brandsProduct.showEnergeticInformation}
				underline={[
					data.brandsProduct.addTitleUnderline,
					data.brandsProduct.customUnderlineAsset,
					data.brandsProduct.underlineColor,
				]}
				awardsConf={[
					data.brandsProduct?.accoladesShowAwards,
					data.brandsProduct?.accoladesShowRatings,
					data.brandsProduct?.accoladesAwardsType,
					data.brandsProduct?.awardsYear,
					data.brandsProduct?.accoladesRatingsYear,
					data.brandsProduct?.accoladesRatings100,
					data.brandsProduct?.accoladesRatings20,
					data.brandsProduct?.accoladesSlug,
					collectionRelated?.length,
					data.brandsProduct?.showMediaPublicationLogo,
				]}
				awards={data.awards}
				ratings={data.ratings}
				btnColor={data.brandsProduct?.productButtonTextColor}
				btnStyle={data.brandsProduct?.productButtonStyleColor}
				btnType={data.brandsProduct?.buttonType}
				btnIcon={[
					data.brandsProduct?.addIcon,
					data.brandsProduct?.icon?.img,
					data.brandsProduct?.iconColor,
				]}
				customData={[
					related?.[0]?.overwriteDefaultData ??
						cRelated?.[0]?.overwriteDefaultData,
					related?.[0]?.customData ?? cRelated?.[0]?.customData,
				]}
			/>

			{data.brandsProduct.relatedSectionPosition != "default" && (
				<>
					{randomRelated.length > 0 && (
						<Blocks sections={randomRelated} relations={relations}></Blocks>
					)}
				</>
			)}

			{data.brandsProduct.showImage && (
				<SectionFeaturedProduct
					bgColor={data.brandsProduct.detailBgColor}
					featuredImage={data.product?.productBrand}
				/>
			)}

			{data.brandsProduct.relatedProductsDependOnGeolocation ? (
				<ProductRelated
					id={data.product.id}
					products={related ?? cRelated}
					allProducts={data.brandsProduct.geo}
					innerRelated={innerRelated}
					data={data.brandsProduct}
					portfolioSlug={portfolioSlug}
					layout={[
						data.brandsProduct?.relatedProductsSliderLayout,
						data.brandsProduct?.relatedLayoutReference,
					]}
				/>
			) : (
				<>
					{data.brandsProduct?.showRelated && (
						<>
							{collectionRelated?.length > 0 ? (
								<CollectionRelated
									id={data.product.id}
									products={collectionRelated}
									innerRelated={innerRelated}
									data={data.brandsProduct}
									portfolioSlug={portfolioSlug}
									layout={[
										data.brandsProduct?.relatedProductsSliderLayout,
										data.brandsProduct?.relatedLayoutReference,
									]}
								/>
							) : (
								<ProductRelated
									id={data.product.id}
									products={related}
									allProducts={mapperMain}
									innerRelated={innerRelated}
									data={data.brandsProduct}
									portfolioSlug={portfolioSlug}
									buttonConfig={data.brandsProduct?.productDetailButtonConfig}
									layout={[
										data.brandsProduct?.relatedProductsSliderLayout,
										data.brandsProduct?.relatedLayoutReference,
									]}
								/>
							)}
						</>
					)}
				</>
			)}

			{data.brandsProduct.relatedSectionPosition == "default" && (
				<>
					{randomRelated.length > 0 && (
						<Blocks sections={randomRelated} relations={relations}></Blocks>
					)}
				</>
			)}
		</>
	);
}

export async function _getStaticProps(
	context,
	pageType,
	serverSideTranslations,
	siteConfig
) {
	const preview = context.preview !== undefined ? context.preview : false;
	const currentSlug = context.params.slug[context.params.slug.length - 1];
	const portfolioSlug = context.params.slug[0];
	const portfolioRealSlug = getRealPortfolioSlug(portfolioSlug, context.locale);

	const portfolioDetail = await getPortfolioDetails(
		context.locale,
		preview,
		siteConfig.siteConfiguration.id,
		portfolioRealSlug
	);

	const mainProduct = await getProduct(currentSlug, context.locale, preview);

	const { product } = await getProductIdBySlug(
		context.locale,
		preview,
		currentSlug
	);

	const awards = await getAwardsById(context.locale, preview, product?.id);
	const ratings = await getRatingsById(context.locale, preview, product?.id);

	const pageData = await pageHandler(context, serverSideTranslations, [], "");

	const portfolioSlugs = buildPortfolioSlug(
		portfolioDetail.brandsProduct?.slug,
		portfolioDetail.brandsProduct?.__typename
	);

	mainProduct?.product?._allSlugLocales.map((productSlug) => {
		mainProduct?.product?.productTypeSpecific._allSlugLocales.map(
			(typeSpecificSlug) => {
				if (typeSpecificSlug.locale === productSlug.locale) {
					const portfolio = portfolioSlugs?.[0]?._allSlugLocales.filter(
						(p) => p.locale === typeSpecificSlug.locale
					)?.[0];
					productSlug.value = `${portfolio.value}/${typeSpecificSlug.value}/${productSlug.value}`;
				}
			}
		);
	});

	const _allSlugLocales = mainProduct?.product?._allSlugLocales;
	const hrefLangs = buildHrefLangsList(
		_allSlugLocales,
		mainProduct?.__typename
	);

	const relations = await fetchComponentRelations(
		{ sections: portfolioDetail.brandsProduct?.relatedSections },
		context.locale,
		context.preview
	);

	return {
		props: {
			relations: relations,
			siteConfig: siteConfig.siteConfiguration,
			...pageData.sst,
			data: {
				...pageData.data,
				...mainProduct,
				...portfolioDetail,
				_allSlugLocales,
				hrefLangs,
				awards,
				ratings,
				_allSlugLocales: mainProduct?.product?._allSlugLocales,
				siteConfig: siteConfig.siteConfiguration,
			},
			preview: pageData.preview,
			pageType,
		},
		revalidate: 3600,
	};
}
