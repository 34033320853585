import { useContext } from "react";
import NotificationContext from "@/lib/functions";

import { Title } from "@/atoms/Title";
import { MultiPurposeParagraph, Paragraph } from "@/components/Atoms/Paragraph";

interface Props {
  title: string;
  message: string;
  status: string;
  color: any[any];
}

function Notification({ title, message, status, color }: Props): JSX.Element {
  const notificationCtx: any = useContext(NotificationContext);

  if (status === "success") {
    return (
      <div className="text-center" onClick={notificationCtx.hideNotification}>
        {/* <SuccessIcon className="mb-6" color="#171E36" /> */}
        <Title tag="h3" data={title} color={color} layout="fixedCenter" />
        <MultiPurposeParagraph data={message} color={color} />
      </div>
    );
  }

  if (status === "wait") {
    return (
      <div className="text-center" onClick={notificationCtx.hideNotification}>
        <Title tag="h3" data={title} color={color} layout="fixedCenter" />
        <MultiPurposeParagraph data={message} color={color} />
      </div>
    );
  }

  if (status === "error") {
    return (
      <div onClick={notificationCtx.hideNotification}>
        <Title tag="h3" data={title} color={color} layout="fixedCenter" />
        <MultiPurposeParagraph data={message} color={color} />
      </div>
    );
  } else {
    return (
      <div onClick={notificationCtx.hideNotification}>
        <h2>{title}</h2>
        <p>{message}</p>
      </div>
    );
  }
}

export default Notification;
