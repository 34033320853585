import { pageHandler } from "@/lib/pages-handler";
import { getArticlePage, getArticleConfig } from "@/dato-api/article";
import { buildHrefLangsList, buildPortfolioSlug } from "@/lib/functions";
import { fetchComponentRelations } from "@/lib/api";
import { ButtonMaster } from "@/components/Atoms/Buttons";
import HeroArticle from "@/organisms/Hero/HeroArticle";
import ArticleContent from "@/organisms/Articles";
import Blocks from "@/components/Organisms";
import { useRouter } from "next/router";
import Hero from "@/components/Organisms/Hero";
import CustomImage from "@/components/Atoms/Image";

export default function Article({ data, relations }) {
	const { locale } = useRouter();

	//Passing the "Content" and using configuration from Hero Configuration
	const heroArticle = {
		__typename: "SectionHeroImageRecord",
		title: data.title,
		image: data.image,
		label: data.categories,
		imageGeo: [null], //TODO: Fix this ?!?
		...data.pageConfig?.brandArticle.detailHeroArticle,
	};

	//Filter the array of relations to remove the current article
	const filteredRelations =
		relations.SectionBrandArticleRecord?.allArticles.filter(
			(article) => article.slug !== data.slug
		);

	//Create an Object Called SectionBrandArticleRecord with object allArticles with the filtered array
	const newRelations = {
		SectionBrandArticleRecord: {
			allArticles: filteredRelations,
		},
	};

	return (
		<div
			style={{
				backgroundColor:
					data.pageConfig?.brandArticle?.backgroundColor?.color?.hex,
			}}
		>
			{data.pageConfig.brandArticle.addHeroImage && <Hero data={heroArticle} />}

			<ArticleContent
				locale={locale}
				date={data._publishedAt}
				data={data.content}
				author={data.author}
				config={data.pageConfig?.brandArticle}
				hideDate={data.pageConfig?.brandArticle.hideAuthorAndDate}
				dateType={data.pageConfig?.brandArticle?.dateType}
				addReturnButton={data.pageConfig?.brandArticle.addReturnButton}
				returnCta={data.pageConfig?.brandArticle.returnCta}
			/>
			<div className="relative flex flex-col gap-14 w-full items-center">
				{data.downloadReadCta &&
					data.pageRelated.map((related, index) => {
						return (
							<>
								<ButtonMaster
									key={index}
									anim={"slideLeft"}
									type={related.buttonStyle}
									cta={related.typeColor?.color}
									color={related.labelColor?.color}
									customIcon={related.icon?.img}
									customColor={related.iconColor?.color}
									link={` ${
										related.page
											? ` ${routerHandler({
													typename: related.__typename,
													slug: pageRelatedLink(
														related.page,
														locale,
														related.sectionAnchor?.title
													),
													locale: locale,
											  })}`
											: `${related.externalUrl}`
									}`}
								>
									{related?.buttonLabel?.label}
								</ButtonMaster>
							</>
						);
					})}
				{data.pageConfig?.brandArticle.addParalaxImage && (
					<CustomImage
						data={data.pageConfig?.brandArticle.parallaxImage}
						className="h-32 object-cover"
					/>
				)}
			</div>
			{data.pageConfig?.brandArticle.relatedSections && (
				<Blocks
					sections={data.pageConfig?.brandArticle.relatedSections}
					relations={newRelations}
				></Blocks>
			)}
		</div>
	);
}

export async function _getStaticProps(
	context,
	pageType,
	serverSideTranslations,
	siteConfig
) {
	const preview = context.preview !== undefined ? context.preview : false;

	const currentSlug =
		context.params.slug.length > 1
			? context.params.slug[1]
			: context.params.slug[0];

	const result = await getArticlePage(
		currentSlug,
		context.locale,
		preview,
		siteConfig.siteConfiguration.id
	);

	const pageConfig = await getArticleConfig(
		context.locale,
		preview,
		siteConfig.siteConfiguration.id
	);

	const pageData = await pageHandler(
		context,
		serverSideTranslations,
		result,
		"article"
	);
	const articleSlugs = buildPortfolioSlug("news");

	result?.article?._allSlugLocales.map((articleSlug) => {
		const article = articleSlugs?.[0]?._allSlugLocales.filter(
			(a) => a.locale === articleSlug.locale
		)?.[0];
		articleSlug.value = `news/${articleSlug.value}`;
	});

	const _allSlugLocales = pageData.data?._allSlugLocales;
	const hrefLangs = buildHrefLangsList(
		_allSlugLocales,
		pageData.data?.__typename
	);

	const relations = await fetchComponentRelations(
		{ sections: pageConfig?.brandArticle.relatedSections },
		context.locale,
		context.preview
	);

	return {
		props: {
			siteConfig: siteConfig.siteConfiguration,
			...pageData.sst,
			data: { ...pageData.data, hrefLangs, pageConfig },
			preview: pageData.preview,
			relations: relations,
			pageType,
		},
	};
}
