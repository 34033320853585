import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import styles from "./index.module.scss";
import Image from "next/image";
import { Label } from "@/components/Atoms/Paragraph";

interface Props {
  data: any;
  color: any;
  styleColor: any;
}

interface ContactCardProps {
  data: any;
  textColor: any;
}

export const ContactCard = ({ data, color, styleColor }: Props) => {
  const { locale }: any = useRouter();

  const style = {
    "--color": color?.hex,
    "--style": styleColor?.hex,
    "--address": `url(${data.addressIcon?.img?.url})`,
    "--geo": `url(${data.geolocationIcon?.img?.url})`,
    "--phone": `url(${data.phoneIcon?.img?.url})`,
    "--fax": `url(${data.faxIcon?.img?.url})`,
  } as React.CSSProperties;

  const { t } = useTranslation();

  return (
    <div className="card relative  flex flex-col gap-8">
      {data.address && (
        <div className="flex flex-row items-center gap-6">
          <div
            style={style as React.CSSProperties}
            className={` ${styles.addressIcon} `}
          ></div>
          <div
            style={style as React.CSSProperties}
            className={`${styles.copyContainer} flex flex-col gap-2 lg:max-w-[50%]`}
          >
            <p className=" brandParagraph font-medium uppercase text-sm">
              {t("address")}
            </p>
            <p className=" brandParagraph  text-sm">{data.address}</p>
          </div>
        </div>
      )}
      {data.geolocation && (
        <div className="flex flex-row items-center gap-6">
          <div
            style={style as React.CSSProperties}
            className={` ${styles.geoIcon} `}
          ></div>
          <div
            style={style as React.CSSProperties}
            className={`${styles.copyContainer} flex flex-col gap-2 lg:max-w-[50%]`}
          >
            <p className=" brandParagraph font-medium uppercase text-sm">
              {t("gps")}
            </p>
            <a
              target="_blank"
              href={`https://www.google.com/maps/search/?api=1&query=${data.geolocation?.latitude},${data.geolocation?.longitude}`}
            >
              <div className="flex flex-row gap-2">
                <p className=" brandParagraph  text-sm">
                  {data.geolocation?.latitude}
                </p>
                <p className=" brandParagraph  text-sm">
                  {data.geolocation?.longitude}
                </p>
              </div>
            </a>
          </div>
        </div>
      )}
      {data.phone && (
        <div className="flex flex-row items-center gap-6">
          <div
            style={style as React.CSSProperties}
            className={` ${styles.phoneIcon} `}
          ></div>
          <div
            style={style as React.CSSProperties}
            className={`${styles.copyContainer} flex flex-col gap-2 lg:max-w-[50%]`}
          >
            <p className=" brandParagraph font-medium uppercase text-sm">
              {t("phone")}
            </p>
            <a className="brandParagraph  text-sm" href={`tel:${data.phone}`}>
              {data.phone}
            </a>
          </div>
        </div>
      )}
      {data.fax && (
        <div className="flex flex-row items-center gap-6">
          <div
            style={style as React.CSSProperties}
            className={` ${styles.faxIcon} `}
          ></div>
          <div
            style={style as React.CSSProperties}
            className={`${styles.copyContainer} flex flex-col gap-2 lg:max-w-[50%]`}
          >
            <p className=" brandParagraph font-medium uppercase text-sm">
              {t("fax")}
            </p>
            <p className=" brandParagraph  text-sm">{data.fax}</p>
          </div>
        </div>
      )}
    </div>
  );
};
