import React, { useState } from "react";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { cn, string_to_slug, variantsFull } from "@/lib/functions";
import Image from "@/components/Atoms/Image";
import { OverlayShadow } from "@/molecules/OverlayShadow/Overlay";
import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import { CopyCard } from "@/components/Molecules/CopyCard";
import { VideoCard } from "@/components/Molecules/VideoCard";
import { OverlayBackground } from "@/components/Atoms/OverlayBackground";
import { Shape } from "@/components/Atoms/Spacer";
import {
  Label,
  MultiPurposeParagraph,
  Paragraph,
} from "@/components/Atoms/Paragraph";
import { MultiPurposeTitle, Title } from "@/components/Atoms/Title";
import GeoWrapper from "@/components/Templates/Main/GeoConditional";
import { cva } from "class-variance-authority";

export default function SectionImageVideo(data: any) {
  const [playing, setPlaying] = useState(false);

  const togglePlay = () => {
    setPlaying(!playing);
  };

  return (
    <>
      <GeoWrapper
        list={data.componentVisibility?.[0]?.listOfCountries}
        country={data.componentVisibility?.[0]?.countries}
        initial={data.componentVisibility?.[0]?.countryAvailability}
      >
        <ImageVideoContainer
          data={data}
          playing={playing}
          toggle={() => togglePlay()}
        />
      </GeoWrapper>
    </>
  );
}

interface ImageVideoContainerProps {
  data: any;
  playing: boolean;
  toggle: any;
}
// CVA for section container
const sectionContainerVariants = cva("mx-auto w-full overflow-hidden", {
  variants: {
    layout: {
      container: "px-6 lg:px-12",
      fullWidth: "px-0",
    },
    sectionMargins: {
      true: "py-12 md:py-12",
      false: "py-0",
    },
    extraPadding: {
      true: "md:pb-32",
      false: "",
    },
  },
  defaultVariants: {
    layout: "container",
    sectionMargins: false,
    extraPadding: false,
  },
});

// CVA for content layout
const contentLayoutVariants = cva(
  "relative flex flex-row gap-4 sm:space-y-0 px-6 lg:px-24 py-12 min-h-auto md:min-h-[450px]",
  {
    variants: {
      textAlign: {
        left: "justify-start p-12 lg:p-24",
        center: "justify-center",
        fixedCenter: "justify-center",
        right: "justify-start lg:justify-end p-12 lg:p-24",
      },
      textFlexPosition: {
        center: "items-center",
        end: "items-end",
        start: "items-start",
      },
      layout: {
        container: "container m-auto 2xl:max-w-[1360px]",
        fullWidth: "",
      },
      contentHeight: {
        default: "xl:min-h-[420px]",
        tall: "min-h-[450px]  xl:min-h-[625px]",
      },
      contentBorderRadius: {
        rounded: "rounded-lg overflow-hidden",
        default: "",
      },

      textLayoutCard: {
        featured: "items-center lg:items-center",
        default: "",
      },
    },
    defaultVariants: {
      textAlign: "left",
      textFlexPosition: "start",
      layout: "container",
      contentBorderRadius: "default",
      contentHeight: "default",
      textLayoutCard: "default",
    },
  }
);

export function ImageVideoContainer({
  data,
  playing,
  toggle,
}: ImageVideoContainerProps) {
  return (
    <div
      style={{ backgroundColor: data.sectionBackgroundColor?.color.hex }}
      id={string_to_slug(data.title)}
      className="w-full relative"
    >
      <LazyMotion features={domAnimation}>
        <m.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          exit="exit"
        >
          {data.addOverlayBgColor && (
            <OverlayBackground
              type={data.overlayStyle}
              color={data.overlayBgColor?.color}
            />
          )}
          {data.addSpacer && (
            <Shape
              shape={data.spacerConfiguration?.customShape}
              color={data.bgColor}
              direction={data.spacerConfiguration?.spacerDirection}
              icon={[
                data.spacerConfiguration?.addIconElement,
                data?.spacerConfiguration?.spacerIcon,
              ]}
              position={data?.spacerConfiguration?.spacerPosition}
              invert={false}
            />
          )}
          <div
            style={{ backgroundColor: data?.bgColor?.color.hex }}
            className={cn(
              sectionContainerVariants({
                layout: data.layout,
                sectionMargins: data.useSectionMargins,
                extraPadding: data.useExtraPadding,
              })
            )}
          >
            <div
              className={cn(
                contentLayoutVariants({
                  textAlign: data.textAlign,
                  textFlexPosition: data.textFlexPosition,
                  layout: data.layout,
                  contentBorderRadius: data.contentBorderRadius,
                  contentHeight: data.contentHeight,
                  textLayoutCard: data.textLayoutCard,
                })
              )}
            >
              <div className="top-0 left-0 absolute w-full h-full -z-0">
                {data.addOverlayParallaxImage && (
                  <Image
                    data={data.parallaxImage}
                    className={cn(
                      data.title ? "hidden" : "",
                      "lg:flex left-0 right-0 m-auto absolute z-10 max-w-[80%] lg:max-w-full",
                      data.textLayoutCard !== "featured"
                        ? "bottom-6 opacity-50"
                        : "top-0 bottom-0"
                    )}
                  />
                )}
                {!playing && (
                  <>
                    {data.overlay && (
                      <OverlayShadow
                        format={data.__typename}
                        data={data.overlay}
                        color={data.imageOverlayBaseColor}
                        layout={data.textAlign}
                      />
                    )}
                  </>
                )}

                {data.image?.video && (
                  <VideoCard
                    bgColor={data.imageOverlayBaseColor}
                    onClick={toggle}
                    color={data.fontColor?.hex}
                    customIcon={[data.addCustomPlayIcon, data?.playIcon]}
                    controls={[
                      data.loop,
                      data.muted,
                      data.controls,
                      data.videoPreview,
                      data.autoplay,
                    ]}
                    file={data.image.video}
                    className="cursor-pointer h-full"
                    placement={data.__typename}
                  />
                )}
                {!data.image?.video && (
                  <FullBackgroundImage
                    data={data.image}
                    position={data?.imagePosition}
                  />
                )}
              </div>
              {!playing && (
                <CopyCard
                  imageLabel={[data.swapLabelForImage, data.imageReplacer]}
                  title={data.title}
                  description={data.description}
                  color={data.textColor?.color}
                  underline={data.addTitleUnderline}
                  underlineColor={data.underlineColor?.color}
                  layout={data.layout}
                  column={data.textColumnLayout}
                  card={data.textLayoutCard}
                  cardColor={data.cardBackgroundColor?.color}
                  align={data.textAlign}
                  pageRelated={data.pageRelated}
                  label={[
                    data.addLabel,
                    data.textLabel,
                    data.textlabelColor?.color,
                  ]}
                  customUnderlineAsset={data.customUnderlineAsset}
                />
              )}
            </div>
          </div>
        </m.div>
      </LazyMotion>
    </div>
  );
}

export const fragment = `
    fragment ImageVideoFragment on SectionImageRecord{
      __typename
      id
      loop
      muted
      controls
      videoPreview
      autoplay
      addCustomPlayIcon
      playIcon {
        img {
          url
          height
          width
        }
      }
      title
      layout
      description(markdown: true)
      contentHeight
      addLabel
      textLabel(markdown: true)
      useSectionMargins
      textLayoutCard
      textAlign
      textColumnLayout
      textFlexPosition
      imagePosition
      contentBorderRadius
      addOverlayBgColor
      overlayStyle
      overlayBgColor {
        color {
          hex
        }
      }
      addTitleUnderline
      swapLabelForImage
      imageReplacer{
        url
        width
        height
      }
      customUnderlineAsset {
        img {
          url
          width
          height
        }
      }
      underlineColor{
        color{
          hex
        }
      }
      textColor{
        color{
          hex
        }
      }
      textLabelColor{
        color{
          hex
        }
      }
      cardBackgroundColor{
        color{
          hex
        }
      }
      bgColor{
        color{
          hex
        }
      }
      sectionBackgroundColor{
        color{
          hex
        }
      }
      addSpacer

      spacerConfiguration {
        id
        spacerDirection
        spacerPosition
        customShape {
          img {
            alt
            url
            width
            height
          }
        }
        addIconElement
        spacerIcon {
          img {
            alt
            url
            width
            height
          }
        }
      }
      assetLabelColor{
        color{
          hex
        }
      }
      overlay
      imageOverlayBaseColor{
        hex
      }
      useExtraPadding
      showLabel

      addOverlayParallaxImage
      parallaxImage {
        url
        width
        height
        responsiveImage {
          ... responsiveImageFragment
        }
      }
      pageRelated {
       ...pageRelatedFragment
      }
      componentVisibility {
        countryAvailability
        listOfCountries
        countries {
          countryCode
        }
      }
      image {
        format
        responsiveImage(imgixParams: {fit: crop, crop: focalpoint, auto:format, q:80, w: "1500", ar: auto}) {
          ...responsiveImageFragment
        }
        width
        height
        url
        video {
          mp4Url
          muxAssetId
          thumbnailUrl
          streamingUrl
          mp4High: mp4Url(res: high)
          mp4Med: mp4Url(res: medium)
          mp4Low: mp4Url(res: low)
          duration
          framerate
          thumbJpg: thumbnailUrl(format: jpg)
          thumbPng: thumbnailUrl(format: png)
          thumbGif: thumbnailUrl(format: gif)
        }
      }
  }
`;

export function SectionFeaturedProduct({
  featuredImage,
  bgColor,
}: {
  featuredImage: any[any];
  bgColor: any;
}) {
  //filter image by typeofimage//

  const filteredImage = featuredImage.image.filter((x: any) =>
    x.typeofimage.includes("lifestyle")
  );

  //random show filtered array//

  const shuffle = (arr: any) => [...arr].sort(() => Math.random() - 0.5);
  const randomImage = filteredImage
    ? shuffle(filteredImage).slice(filteredImage.length - 1)
    : [];

  if (filteredImage?.length > 0) {
    return (
      <div style={{ backgroundColor: bgColor?.color?.hex }}>
        <LazyMotion features={domAnimation}>
          <m.div
            className="w-full"
            variants={variantsFull()}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            exit="exit"
          >
            <div className="container mx-auto pb-12 md:pb-32">
              <div className={`relative w-full h-[20rem] lg:h-[500px] `}>
                {" "}
                <FullBackgroundImage data={randomImage?.[0]?.image} />{" "}
              </div>
            </div>
          </m.div>
        </LazyMotion>
      </div>
    );
  } else {
    return null;
  }
}

export function MarketingBanner({
  title,
  image,
  label,
  textColor,
  description,
}: {
  title: string;
  image: any;
  label: any;
  textColor: any;
  description: string;
}) {
  return (
    <>
      <div className="relative h-[200px] md:h-[300px]  xl:h-[400px] w-full">
        <FullBackgroundImage data={image} position={"cover"} />
        <div className="card   h-full grid grid-cols-3 justify-center p-6 md:p-12 gap-2">
          <div
            className={`z-10 w-full  flex flex-col gap-8 relative justify-center col-span-2`}
          >
            <article className="gap-4 flex flex-col max-w-[90%]">
              {label[0] && (
                <>
                  {!label[1] && (
                    <MultiPurposeParagraph data={label[1]} color={label[2]} />
                  )}
                </>
              )}
              <MultiPurposeTitle
                type="default"
                tag="h3"
                data={title}
                color={textColor?.color}
              />
              {description && (
                <MultiPurposeParagraph
                  data={description}
                  color={textColor?.color}
                />
              )}
            </article>
          </div>
          <div className="relative z-10  flex flex-col justify-center items-center col-span-1">
            {label[0] && (
              <>
                {label[1] && (
                  <Image
                    className="max-w-[5rem] md:max-w-[10rem]"
                    data={label[2]}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
