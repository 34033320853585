import { ButtonMaster } from "@/components/Atoms/Buttons";
import CustomLink from "@/components/Atoms/CustomLink";
import Image from "@/components/Atoms/Image";
import { MultiPurposeParagraph } from "@/components/Atoms/Paragraph";
import { Title } from "@/components/Atoms/Title";
import { routerHandler } from "@/lib/routes/router-links-handler";
import styles from "./index.module.scss";
import React from "react";
import CustomImage from "@/components/Atoms/Image";
import { cn, getPortfolioSlug, pageRelatedLink } from "@/lib/functions";
import { CustomUnderline, Underline } from "@/components/Atoms/Underline";
import { useTranslation } from "next-i18next";
import { cva } from "class-variance-authority";

interface Props {
  entry: any;
  locale: any;
  portfolioSlug: string;
  card: "bgImage" | "bgColor" | "default" | "textSmall" | "stack";
  textColor: any;
  styleColor: any;
  underline: any;
  labelLayout: "start" | "center";
  underlineColor: any;
  customUnderlineAsset: any;
  buttonColor: any;
  buttonText: any;
  style: "default" | "rect" | "pill";
  layout: string;
  relations?: any;
  ctaConfig: any;
}

const featuredBannerVariants = {
  container: cva("flex-none lg:flex-1 flex flex-col gap-4 group relative", {
    variants: {
      card: {
        bgImage: "",
        bgColor: "",
        default: "lg:translate-y-[0%]  max-w-[80%] lg:max-w-[100%]",
        textSmall: "",
        stack: "",
        // Add any other card values you're using in your components
      },
      style: {
        default: "",
        pill: "rounded-full",
        rect: "",
      },
      zIndex: {
        true: "z-[1]",
        false: "",
      },
    },
    defaultVariants: {
      card: "default",
      style: "default",
      zIndex: false,
    },
  }),

  preview: cva("h-[55%] absolute top-0 w-full", {
    variants: {
      style: {
        rect: styles.previewBg,
        pill: "",
        default: "",
      } as const,
      zIndex: {
        true: "z-[-1]",
        false: "",
      },
    },
  }),

  content: cva("relative z-1 flex flex-col justify-between h-full", {
    variants: {
      labelLayout: {
        start: "text-start items-start",
        center: "text-center items-center",
      } as const,
      style: {
        rect: "mt-20",
        pill: "",
        default: "",
      },
      clickable: {
        false: "pointer-events-none",
        true: "",
      },
    },
  }),

  image: cva(
    "mx-auto group-hover:scale-105 lg:group-hover:translate-y-[-5%] transition-all ease-in-out duration-300",
    {
      variants: {
        style: {
          rect: "max-w-[20rem]",
          default: "max-w-[14rem]",
          pill: "",
        },
      },
    }
  ),

  details: cva("mx-auto flex flex-col justify-between py-4", {
    variants: {
      labelLayout: {
        start: "text-start items-start",
        center: "text-center items-center",
      },
      style: {
        pill: "max-w-[80%]",
        default: "max-w-[85%]",
        rect: "",
      },
    },
  }),

  textSection: cva("flex flex-col", {
    variants: {
      layout: {
        start: "items-start",
        center: "items-center",
      },
      hasAccolades: {
        true: "",
        false: "",
      },
    },
  }),

  productInfo: cva("flex flex-row gap-4", {
    variants: {
      size: {
        small: "",
        default: "",
      },
    },
    defaultVariants: {
      size: "small",
    },
  }),

  productText: cva("max-w-full", {
    variants: {
      hasColor: {
        true: "",
        false: "",
      },
    },
  }),

  buttonWrapper: cva("flex justify-center", {
    variants: {
      spacing: {
        default: "",
        none: "",
      },
    },
    defaultVariants: {
      spacing: "default",
    },
  }),
};

export function FeaturedBannersGallery({
  entry,
  locale,
  underline,
  underlineColor,
  customUnderlineAsset,
  labelLayout,
  portfolioSlug,
  card,
  textColor,
  style,
  styleColor,
  ctaConfig,
}: Props) {
  const bg = {
    "--bgPreview": entry.previewColor?.color.hex,
  } as React.CSSProperties;

  const { t } = useTranslation();

  const ctaSlug =
    getPortfolioSlug(ctaConfig[1]?.portfolioConfiguration, locale) ||
    ctaConfig[1]?.portfolioConfiguration?.slug;

  if (entry.__typename == "SectionTextImageRecord") {
    return (
      <div className="flex-none lg:flex-1  flex flex-col gap-4 group relative">
        <CustomLink
          href={` ${
            entry.pageRelated?.[0].page
              ? ` ${routerHandler({
                  typename: entry.pageRelated?.[0].__typename,
                  slug: pageRelatedLink(
                    entry.pageRelated?.[0].page,
                    locale,
                    entry.pageRelated?.[0].sectionAnchor?.title
                  ),
                  locale: locale,
                })}`
              : `${entry.pageRelated?.[0].externalUrl}`
          }`}
          external={entry.pageRelated?.[0].external}
        >
          <div className="relative overflow-hidden">
            <CustomImage
              className="transition-all duration-[2s] group-hover:scale-[1.1]"
              data={entry.image}
            />
          </div>
          <div className=" flex flex-col mt-4">
            {entry.addLabel && (
              <MultiPurposeParagraph data={entry.textLabel} color={textColor} />
            )}
            <Title
              tag="h4"
              data={entry.title}
              color={textColor}
              layout="left"
            />
          </div>
          <div className="group">
            {ctaConfig[0] && (
              <ButtonMaster
                anim={"slideTop"}
                type={ctaConfig[1]?.buttonStyle}
                cta={ctaConfig[1]?.typeColor?.color}
                color={ctaConfig[1]?.labelColor?.color}
                customIcon={ctaConfig[1]?.icon?.img}
                customColor={ctaConfig[1]?.iconColor?.color}
              >
                {ctaConfig[1]?.buttonLabel?.label}
              </ButtonMaster>
            )}
          </div>
        </CustomLink>
      </div>
    );
  } else {
    const awards = entry?.product?._allReferencingAwards;
    const ratings = entry?.product?._allReferencingRatings;
    const awardsYearSetting =
      entry?.product?._allReferencingBrandsProducts?.[0]?.awardsYear;
    const ratingsYearSetting =
      entry?.product?._allReferencingBrandsProducts?.[0]?.accoladesRatingsYear;

    let ratingsValid: any[] = [];

    if (awards.length > 0) {
      if (awards.length > 0) {
        awards?.forEach((award: any) => {
          if (parseInt(award?.ratingYear) >= parseInt(awardsYearSetting)) {
            ratingsValid.push(award);
          }
        });
      }
    }
    if (ratings.length > 0) {
      ratings?.forEach((rating: any) => {
        if (parseInt(rating?.ratingYear) >= parseInt(ratingsYearSetting)) {
          ratingsValid.push(rating);
        }
      });
    }

    return (
      <div
        className={cn(
          featuredBannerVariants.container({
            card,
            style,
            zIndex: true,
          })
        )}
      >
        {style === "rect" && entry.previewColor?.color.hex ? (
          <div
            style={bg}
            className={cn(
              featuredBannerVariants.preview({
                style: "rect",
                zIndex: true,
              })
            )}
          />
        ) : (
          <div
            className={cn(
              featuredBannerVariants.preview({
                zIndex: true,
              })
            )}
          >
            <CustomImage data={entry.previewImage} />
          </div>
        )}

        <div
          className={cn(
            featuredBannerVariants.content({
              labelLayout,
              style,
              clickable: !entry.hideCallToAction,
            })
          )}
        >
          <ConditionalLink
            type={ctaConfig[1]?.linksToProductAwardsPage}
            entry={entry}
            locale={locale}
            portfolioSlug={portfolioSlug ?? ctaSlug}
            accoladesSlug={ctaConfig[1]?.portfolioConfiguration?.accoladesSlug}
            hide={entry.hideCallToAction}
          >
            <Image
              data={
                entry?.product?.factSheetImage ||
                entry?.product?.sku[0]?.skuImageGallery[0]
              }
              className={cn(
                featuredBannerVariants.image({
                  style,
                })
              )}
            />
            <div className="flex flex-col min-h-[11rem] justify-between">
              <div
                className={cn(
                  featuredBannerVariants.details({
                    labelLayout,
                    style,
                  })
                )}
              >
                <div
                  className={cn(
                    featuredBannerVariants.textSection({
                      layout: labelLayout === "start" ? "start" : "center",
                      hasAccolades: entry.productNameLabel === "custom",
                    })
                  )}
                >
                  <div className="flex flex-col items-center">
                    {entry.showUmbrellaBrand && (
                      <MultiPurposeParagraph
                        type={"label"}
                        data={entry.product?.productBrand.umbrellaBrand?.title}
                        color={textColor}
                      />
                    )}
                    {entry.productNameLabel == "custom" ? (
                      <div className="flex flex-col">
                        <MultiPurposeParagraph
                          type="excerpt"
                          data={entry.customLabel?.label}
                          color={textColor}
                        />
                        {underline && (
                          <>
                            {customUnderlineAsset?.img ? (
                              <CustomUnderline
                                shape={customUnderlineAsset?.img}
                                color={underlineColor}
                              />
                            ) : (
                              <Underline color={underlineColor} />
                            )}
                          </>
                        )}
                      </div>
                    ) : entry.productNameLabel == "brand" ? (
                      <div className="flex flex-col">
                        {ratingsValid.length > 0 &&
                          entry.showAccoladesCount && (
                            <MultiPurposeParagraph
                              type="default"
                              data={`${ratingsValid.length?.toString()} ${t(
                                "accolades"
                              )}`}
                              color={textColor}
                            />
                          )}
                        <MultiPurposeParagraph
                          type="excerpt"
                          data={entry.product.productBrand.title}
                          color={textColor}
                        />
                        {underline && (
                          <>
                            {customUnderlineAsset?.img ? (
                              <CustomUnderline
                                shape={customUnderlineAsset?.img}
                                color={underlineColor}
                              />
                            ) : (
                              <Underline color={underlineColor} />
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="flex flex-col">
                        <MultiPurposeParagraph
                          type="excerpt"
                          data={entry.product?.productName}
                          color={textColor}
                        />
                        {underline && (
                          <>
                            {customUnderlineAsset?.img ? (
                              <CustomUnderline
                                shape={customUnderlineAsset?.img}
                                color={underlineColor}
                              />
                            ) : (
                              <Underline color={underlineColor} />
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className={cn(featuredBannerVariants.productInfo())}>
                  {entry.showDesignationOfOrigin && (
                    <MultiPurposeParagraph
                      type="default"
                      data={entry.product?.productOrigin?.originDesignation}
                      color={textColor}
                    />
                  )}
                  {entry.showProductTypeSpecific && (
                    <p
                      className={cn(
                        featuredBannerVariants.productText({
                          hasColor: true,
                        })
                      )}
                      style={{ color: textColor?.hex }}
                    >
                      {entry.product?.productTypeSpecific?.name}
                    </p>
                  )}
                  {entry.showVintageYear && (
                    <p
                      className={cn(
                        featuredBannerVariants.productText({
                          hasColor: true,
                        })
                      )}
                      style={{ color: textColor?.hex }}
                    >
                      {entry.product?.vintageYear}
                    </p>
                  )}
                </div>
              </div>

              {ctaConfig[0] && (
                <div className={cn(featuredBannerVariants.buttonWrapper())}>
                  <ButtonMaster
                    anim={"slideTop"}
                    type={ctaConfig[1]?.buttonStyle}
                    cta={ctaConfig[1]?.typeColor?.color}
                    color={ctaConfig[1]?.labelColor?.color}
                    customIcon={ctaConfig[1]?.icon?.img}
                    customColor={ctaConfig[1]?.iconColor?.color}
                  >
                    {ctaConfig[1]?.buttonLabel?.label}
                  </ButtonMaster>
                </div>
              )}
              {style === "pill" && (
                <div
                  className="absolute bottom-4 w-full h-[60%] rounded-full z-[-1]"
                  style={{ backgroundColor: styleColor }}
                ></div>
              )}
            </div>
          </ConditionalLink>
        </div>
      </div>
    );
  }
}

interface linkProps {
  type: string;
  entry: any;
  locale: string;
  children: any;
  portfolioSlug: string;
  hide: boolean;
  accoladesSlug: any;
}

export function ConditionalLink({ ...props }: linkProps) {
  if (props.type) {
    return (
      <CustomLink
        className="cursor-pointer"
        href={` ${
          props.hide
            ? ``
            : `${routerHandler({
                typename: "AwardRecord",
                slug: [props?.accoladesSlug, props.entry.product.slug],
                locale: props.locale,
              })}`
        } `}
      >
        {props.children}
      </CustomLink>
    );
  } else {
    return (
      <CustomLink
        className="cursor-pointer"
        href={`${
          props.hide
            ? ``
            : `${routerHandler({
                typename: props.entry?.__typename,
                slug: [
                  props.portfolioSlug,
                  props.entry?.product?.productTypeSpecific?.slug,
                  props.entry?.product?.slug,
                ],
                locale: props.locale,
              })}`
        }`}
      >
        {props.children}
      </CustomLink>
    );
  }
}
