import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import styles from "./index.module.scss";
import { cva, VariantProps } from "class-variance-authority";

interface Props {
  copy: any;
  color: any[any];
  className?: string;
  layout: any;
  column?: string;
  card?: string;
  mcolor?: any;
  altColor?: any;
}

export function FeaturedParagraph({ copy, color, layout }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;

  return (
    <>
      <p
        style={style as React.CSSProperties}
        className={` ${
          styles.paragraph
        } brandParagraphFeatured text-base lg:text-lg flex flex-col gap-4 ${
          copy?.length > 500 && layout !== "half" ? "lg:columns-2 gap-12" : ""
        }`}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => <span {...props} />,
            div: React.Fragment,
          }}
        >
          {copy}
        </ReactMarkdown>
      </p>
    </>
  );
}

export function FeaturedWarning({ copy, color, layout }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;

  return (
    <>
      <p
        style={style as React.CSSProperties}
        className={` ${styles.paragraph} FeaturedWarning uppercase font-extrabold text-center flex flex-col gap-4`}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => <span {...props} />,
            div: React.Fragment,
          }}
        >
          {copy}
        </ReactMarkdown>
      </p>
    </>
  );
}

export function Paragraph({
  copy,
  color,
  layout,
  card,
  className,
  mcolor,
  altColor,
}: Props) {
  const style = {
    "--color": color?.hex,
    "--altColor": altColor?.hex,
  } as React.CSSProperties;
  return (
    <div
      style={style as React.CSSProperties}
      className={`  ${
        mcolor?.hex ? `${styles.paragraphVariant} ` : `${styles.paragraph}`
      }  text-sm lg:text-base flex flex-col   ${
        layout === "left"
          ? `${card == "featured" ? "text-center lg:text-left" : "text-left"}`
          : layout === "right"
          ? `${card == "featured" ? "text-center lg:text-left" : "text-left"}`
          : layout === "center"
          ? "lg:text-center"
          : layout === "fixedCenter"
          ? "text-center"
          : ""
      } ${className}`}
    >
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          p: ({ node, ...props }) => <p {...props} />,
          a: ({ node, ...props }) => <a target="_blank" {...props} />,
          ul: ({ node, ...props }) => (
            <ul className="flex flex-col gap-2 lg:gap-4" {...props} />
          ),
          h3: ({ node, ...props }) => (
            <p
              className={`${styles.altColor} text-base lg:text-lg brandTitle leading-tight `}
              {...props}
            />
          ),
          h1: ({ node, ...props }) => (
            <p
              className={`${styles.altColor} text-base lg:text-lg brandTitle leading-tight `}
              {...props}
            />
          ),
          div: React.Fragment,
        }}
      >
        {copy}
      </ReactMarkdown>
    </div>
  );
}

export function ParagraphNumber({
  copy,
  color,
  layout,
  card,
  className,
}: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;

  return (
    <p
      style={style as React.CSSProperties}
      className={`  ${
        styles.paragraph
      }  text-sm lg:text-base flex flex-col gap-4  brandParagraph ${
        layout === "left"
          ? `${card == "featured" ? "text-center lg:text-left" : "text-left"}`
          : layout === "right"
          ? `${card == "featured" ? "text-center lg:text-left" : "text-left"}`
          : layout === "center"
          ? "lg:text-center"
          : layout === "fixedCenter"
          ? "text-center"
          : ""
      } ${className}`}
    >
      {copy}
    </p>
  );
}

export function Label({ copy, color, className }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;
  return (
    <>
      <p
        style={style as React.CSSProperties}
        className={` ${styles.paragraph} ${className} brandLabel text-brandLabel lg:text-brandLabel_lg xl:text-brandLabel_xl max-w-full`}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => <span {...props} />,
            div: React.Fragment,
          }}
        >
          {copy}
        </ReactMarkdown>
      </p>
    </>
  );
}

export function LabelNumber({ copy, color, className }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;
  return (
    <>
      <p
        style={style}
        className={` ${styles.paragraph} ${className} text-xs max-w-full  font-bold font-secondary opacity-50`}
      >
        {copy}
      </p>
    </>
  );
}

export function FeaturedLabel({ copy, color, className }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;
  return (
    <>
      <p
        style={style as React.CSSProperties}
        className={` ${styles.paragraph} ${className} brandFeaturedCall text-md lg:text-lg max-w-full`}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => <span {...props} />,
            div: React.Fragment,
          }}
        >
          {copy}
        </ReactMarkdown>
      </p>
    </>
  );
}

export function HeroLabel({ copy, color, className, layout }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;
  return (
    <>
      <p
        style={style as React.CSSProperties}
        className={`${layout == "center" ? "text-center" : "text-left"} ${
          styles.paragraph
        } ${className}   brandHeroLabel text-heroLabelSm lg:text-heroLabel max-w-full  `}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => <span {...props} />,
            div: React.Fragment,
          }}
        >
          {copy}
        </ReactMarkdown>
      </p>
    </>
  );
}

export function ImageLabel({ copy, color, className }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;
  return (
    <>
      <p
        style={style as React.CSSProperties}
        className={` ${styles.paragraph} ${className} brandImageLabel text-xs max-w-full pt-1 flex flex-col gap-2 `}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => <span {...props} />,
            div: React.Fragment,
            a: ({ node, ...props }) => (
              <a className="underline font-bold" target="_blank" {...props} />
            ),
          }}
        >
          {copy}
        </ReactMarkdown>
      </p>
    </>
  );
}

export function BigNumber({ copy, color, className, layout }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;
  return (
    <p
      style={style as React.CSSProperties}
      className={` ${styles.paragraph} ${className} text-center   text-heroLabelSm lg:text-heroLabel max-w-full  brandTitle `}
    >
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          p: ({ node, ...props }) => <span {...props} />,
          div: React.Fragment,
        }}
      >
        {copy}
      </ReactMarkdown>
    </p>
  );
}

// 2025 DEV

export type ParaVariantProps = VariantProps<typeof ParaVariants>;
const ParaVariants = cva(`flex flex-col  gap-4 lg:gap-6`, {
  variants: {
    type: {
      image: "",
      label: "",
      default: "",
      quote: "",
      excerpt: "",
      headline: "",
      featured: "",
      subLabel: "",
    },
    font: {
      sans: "font-sans",
      serif: "font-secondary",
    },
    weight: {
      default: "font-normal",
      bold: "font-bold",
    },
    transform: {
      uppercase: "uppercase",
      lowercase: "lowercase",
      capitalize: "capitalize",
    },
    align: {
      center: "text-center",
      left: "text-left",
      right: "text-right",
    },
    clamp: {
      true: "line-clamp-3",
      false: "",
    },
  },
  compoundVariants: [
    {
      type: "default",
      class: "brandParagraph",
    },
    {
      type: "image",
      class: "imageText",
    },
    {
      type: "label",
      class: "labelText",
    },
    {
      type: "subLabel",
      class: "subLabelText",
    },
    {
      type: "featured",
      class: "multiFeatured",
    },
    {
      type: "headline",
      class: "headlineText",
    },
    {
      type: "excerpt",
      class: "excerptText",
    },
    {
      type: "quote",
      class: "quoteText",
    },
  ],
});

export interface ParaProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    ParaVariantProps {
  data: any;
  color: any;
  titleColor?: any;
  mcolor?: any;
}

export const MultiPurposeParagraph = ({
  data,
  color,
  type,
  weight,
  font,
  align,
  titleColor,
  transform,
  mcolor,
}: ParaProps) => {
  const style = {
    "--color": color?.hex,
    "--titleColor": titleColor?.hex,
    "--mcolor": mcolor?.hex,
  } as React.CSSProperties;

  return (
    <>
      <div
        style={style as React.CSSProperties}
        className={`  ${
          mcolor?.hex
            ? `${styles.paragraphVariant} `
            : `${styles.paragraph}
        `
        }  ${ParaVariants({
          type,
          weight,
          font,
          align,
          transform,
        })}`}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            h3: ({ node, ...props }) => (
              <p
                style={{ textWrap: "balance" }}
                className={`multiHeading ${styles.multiTitle} `}
                {...props}
              />
            ),
            h2: ({ node, ...props }) => (
              <p
                style={{ textWrap: "balance" }}
                className={`multiHeading ${styles.multiTitle}`}
                {...props}
              />
            ),
            h1: ({ node, ...props }) => (
              <p
                style={{ textWrap: "balance" }}
                className={`multiHeading ${styles.multiTitle}`}
                {...props}
              />
            ),
            blockquote: ({ node, ...props }) => (
              <span className="subLabel mb-2 lg:mb-4" {...props} />
            ),
            p: ({ node, ...props }) => <p {...props} />,
            a: ({ node, ...props }) => <a className="underline font-bold" {...props} />,

            ul: ({ node, ...props }) => (
              <ul className="flex flex-col gap-2 list-disc pl-8" {...props} />
            ),
            div: React.Fragment,
          }}
        >
          {data}
        </ReactMarkdown>
      </div>
    </>
  );
};
