import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import styles from "./index.module.scss";
import { cva, VariantProps } from "class-variance-authority";

interface Props {
	tag: string;
	data: string;
	color: any[any];
	layout?: any;
	className?: string;
	mcolor?: any;
}

export const Title = ({
	tag,
	data,
	color,
	layout,
	mcolor,
	className,
}: Props) => {
	const HeadingTag: any = `${tag}`;
	const style = {
		"--color": color?.hex,
		"--mcolor": mcolor?.hex,
	} as React.CSSProperties;
	return (
		<>
			<HeadingTag
				style={style as React.CSSProperties}
				className={` ${className} ${
					mcolor?.hex ? `${styles.titleVariant} ` : `${styles.title}`
				} leading-tight  flex flex-col  ${
					tag === "h2"
						? `text-heroTitle lg:text-heroTitle_lg xl:text-heroTitle_xl brandTitle`
						: tag === "h3"
						? "text-lg lg:text-heroTitle xl:text-heroTitle_lg brandTitleSection"
						: tag === "h4"
						? " text-base md:text-lg lg:text-xl  xl:text-2xl brandTitleSection"
						: tag === "h5"
						? "text-lg lg:text-xl brandTitleSection"
						: tag === "h6"
						? " text-base lg:text-lg brandTitleSection"
						: "text-base lg:text-lg brandTitleSection"
				}  ${
					layout === "center"
						? "text-center"
						: layout === "left"
						? "text-left"
						: layout === "right"
						? "text-left"
						: layout === "fixedCenter"
						? "text-center"
						: "text-left"
				} 
       `}
			>
				<ReactMarkdown
					rehypePlugins={[rehypeRaw]}
					components={{
						p: ({ node, ...props }) => (
							<span className={className} {...props} />
						),
						div: React.Fragment,
					}}
				>
					{data}
				</ReactMarkdown>
			</HeadingTag>
		</>
	);
};

export const BolderTitle = ({ tag, data, color, className, layout }: Props) => {
	const HeadingTag: any = `${tag}`;
	const style = {
		"--color": color?.hex,
	} as React.CSSProperties;

	return (
		<>
			<HeadingTag
				style={style as React.CSSProperties}
				className={` ${styles.title} ${className} 
        }  brandTitle flex flex-col leading-none  ${
					tag === "h2"
						? `text-4xl md:text-6xl xl:text-7xl`
						: tag === "h3"
						? "text-3xl lg:text-4xl  xl:text-5xl"
						: tag === "h4"
						? " text-xl lg:text-2xl  xl:text-3xl"
						: tag === "h5"
						? "text-lg lg:text-xl"
						: tag === "h6"
						? " text-base lg:text-lg"
						: "text-base lg:text-lg"
				}   
        ${
					layout === "center"
						? "text-center"
						: layout === "left"
						? "text-left"
						: layout === "right"
						? "text-left"
						: layout === "fixedCenter"
						? "text-center"
						: "text-left"
				} 
        `}
			>
				<ReactMarkdown
					rehypePlugins={[rehypeRaw]}
					components={{
						p: ({ node, ...props }) => <span {...props} />,
						div: React.Fragment,
					}}
				>
					{data}
				</ReactMarkdown>
			</HeadingTag>
		</>
	);
};

export const HeroSignatureTitle = ({ tag, data, color, layout }: Props) => {
	const HeadingTag: any = `${tag}`;
	const style = { "--color": color?.hex } as React.CSSProperties;
	return (
		<>
			<HeadingTag
				style={style as React.CSSProperties}
				className={`  flex flex-col  ${styles.title} heroSignature ${
					tag === "h2"
						? "text-heroSignature lg:text-heroSignature_lg xl:text-heroSignature_xl"
						: tag === "h3"
						? "text-heroSignature lg:text-heroSignature_lg xl:text-heroSignature_xl"
						: tag === "h4"
						? "text-base lg:text-lg xl:text-xl"
						: "text-xl"
				}  ${
					layout === "center"
						? "text-left"
						: layout === "left"
						? "text-left"
						: layout === "fixedCenter"
						? "text-center"
						: "text-left"
				}  `}
			>
				<ReactMarkdown
					rehypePlugins={[rehypeRaw]}
					components={{
						p: ({ node, ...props }) => <span {...props} />,
						div: React.Fragment,
					}}
				>
					{data}
				</ReactMarkdown>
			</HeadingTag>
		</>
	);
};

export const HeroProductTitle = ({
	tag,
	data,
	color,
	layout,
	mcolor,
	className,
}: Props) => {
	const HeadingTag: any = `${tag}`;
	const style = {
		"--color": color?.hex,
		"--mcolor": mcolor?.hex,
	} as React.CSSProperties;
	return (
		<>
			<HeadingTag
				style={style as React.CSSProperties}
				className={` flex flex-col  ${
					mcolor?.hex ? `${styles.titleVariant} ` : `${styles.title}`
				} ${className}  ${
					tag === "h2"
						? "text-2xl lg:text-4xl xl:text-4xl"
						: tag === "h3"
						? "text-xl lg:text-2xl  xl:text-3xl"
						: tag === "h4"
						? " text-lg lg:text-xl  xl:text-2xl"
						: "text-xl"
				}  ${
					layout === "center"
						? "text-left"
						: layout === "left"
						? "text-left"
						: layout === "fixedCenter"
						? "text-center"
						: "text-left"
				}  `}
			>
				<ReactMarkdown
					rehypePlugins={[rehypeRaw]}
					components={{
						p: ({ node, ...props }) => <span {...props} />,
						div: React.Fragment,
					}}
				>
					{data}
				</ReactMarkdown>
			</HeadingTag>
		</>
	);
};

export const HeroUmbrellaTitle = ({
	tag,
	data,
	color,
	mcolor,
	className,
}: Props) => {
	const HeadingTag: any = `${tag}`;
	const style = {
		"--color": color?.hex,
		"--mcolor": mcolor?.hex,
	} as React.CSSProperties;
	return (
		<>
			<HeadingTag
				style={style as React.CSSProperties}
				className={` flex flex-col text-sm md:text-base lg:text-lg titleUmbrellaHero  ${
					mcolor?.hex ? `${styles.titleVariant} ` : `${styles.title}`
				} ${className}`}
			>
				<ReactMarkdown
					rehypePlugins={[rehypeRaw]}
					components={{
						p: ({ node, ...props }) => <span {...props} />,
						div: React.Fragment,
					}}
				>
					{data}
				</ReactMarkdown>
			</HeadingTag>
		</>
	);
};

export const HeroFeaturedTitle = ({ tag, data, color, layout }: Props) => {
	const HeadingTag: any = `${tag}`;
	const style = { "--color": color?.hex } as React.CSSProperties;
	return (
		<>
			<HeadingTag
				style={style as React.CSSProperties}
				className={` font-secondary leading-tight  ${styles.title}  ${
					tag === "h2"
						? "text-xl md:text-2xl lg:text-2xl"
						: tag === "h3"
						? " text-lg md:text-xl lg:text-xl"
						: tag === "h4"
						? "text-base md:text-lg lg:text-lg"
						: "text-2xl"
				}  ${
					layout === "center"
						? "text-center"
						: layout === "left"
						? "text-left"
						: layout === "fixedCenter"
						? "text-center"
						: "text-left"
				}  `}
			>
				<ReactMarkdown components={{ p: React.Fragment }}>{data}</ReactMarkdown>
			</HeadingTag>
		</>
	);
};

export const FeaturedTitle = ({
	tag,
	data,
	color,
	layout,
	className,
}: Props) => {
	const HeadingTag: any = `${tag}`;
	const style = { "--color": color?.hex } as React.CSSProperties;
	return (
		<HeadingTag
			style={style as React.CSSProperties}
			className={` leading-tight brandFeatured ${styles.title} ${className} ${
				tag === "h2"
					? "text-4xl md:text-4xl lg:text-7xl"
					: tag === "h3"
					? " text-3xl md:text-3xl lg:text-4xl"
					: tag === "h4"
					? "text-xl md:text-xl lg:text-2xl"
					: "text-lg md:text-xl"
			}  ${
				layout === "center"
					? "text-left"
					: layout === "left"
					? "text-left"
					: layout === "fixedCenter"
					? "text-center lg:max-w-[60%]"
					: layout === "featuredCenter"
					? "text-center"
					: "text-left"
			} 
        `}
		>
			<ReactMarkdown
				rehypePlugins={[rehypeRaw]}
				components={{
					p: ({ node, ...props }) => <span {...props} />,
					div: React.Fragment,
				}}
			>
				{data}
			</ReactMarkdown>
		</HeadingTag>
	);
};

export const FeaturedQuoteTitle = ({
	tag,
	data,
	color,
	layout,
	className,
}: Props) => {
	const HeadingTag: any = `${tag}`;
	const style = { "--color": color?.hex } as React.CSSProperties;
	return (
		<HeadingTag
			style={style as React.CSSProperties}
			className={` leading-tight brandFeatured ${styles.title} ${className} ${
				tag === "h2"
					? "text-4xl md:text-4xl lg:text-7xl"
					: tag === "h3"
					? "text-quoteSignature lg:text-quoteSignature_lg xl:text-quoteSignature_xl"
					: tag === "h4"
					? "text-xl md:text-xl lg:text-2xl"
					: "text-lg md:text-xl"
			}  ${
				layout === "center"
					? "text-left"
					: layout === "left"
					? "text-left"
					: layout === "fixedCenter"
					? "text-center"
					: layout === "featuredCenter"
					? "text-center"
					: "text-left"
			} 
        `}
		>
			<ReactMarkdown
				rehypePlugins={[rehypeRaw]}
				components={{
					p: ({ node, ...props }) => <span {...props} />,
					div: React.Fragment,
				}}
			>
				{data}
			</ReactMarkdown>
		</HeadingTag>
	);
};

export const FeaturedTitleNumber = ({
	tag,
	data,
	color,
	layout,
	className,
}: Props) => {
	const HeadingTag: any = `${tag}`;
	const style = { "--color": color?.hex } as React.CSSProperties;
	return (
		<HeadingTag
			style={style as React.CSSProperties}
			className={` leading-tight brandFeatured ${styles.title} ${className} ${
				tag === "h2"
					? "text-4xl md:text-4xl lg:text-7xl"
					: tag === "h3"
					? " text-3xl md:text-3xl lg:text-4xl"
					: tag === "h4"
					? "text-xl md:text-xl lg:text-2xl"
					: "text-lg md:text-xl"
			}  ${
				layout === "center"
					? "text-left"
					: layout === "left"
					? "text-left"
					: layout === "fixedCenter"
					? "text-center lg:max-w-[60%]"
					: layout === "featuredCenter"
					? "text-center"
					: "text-left"
			} 
        `}
		>
			{data}
		</HeadingTag>
	);
};

// multipurpose title to remove previous configurations //

export type TitleVariantProps = VariantProps<typeof TitleVariants>;
const TitleVariants = cva(`flex flex-col gap-2 `, {
	variants: {
		tag: {
			h1: `leading-none`,
			h2: `leading-none`,
			h3: `leading-none`,
			h4: `leading-none`,
			p: ``,
		},
		type: {
			default: "",
			featured: "",
			excerpt: "",
			excerptAlt: "",
			headline: "",
			headlineAlt: "",
			umbrella: "umbrellaTitle",
		},
		layout: {
			default: "",
			center: "text-center",
			left: "text-left",
			fixedCenter: "text-center",
			featuredCenter: "text-center",
			right: "text-right",
		},
		weight: {
			default: "",
			bold: "font-bold",
		},
		columns: {
			double: "max-w-xl",
			single: "",
		},
	},
	compoundVariants: [
		{
			tag: "h2",
			type: "default",
			class: "titleH2Default",
		},
		{
			tag: "h2",
			type: "headline",
			class: "titleH2Headline",
		},
		{
			tag: "h2",
			type: "featured",
			class: "titleH2Featured",
		},
		{
			tag: "h2",
			type: "excerpt",
			class: "titleH2Excerpt",
		},
		{
			tag: "h3",
			type: "default",
			class: "titleH3Default",
		},
		{
			tag: "h3",
			type: "headline",
			class: "titleH3Headline",
		},
		{
			tag: "h3",
			type: "featured",
			class: "titleH3Featured",
		},
		{
			tag: "h3",
			type: "excerpt",
			class: "titleH3Excerpt",
		},
		{
			tag: "h3",
			type: "headline",
			class: "titleH3Headline",
		},
		{
			tag: "h4",
			type: "featured",
			class: "titleH4Featured",
		},
		{
			tag: "p",
			type: "default",
			class: "defaultTitle",
		},
		{
			tag: "p",
			type: "featured",
			class: "featuredText",
		},
		{
			tag: "p",
			type: "headline",
			class: "headlineText",
		},
		{
			tag: "p",
			type: "headlineAlt",
			class: "headlineTextAlt",
		},
		{
			tag: "p",
			type: "excerpt",
			class: "excerptText",
		},
		{
			tag: "p",
			type: "excerptAlt",
			class: "excerptTextAlt",
		},
	],
	defaultVariants: {
		type: "default",
		tag: "h2",
		weight: "default",
	},
});

export interface TitleProps
	extends React.HTMLAttributes<HTMLHeadingElement>,
		TitleVariantProps {
	data: any;
	color: any;
	mcolor?: any;
}

export const MultiPurposeTitle = ({
	data,
	tag,
	color,
	type,
	weight,
	columns,
	layout,
	mcolor,
}: TitleProps) => {
	const HeadingTag: any = `${tag}`;
	const style = {
		"--color": color?.hex,
		"--mcolor": mcolor?.hex,
	} as React.CSSProperties;

	return (
		<>
			<HeadingTag
				style={style as React.CSSProperties}
				className={`   ${
					mcolor?.hex ? `${styles.titleVariant} ` : `${styles.title}`
				}  ${TitleVariants({
					tag,
					type,
					weight,
					columns,
					layout,
				})}`}
			>
				<ReactMarkdown
					rehypePlugins={[rehypeRaw]}
					components={{
						p: ({ node, ...props }) => (
							<span style={{ textWrap: "balance" }} {...props} />
						),

						div: React.Fragment,
					}}
				>
					{data}
				</ReactMarkdown>
			</HeadingTag>
		</>
	);
};
