"use client";
import Image from "@/components/Atoms/Image";
import { LazyMotion, domAnimation, m, motion } from "framer-motion";
import { cn, slideReveal, string_to_slug } from "@/lib/functions";
import styles from "./index.module.scss";
import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import { TextCopyCard } from "@/components/Molecules/CopyCard";
import {
  AboveParallaxImage,
  BellowParallaxImage,
  StyleBackgroundImage,
} from "@/components/Atoms/ParallaxImage";
import { Shape } from "@/components/Atoms/Spacer";
import React from "react";
import GeoWrapper from "@/components/Templates/Main/GeoConditional";
import { cva, VariantProps } from "class-variance-authority";
import { TextImageProps } from "types/types";
import { MultiPurposeParagraph } from "@/components/Atoms/Paragraph";

export type TextImageHalfVariantProps = VariantProps<
  typeof TextImageHalfVariants
>;
const TextImageHalfVariants = cva(` mx-auto relative w-full `, {
  variants: {
    contentHeight: {
      small: "",
      medium: "min-h-[600px]",
      large: "min-h-[800px]",
      default: "overflow-x-hidden",
      textSmall: "",
    },
    useSectionMargins: {
      true: "py-6 md:py-12",
      false: "py-0",
    },
    useExtraTopMargins: {
      true: "pt-20 pb-12 md:pt-24 md:pb-12",
      false: "",
    },
    useExtraPadding: {
      true: "pb-24 lg:pb-32",
      false: "",
    },
  },
  defaultVariants: {
    contentHeight: "default",
    useSectionMargins: false,
    useExtraTopMargins: false,
    useExtraPadding: false,
  },
});

interface TextImageHalfProps
  extends VariantProps<typeof TextImageHalfVariants> {
  data: TextImageProps;
  radius?: "rounded" | "roundedXl" | "none";
  contentHeight?: "small" | "medium" | "large" | "default" | "textSmall";
  useSectionMargins?: boolean;
  useExtraTopMargins?: boolean;
  useExtraPadding?: boolean;
}

const textImageHalfVariants = {
  container: cva("container mx-auto", {
    variants: {
      contentHeight: {
        small: "pt-12 sm:translate-y-[-18%] md:translate-y-[0] lg:h-[450px]",
        medium: "",
        large: "",
        default: "",
        textSmall: "",
      },
    },
  }),

  innerContainer: cva("relative w-full", {
    variants: {
      hasParallax: {
        true: "",
        false: "",
      },
      alignment: {
        left: "lg:pl-[3rem]",
        right: "lg:pl-[3rem]",
      },
      parallaxPos: {
        above: "",
        bellow: "",
      },
    },
    compoundVariants: [
      {
        hasParallax: true,
        alignment: "right",
        class: "lg:pl-[5rem]",
      },
      {
        hasParallax: true,
        alignment: "left",
        class: "lg:pl-[5rem]",
      },
      {
        hasParallax: true,
        alignment: "right",
        parallaxPos: "above",
        class: "lg:pl-[30rem]",
      },
    ],
  }),

  imageContainer: cva("flex-none lg:col-span-1 flex z-20", {
    variants: {
      alignment: {
        left: "order-1 lg:order-2",
        right: "order-1",
      },
      contentHeight: {
        small: "translate-y-[0] xl:translate-y-[-25%] justify-center flex",
        medium: "",
        large: "",
        default: "",
        textSmall: "",
      },
    },
  }),

  imageWrapper: cva("relative", {
    variants: {
      contentHeight: {
        small: "max-w-[30rem] lg:max-w-[33rem] relative bottom-0",
        medium: "",
        large: "",
        default: "",
        textSmall: "",
      },
    },
  }),

  gridLayout: cva(
    "flex lg:grid container 2xl:max-w-[1360px] relative mx-auto w-full px-6 lg:px-0 gap-12 lg:gap-16",
    {
      variants: {
        columnDisplay: {
          default: "flex-col",
          reverse: "flex-col-reverse",
        },
        gridCols: {
          default: "lg:grid-cols-2",
          custom: "lg:grid-cols-[450px_auto]",
        },
      },
      defaultVariants: {
        columnDisplay: "default",
        gridCols: "default",
      },
    }
  ),

  imageAsset: cva("", {
    variants: {
      height: {
        reduced: "md:max-w-[400px] m-auto",
        default: "",
      },
    },
    defaultVariants: {
      height: "default",
    },
  }),

  image: cva("", {
    variants: {
      format: {
        png: "",
        default: "",
      },
      radius: {
        rounded: "rounded-md",
        roundedXl: "rounded-3xl",
        none: "",
      },
      hasParallax: {
        true: styles.parallaxMain,
        false: styles.imageFix,
      },
    },
    defaultVariants: {
      format: "default",
      radius: "none",
      hasParallax: false,
    },
  }),

  label: cva("", {
    variants: {
      format: {
        png: "text-center md:px-24",
        default: "text-left",
      },
    },
    defaultVariants: {
      format: "default",
    },
  }),
};

export default function TextImageHalf({
  data,
  contentHeight,
  useSectionMargins,
  useExtraTopMargins,
  useExtraPadding,
  radius,
}: TextImageHalfProps) {
  const geoFilter = data?.geo?.map((filter: any) => filter);
  return (
    <GeoWrapper
      list={geoFilter?.[0]?.listOfCountries}
      country={geoFilter?.[0]?.countries}
      initial={geoFilter?.[0]?.countryAvailability}
    >
      {data.addSpacer && (
        <Shape
          shape={data.spacerConfiguration?.customShape}
          color={data.bgColor}
          direction={data.spacerConfiguration?.spacerDirection}
          position={data.spacerConfiguration?.spacerPosition || "bottom"}
          icon={[
            data.spacerConfiguration?.addIconElement,
            data?.spacerConfiguration?.spacerIcon,
          ]}
          invert={false}
        />
      )}

      <div
        style={{ backgroundColor: data?.bgColor?.color?.hex }}
        className={cn(
          TextImageHalfVariants({
            contentHeight,
            useSectionMargins,
            useExtraTopMargins,
            useExtraPadding,
          })
        )}
      >
        {data.backgroundType == "full" && (
          <>
            {data.addBackground && data.backgroundImage && (
              <>
                {data.backgroundImage.format === "svg" ? (
                  <StyleBackgroundImage
                    position={data.textAlignment}
                    data={data.backgroundImage}
                  />
                ) : (
                  <FullBackgroundImage data={data.backgroundImage} />
                )}
              </>
            )}
          </>
        )}
        <div
          className="absolute top-[-100px]"
          id={data.title ? string_to_slug(data.title) : ""}
        ></div>
        <div
          className={cn(
            textImageHalfVariants.container({
              contentHeight: data.contentHeight,
            })
          )}
        >
          <div
            className={cn(
              textImageHalfVariants.gridLayout({
                columnDisplay: data.mobileColumnDisplay ? "reverse" : "default",
                gridCols:
                  data.contentHeight === "textSmall" &&
                  data.textAlignment === "left"
                    ? "custom"
                    : "default",
              })
            )}
          >
            <div
              className={cn(
                textImageHalfVariants.innerContainer({
                  hasParallax: data?.addOverlayParallaxImage,
                  alignment: data.textAlignment === "left" ? "left" : "right",
                  parallaxPos: data.parallaxImagePosition,
                }),
                styles.innerTextContainer,
                data.useSmallerContainer && styles.smallerContainer,
                data.textAlignment === "right"
                  ? styles.textRight
                  : styles.textLeft,
                data.contentHeight === "small" && styles.smallInnerContainer
              )}
            >
              {data.backgroundType == "container" && (
                <>
                  {data.addBackground && data.backgroundImage && (
                    <>
                      {data.backgroundImage.format === "svg" ? (
                        <StyleBackgroundImage
                          position={data.textAlignment}
                          data={data.backgroundImage}
                        />
                      ) : (
                        <FullBackgroundImage data={data.backgroundImage} />
                      )}
                    </>
                  )}
                </>
              )}

              <TextCopyCard
                layout="left"
                ctaLayout={data.ctaLayout}
                data={data}
              />
            </div>

            <div
              className={cn(
                textImageHalfVariants.imageContainer({
                  alignment: data.textAlignment === "left" ? "left" : "right",
                  contentHeight: data.contentHeight,
                })
              )}
            >
              <LazyMotion features={domAnimation}>
                <m.div
                  className={cn(
                    textImageHalfVariants.imageWrapper({
                      contentHeight: data.contentHeight,
                    }),
                    "w-full flex flex-row lg:flex-col gap-12 justify-center"
                  )}
                >
                  <motion.div
                    variants={slideReveal(data.textAlignment)}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    exit="exit"
                    className="relative flex flex-col"
                  >
                    <div
                      className={cn(
                        textImageHalfVariants.imageAsset({
                          height:
                            data.mainAssetHeight === "reduced"
                              ? "reduced"
                              : "default",
                        })
                      )}
                    >
                      {data.image && (
                        <Image
                          data={data.image}
                          className={cn(
                            textImageHalfVariants.image({
                              format:
                                data.image.format === "png" ? "png" : "default",
                              radius,
                              hasParallax: !!data.parallaxImage,
                            })
                          )}
                        />
                      )}

                      {data.showLabel && (
                        <MultiPurposeParagraph
                          type="image"
                          data={data.image?.title}
                          color={data.textLabelColor?.color}
                        />
                      )}
                    </div>
                    {data.addOverlayParallaxImage && (
                      <>
                        {data.parallaxImagePosition == "bellow" ? (
                          <BellowParallaxImage
                            position={data.textAlignment}
                            data={data.parallaxImage}
                          />
                        ) : (
                          <AboveParallaxImage
                            position={data.textAlignment}
                            data={data.parallaxImage}
                          />
                        )}
                      </>
                    )}
                  </motion.div>
                </m.div>
              </LazyMotion>
            </div>
          </div>
        </div>
      </div>
    </GeoWrapper>
  );
}
