import { pageHandler } from "@/lib/pages-handler";
import { getPortfolio } from "@/dato-api/portfolio";
import dynamic from "next/dynamic";
import Blocks from "@/components/Organisms";
import MenuPortfolio from "@/components/Organisms/MenuPortfolio";
import FeaturedSection from "@/components/Organisms/FeaturedSection";
import { GeoWrapperPage } from "@/components/Templates/Main/GeoConditional";
import {
  ProductGrid,
  ProductsDefinitionList,
} from "@/components/Organisms/Product";
import {
  buildHrefLangsList,
  buildPortfolioSlug,
  getPortfolioSlug,
  getRealPortfolioSlug,
} from "@/lib/functions";
import { useRouter } from "next/router";
import AnchorsPortfolio from "@/components/Organisms/AnchorsPortfolio";
import HrefLangs from "@/components/Atoms/hrefLangs";
import OgUrl from "@/components/Atoms/ogUrl";
import { getAwardsByProductsIdsList } from "@/dato-api/awards";
import { getRatingsByProductsIdsList } from "@/dato-api/ratings";

const Hero = dynamic(() => import("@/components/Organisms/Hero"));

export default function BrandsPortfolioPage({ data, relations }) {
  const portfolio = data.brandsProduct;
  const { locale } = useRouter();
  const portfolioSlug = getPortfolioSlug(portfolio, locale);
  const isProductsList = portfolio?.typeList;

  const homeCollections = portfolio?.productsCollection;

  const productsDefinitionList = portfolio?.productsList;

  return (
    <GeoWrapperPage
      initial={portfolio?.countryAvailability}
      country={portfolio?.countries}
      list={portfolio?.listOfCountries}
      request={""}
    >
      <HrefLangs links={data.hrefLangs} />
      <OgUrl
        url={
          data.hrefLangs.filter((link) => link.hrefLang === locale)?.[0]?.href
        }
      />
      <Hero data={portfolio?.hero} />
      {portfolio?.showNavbar === true ? (
        <>
          {portfolio.typeOfNavigation ? (
            <MenuPortfolio
              bg={portfolio.hero.bgColor}
              text={portfolio.hero.textColor}
              data={portfolio.menu}
              portfolioSlug={portfolioSlug}
            />
          ) : (
            <AnchorsPortfolio
              bg={portfolio.hero.bgColor}
              text={portfolio.productTextColor}
              style={portfolio.productStyleColor}
              productsDefinition={productsDefinitionList}
            />
          )}
        </>
      ) : (
        <></>
      )}

      {portfolio?.featuredSectionPosition && (
        <Blocks
          portfolioSlug={portfolioSlug}
          sections={portfolio.featureSection}
          relations={relations}
        ></Blocks>
      )}

      {!isProductsList &&
        homeCollections?.map((entry, i) => (
          <div>
            <FeaturedSection data={entry.title} />
            {entry.swapProductsForDefinitions ? (
              <ProductGrid
                type={portfolio.productLisCard}
                bgColor={portfolio.bgColor}
                text={portfolio.productTextColor}
                style={portfolio.productStyleColor}
                typeCard={portfolio.productListCard}
                btn={portfolio.productButtonTextColor}
                btnStyle={portfolio.productButtonStyleColor}
                productDefinition={entry.productsDefinitionCollection}
                portfolioSlug={portfolioSlug}
                buttonConfig={portfolio?.buttonTypeConfig}
              />
            ) : (
              <ProductGrid
                type={portfolio.productLisCard}
                bgColor={portfolio.bgColor}
                text={portfolio.productTextColor}
                style={portfolio.productStyleColor}
                typeCard={portfolio.productListCard}
                btn={portfolio.productButtonTextColor}
                btnStyle={portfolio.productButtonStyleColor}
                product={entry.productsCollection}
                portfolioSlug={portfolioSlug}
                buttonConfig={portfolio?.buttonTypeConfig}
              />
            )}
          </div>
        ))}

      {isProductsList && (
        <>
          <ProductsDefinitionList
            layout={portfolio.layout}
            column={portfolio.layoutColumnWidth}
            reduce={portfolio.reduceImageProductSize}
            type={portfolio.productListCard}
            underline={[
              portfolio.addTitleUnderline,
              portfolio.customUnderlineAsset,
              portfolio.underlineColor,
            ]}
            buttonConfig={portfolio?.buttonTypeConfig}
            spacer={[portfolio.addSpacer, portfolio.spacerConfiguration]}
            productsDefinition={productsDefinitionList}
            bgColor={portfolio.bgColor}
            invertProductImageOrder={portfolio.invertProductImageOrder}
            portfolioSlug={portfolioSlug}
            text={portfolio.productTextColor}
            style={portfolio.productStyleColor}
            btn={portfolio.productButtonTextColor}
            btnStyle={portfolio.productButtonStyleColor}
            btnType={portfolio.buttonType}
            btnIcon={[
              portfolio.addIcon,
              portfolio.icon?.img,
              portfolio.iconColor,
            ]}
            btnLabel={
              portfolio?.productsButtonLabel?.label || portfolio?.buttonLabel
            }
            parallax={[
              portfolio?.productListParallaxImage,
              portfolio?.parallaxImagePosition,
            ]}
            bgImage={[
              portfolio?.addBackgroundImage,
              portfolio?.backgroundImage,
              portfolio?.backgroundImageStyle,
            ]}
            order={portfolio?.invertLayoutOrder}
            showTypeGeneric={portfolio.showType}
            featuredSize={portfolio.featuredImageSize}
            typeOfNavigation={portfolio.typeOfNavigation}
            description={portfolio.showProductDescription}
            brand={portfolio.showBrand}
          />
        </>
      )}
      {!portfolio?.featuredSectionPosition && (
        <Blocks
          portfolioSlug={portfolioSlug}
          sections={portfolio?.featureSection}
          relations={relations}
        ></Blocks>
      )}
    </GeoWrapperPage>
  );
}

export async function _getStaticProps(
  context,
  pageType,
  serverSideTranslations,
  siteConfig
) {
  const preview = context.preview !== undefined ? context.preview : false;
  const currentSlug =
    context.params.slug.length > 1
      ? context.params.slug[1]
      : context.params.slug[0];

  const portfolioRealSlug = getRealPortfolioSlug(currentSlug, context.locale);

  const result = await getPortfolio(
    context.locale,
    preview,
    siteConfig.siteConfiguration.id,
    portfolioRealSlug
  );

  const pageData = await pageHandler(
    context,
    serverSideTranslations,
    result,
    "",
    true
  );
  let products = [];
  // Remove product with hide property
  if (pageData.data) {
    if (pageData.data?.brandsProduct?.productsList?.length > 0) {
      pageData.data.brandsProduct.productsList =
        pageData?.data?.brandsProduct?.productsList
          ?.map((list) => list.productsDefinition)?.[0]
          ?.filter((productDefinition) => {
            if (!productDefinition.hide) {
              products.push(productDefinition.product?.id);
              return productDefinition;
            }
          });
    }

    if (pageData.data.brandsProduct?.productsCollection?.length > 0) {
      pageData.data.brandsProduct.productsCollection =
        pageData?.data?.brandsProduct?.productsCollection?.filter(
          (collection) => {
            if (collection.hideHome === false) {
              products.push(collection?.product?.id);
              return collection;
            }
          }
        );
    }

    products = products.filter((product) => product !== undefined);

    const awards =
      products.length > 0 ? await getAwardsByProductsIdsList(products) : [];
    const ratings =
      products.length > 0 ? await getRatingsByProductsIdsList(products) : [];

    pageData?.data?.brandsProduct?.productsList?.map((list) => {
      list.product.ratings =
        ratings?.allRatings?.filter(
          (rating) => rating?.product?.id === list.product?.id
        ) || [];
      list.product.awards =
        awards?.allAwards?.filter(
          (award) => award?.product?.id === list.product?.id
        ) || [];

      // Order ratings by year and get the 3 first
      const orderedRatings = list.product.ratings
        .sort((a, b) => b.ratingYear - a.ratingYear)
        .slice(0, 3);
      // Order awards by year and get the 3 first
      const orderedAwards = list.product.awards
        .sort((a, b) => b.awardYear - a.awardYear)
        .slice(0, 3);

      // Merged list of awards and ratings ordered by year
      list.product.awardsAndRatings = [
        ...orderedAwards,
        ...orderedRatings,
      ].sort((a, b) => b.ratingYear - a.ratingYear);
    });
  }

  const portfolioSlugs = buildPortfolioSlug(
    pageData.data.brandsProduct?.slug,
    pageData.data.brandsProduct?.__typename
  );

  const _allSlugLocales =
    portfolioSlugs?.[0]?._allSlugLocales?.[0]?.value !== undefined
      ? portfolioSlugs?.[0]?._allSlugLocales
      : null;

  const hrefLangs = buildHrefLangsList(
    _allSlugLocales,
    pageData.data.brandsProduct?.__typename
  );

  return {
    props: {
      siteConfig: siteConfig.siteConfiguration,
      ...pageData.sst,
      data: {
        ...pageData.data,
        currentSlug,
        siteConfig,
        _allSlugLocales,
        hrefLangs,
      },
      preview: pageData.preview,
      pageType,
    },
    revalidate: 3600,
  };
}
