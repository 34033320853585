import {
  FeaturedCopyCard,
  FeaturedCard,
} from "@/components/Molecules/CopyCard";
import { FullBackgroundImage } from "@/components/Molecules/ImageCard";

import styles from "./index.module.scss";

export default function Experiences(data: any) {
  return (
    <div
      style={{ backgroundColor: data.bgColor?.color.hex }}
      className={` relative w-full  overflow-hidden ${
        data.useSectionMargins ? "py-12 lg:py-24" : "py-0"
      } `}
    >
      <div className="max-w-[1360px] mx-auto ">
        <div className="container mx-auto relative w-full flex flex-col py-12 lg:py-24">
          <div className="grid grid-cols-2  px-6  lg:px-12  ">
            <div
              className={` col-span-2 lg:col-span-1 flex flex-col gap-y-4 mb-12`}
            >
              <FeaturedCopyCard
                title={data.title}
                description={data.description}
                color={data.textColor?.color}
                customUnderlineAsset={data.customUnderlineAsset}
                underline={data.addTitleUnderline}
                underlineColor={data.underlineColor?.color}
                pageRelated={data.pageRelated}
                label={[data.addLabel, data.titleLabel]}
              />
            </div>
          </div>
          <div className={`${styles.bannersGrid}`}>
            {data.experiences.map((entry: any, i: number) => (
              <div
                key={i}
                className={`${styles.featuredBannerContainer} group cursor-pointer `}
              >
                {entry.image && (
                  <FullBackgroundImage className="" data={entry.image} />
                )}
                <span className="w-full h-full top-0 absolute bg-black opacity-50"></span>
                <span className="transition duration-1000 ease-out group-hover:translate-y-[-8%]">
                  <FeaturedCard
                    title={entry.title}
                    fontColor={data.cardTextColor?.color}
                    content={entry.description}
                    link={entry.bookUrl}
                    options={data}
                  />
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export const fragment = `
    fragment SectionExperienceFragment on SectionExperienceRecord{
      __typename
      id
      title
      description(markdown: true)
      addLabel
      titleLabel(markdown: true)
      textColor{
        color{
          hex
          alpha
        }
      }
      bgColor {
        color {
          hex
          alpha
        }
      }
      useSectionMargins
      addTitleUnderline
      underlineColor{
        color{
          hex
          alpha
        }
      }
      cardTextColor{
        color{
          hex
          alpha
        }
      }
      buttonStyle
      labelColor {
   
        color {
          hex
          alpha
        }
      }
      iconColor {
  
        color {
          hex
          alpha
        }
      }
      icon {
        img {
          url
        }
      }
      addIcon
      buttonLabelText {
        label
      }
      typeColor {
   
        color {
          hex
        }
      }
      experiences {
        title
        description
        image {
          responsiveImage {
            ... responsiveImageFragment
          }
        }
        bookUrl
      }
  }
`;
