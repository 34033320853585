import { pageHandler } from "@/lib/pages-handler";
import { useRouter } from "next/router";
import FeaturedSection from "@/components/Organisms/FeaturedSection";
import { ProductGrid } from "@/components/Organisms/Product";
import { getPortfolio } from "@/dato-api/portfolio";
import MenuPortfolio from "@/components/Organisms/MenuPortfolio";
import {
  buildHrefLangsList,
  getPortfolioSlug,
  getRealPortfolioSlug,
  portfolioMapper,
} from "@/lib/functions";
import TextImageHalf from "@/organisms/TextImage/TextImageHalf";
import dynamic from "next/dynamic";
import HrefLangs from "@/components/Atoms/hrefLangs";
import OgUrl from "@/components/Atoms/ogUrl";

const Hero = dynamic(() => import("@/components/Organisms/Hero"));

export default function BrandsPortfolioPage({ data, relations }) {
  const { locale, query } = useRouter();
  const slugDisplay = query?.slug?.[query.slug?.length - 1].replace(
    ".json",
    ""
  );
  const portfolioSlug = getPortfolioSlug(data.brandsProduct, locale);
  const slugContent = data.brandsProduct?.productsCollection?.filter((x) =>
    x.title.slug.includes(slugDisplay)
  );

  return (
    <>
      <HrefLangs links={data.hrefLangs} />
      <OgUrl
        url={
          data.hrefLangs.filter((link) => link.hrefLang === locale)?.[0]?.href
        }
      />
      <Hero data={data.brandsProduct?.hero} />
      {data.brandsProduct?.showNavbar && (
        <MenuPortfolio
          bg={data.brandsProduct?.hero.bgColor}
          text={data.brandsProduct?.hero.textColor}
          data={data.brandsProduct?.menu}
          portfolioSlug={portfolioSlug}
        />
      )}
      {slugContent?.map((entry, i) => (
        <div key={i}>
          <FeaturedSection data={entry.title} />
          {entry.swapProductsForDefinitions ? (
            <ProductGrid
              bgColor={data.brandsProduct.bgColor}
              text={data.brandsProduct.productTextColor}
              style={data.brandsProduct.productStyleColor}
              typeCard={data.brandsProduct.productListCard}
              btn={data.brandsProduct.productButtonTextColor}
              btnStyle={data.brandsProduct.productButtonStyleColor}
              productDefinition={entry.productsDefinitionCollection}
              portfolioSlug={portfolioSlug}
              buttonConfig={data.brandsProduct?.buttonTypeConfig}
            />
          ) : (
            <ProductGrid
              bgColor={data.brandsProduct.bgColor}
              text={data.brandsProduct.productTextColor}
              style={data.brandsProduct.productStyleColor}
              typeCard={data.brandsProduct.productListCard}
              btn={data.brandsProduct.productButtonTextColor}
              btnStyle={data.brandsProduct.productButtonStyleColor}
              product={entry.productsCollection}
              portfolioSlug={portfolioSlug}
              buttonConfig={data.brandsProduct?.buttonTypeConfig}
            />
          )}
          {entry.title?.sections.length > 0 && (
            <TextImageHalf data={entry.title?.sections[0]} />
          )}
        </div>
      ))}
    </>
  );
}

export async function _getStaticProps(
  context,
  pageType,
  serverSideTranslations,
  siteConfig
) {
  const preview = context.preview !== undefined ? context.preview : false;
  const currentSlug =
    context.params.slug.length > 1
      ? context.params.slug[1]
      : context.params.slug[0];

  const portfolioSlug = context.params.slug[0];

  const portfolioRealSlug = getRealPortfolioSlug(portfolioSlug, context.locale);

  const result = await getPortfolio(
    context.locale,
    preview,
    siteConfig.siteConfiguration.id,
    portfolioRealSlug
  );

  const pageData = await pageHandler(
    context,
    serverSideTranslations,
    result,
    "",
    true
  );

  const portfolioSlugsMapper = portfolioMapper[portfolioRealSlug];
  const currentCollection =
    pageData.data.brandsProduct.productsCollection.filter((x) =>
      x.title.slug.includes(currentSlug)
    );
  const _allSlugLocales = currentCollection[0]?.title?._allSlugLocales;

  _allSlugLocales?.map((slug) => {
    const path1 = portfolioSlugsMapper[slug.locale];
    slug.value = `${path1}/${slug.value}`;
  });

  const hrefLangs = buildHrefLangsList(
    _allSlugLocales,
    pageData.data.brandsProduct?.__typename
  );

  return {
    props: {
      siteConfig: siteConfig.siteConfiguration,
      ...pageData.sst,
      data: { ...pageData.data, hrefLangs, _allSlugLocales },
      preview: pageData.preview,
      pageType,
    },
    revalidate: 3600,
  };
}
