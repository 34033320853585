"use client";

import { Marker, Polygon } from "@react-google-maps/api";
import { useState, useLayoutEffect } from "react";
import { Sidebar } from "@/components/Molecules/SidebarMap/index";
import { cva } from "class-variance-authority";
import { MapComponent } from "@/components/Molecules/MapComponent";

let markerDefault = "/static/images/marker-default.svg";
let markerHover = "/static/images/marker-filled.svg";
let markerList = "/static/images/marker-list.svg";
let markerBig = "/static/images/marker.svg";

export function useMediaQuery() {
	const [screenSize, setScreenSize] = useState([0, 0]);

	useLayoutEffect(() => {
		function updateScreenSize() {
			setScreenSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateScreenSize);
		updateScreenSize();
		return () => window.removeEventListener("resize", updateScreenSize);
	}, []);

	return screenSize;
}

export const handlePolygonCode = (polygonCode: any) => {
	if (polygonCode === undefined || polygonCode.length === 0) return;

	let result = [];
	if (polygonCode.indexOf("[") === -1) {
		const regex = /(\s*)?(-?\s?\d+.\d+|\s),((\s)?-?\d+.\d+)(,\d+)?/gm;
		const subst = `[$2,$3],`;

		let sp;
		try {
			sp = polygonCode.replace(/\\n/, " ").split(",").join(",");
			result = sp
				.trim()
				.replace(/ /g, "")
				.replace(regex, subst)
				.replace(/.$/, "");
			result = JSON.parse(`[${result}]`);
		} catch (error) {
			console.error(error, sp);
		}
	} else {
		result = JSON.parse(polygonCode);
	}

	const final = result
		.filter((f: any) => f != "")
		.map((m: any) => {
			if (m != "") return { lat: m[1], lng: m[0] };
		});

	return final;
};

export const createPolygonArray = (locations: any) => {
	if (locations.length === 0) return;
	let locationsAdded: any[] = [];
	return locations
		.filter((location: any) => location.region.polygonShapes)
		.map((location: any) => {
			if (
				locationsAdded.length === 0 ||
				locationsAdded.indexOf(location.region.name) === -1
			) {
				locationsAdded.push(location.region.name);
				return {
					id: location.id,
					regionId: location.region.id,
					region: location.region,
					color: location.region.colorShape,
					paths: location.region.polygonShapes
						.filter((f: any) => f !== undefined)
						.map((m: any) => handlePolygonCode(m.coordinates)),
				};
			}
		})
		.filter((f: any) => f !== undefined);
};

export const createCountriesPolygonArray = (locations: any) => {
	if (locations.length === 0) return;
	let locationsAdded: any[] = [];
	return locations
		.filter((location: any) => location.country.polygonShapes)
		.map((location: any) => {
			if (
				locationsAdded.length === 0 ||
				locationsAdded.indexOf(location.country.title) === -1
			) {
				locationsAdded.push(location.country.title);
				return {
					countryId: location.country.id,
					country: location.region,
					color: location.country.colorTheme,
					paths: location.country.polygonShapes
						.filter((f: any) => f !== undefined)
						.map((m: any) => handlePolygonCode(m.coordinates)),
				};
			}
		})
		.filter((f: any) => f !== undefined);
};

const MapContainerVariants = cva(`relative mx-auto w-full `, {
	variants: {
		useSectionMargins: {
			true: "py-6 md:py-12",
			false: "py-0",
		},
		useExtraTopMargins: {
			true: "pt-12 pb-6 md:pt-24 md:pb-12",
			false: "",
		},
		useExtraPadding: {
			true: "pb-32 lg:pb-32",
			false: "",
		},
	},
	defaultVariants: {
		useSectionMargins: false,
		useExtraTopMargins: false,
		useExtraPadding: false,
	},
});

export default function EstateMap(data: any) {
	const [width] = useMediaQuery();
	let [location, setLocation] = useState(false);
	let [currentActive, setCurrentActive] = useState(false);
	let [zoom, setZoom] = useState<number | null>(null);
	let [center, setCenter] = useState<Object | boolean>(false);
	const [showSidebarOverlay, setShowSidebarOverlay] = useState(false);
	const [currentRegion, setCurrentRegion] = useState<Object[]>([]);
	const [currentCountry, setCurrentCountry] = useState<Object[]>([]);


	let [markers, setMarkers] = useState(
		data.locations &&
			data.locations
				.filter((location: any) => location.gps)
				.map((location: any, key: number) => {
					return {
						key: key,
						id: location.id,
						regionId: location.region.id,
						country: location.country,
						name: location.name,
						url: markerDefault,
						data: location,
						position: {
							lat: location.gps.latitude,
							lng: location.gps.longitude,
						},
					};
				})
	);

	let [polygons, setPolygons] = useState(
		data.locations && createPolygonArray(data.locations)
	);

	let [polygonsCountries, setPolygonsCountries] = useState(
		data.locations && createCountriesPolygonArray(data.locations)
	);

	let [currentMarkers, setCurrentMarkers] = useState<Object[]>([]);

	const setMarkerUrl = (locationId: any, url: any) => {
		let markersNew = markers.map((item: any) => {
			item.url = markerDefault;
			return item;
		});

		let objIndex = markersNew.findIndex((obj: any) => obj.id == locationId);
		markersNew[objIndex].url = url;
		setMarkers(markersNew);
	};

	const setMarkerUrlAsDefault = () => {
		let markersNew = markers.map((item: any) => {
			item.url = markerDefault;
			return item;
		});

		setMarkers(markersNew);
	};

	const clickListItem = (locationId: any) => {
		let location = data.locations.find((item: any) => item.id === locationId);

		if (location) {
			let polygon = polygons.filter(
				(p: any) => p.regionId === location.region.id
			);
			setCurrentActive(location.id);
			setMarkerUrl(location.id, markerBig);
			setCurrentMarkers([
				{
					latitude: location.gps.latitude,
					longitude: location.gps.longitude,
					paths: polygon[0].paths,
				},
			]);
			setLocation(location);
			setCenter({ lat: location.gps.latitude, lng: location.gps.longitude });
			if (width < 450) {
				setZoom(10);
			} else setZoom(13);
			/* setShowSidebarOverlay(true); */
		}
	};

	const clickOnRegion = (region: any) => {
		if (region) {
			let polygon = polygons.filter(
				(p: any) => p.regionId === region[0].regionId
			);
			setCurrentRegion([{ region: region, polygon: polygon }]);
			setMarkerUrlAsDefault();
			setCurrentActive(false);
		}
	};

	const setCurrentRegionWhenIsOnlyOne = () => {
		if (data.locations !== null && data.locations.length > 0) {
			let region = data.locations[0].region;
			let polygon = polygons.filter((p: any) => p.regionId === region.id);
			setCurrentRegion([{ region: region, polygon: polygon }]);
		}
	};

	const clickOnCountry = (country: any) => {
		if (country) {
			let polygon = polygonsCountries.filter(
				(p: any) => p.countryId === country.id
			);
			setCurrentCountry([{ country: data.region, polygon: polygon }]);
			setMarkerUrlAsDefault();
			setCurrentActive(false);
		}
	};

	const onCloseSidebar = () => {
		setShowSidebarOverlay(false);
	};

	const onZoomChange = (zoom: any) => {
		setZoom(zoom);
	};

	return (
		<div
			className="relative"
			style={{ backgroundColor: data?.bgColor?.color?.hex }}
		>
			<div
				className={`${MapContainerVariants({
					useSectionMargins: data?.useSectionMargins,
					useExtraTopMargins: data?.useExtraTopMargins,
					useExtraPadding: data?.useExtraBottomMargins,
				})} `}
			>
				<div className="flex flex-col-reverse md:flex-row h-[90vh] md:h-full w-screen overflow-hidden">
					<Sidebar
						currentActive={currentActive}
						location={location}
						icon={markerList}
						iconHover={markerHover}
						markers={markers}
						clickListItem={clickListItem}
						onCloseSidebar={onCloseSidebar}
						showSidebarOverlay={showSidebarOverlay}
						clickOnRegion={clickOnRegion}
						clickOnCountry={clickOnCountry}
						color={data?.bgColor?.color?.hex}
					/>
					<div
						className={`relative ${
							showSidebarOverlay
								? "md:transform md:duration-300 md:w-2/4"
								: "md:transform md:duration-100 md:w-3/4"
						}`}
					>
						<MapComponent
							hookWidth={width}
							defaultZoom={8}
							mapStyle
							key={markers.key}
							defaultCenter={{ lat: 41.14138, lng: -7.7671 }}
							region={data.region}
							markers={
								data.locations &&
								data.locations
									.filter(({ gps }: { gps: any }) => gps)
									.map(({ gps, i }: { gps: any; i: number }) => (
										<span key={i}>{gps}</span>
									))
							}
							currentMarkers={currentMarkers}
							currentRegion={currentRegion}
							currentCountry={currentCountry}
							zoom={zoom}
							center={center}
							onZoomChange={onZoomChange}
							polygons={polygons}
							setCurrentRegionWhenIsOnlyOne={setCurrentRegionWhenIsOnlyOne}
						>
							{markers &&
								markers.map((item: any, index: number) => (
									<Marker
										key={index}
										icon={item.url}
										position={item.position}
										onClick={() => clickListItem(item.id)}
									/>
								))}
							{polygons &&
								polygons.map((polygon: any) => {
									return polygon.paths.map((p: any, index: number) => (
										<Polygon
											key={index}
											paths={p}
											options={{
												fillColor:
													polygon.color !== null
														? polygon.color.hex
														: "#FFFFFF",
												fillOpacity: 0.15,
												strokeColor: "#000",
												strokeOpacity: 0,
												strokeWeight: 0,
											}}
										/>
									));
								})}
						</MapComponent>
					</div>
				</div>
			</div>
		</div>
	);
}

export const fragment = `
fragment SectionWineTourismFragment on SectionWineTourismRecord {
	id
	title
    description
	useSectionMargins
	useExtraTopMargins
	useExtraBottomMargins
	bgColor {
        color {
          hex
        }
      }
    locations {
        id
        name
        description
        excerpt
		addExtraSidebarPages
        page { 
            __typename
            slug
        }
        country {
            id
            title
            colorTheme {
                hex
            }
            polygonShapes {
            ... on PolygonRecord {
                name
                coordinates
                }
            }
        }
        brand {
        title
        }
        gallery {
        url
        basename
        }
        gps {
        latitude
        longitude
        }
        region {
        id
        name
        polygonShapes {
            ... on PolygonRecord {
                name
                coordinates
                }
        }
        colorShape {
            hex
        }
        }    
	}
}
  `;
