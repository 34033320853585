import { MultiPurposeParagraph } from "@/components/Atoms/Paragraph";
import { Shape } from "@/components/Atoms/Spacer";
import { IconImage } from "../ImageCard";
import styles from "./index.module.scss";

interface Props {
  data: any;
}

interface Main {
  description: string;
  bgColor: any;
  color: any;
  layout: string;
  asset: any;
  shape?: any;
  subColor: any;
}

export function TestimonialMain({
  description,
  bgColor,
  color,
  subColor,
  layout,
  asset,
  shape,
}: Main) {
  const style = {
    "--shape": `url(${shape?.img?.url})`,
    "--color": color?.color.hex,
    "--bg": bgColor.color?.hex,
  } as React.CSSProperties;
  return (
    <>
      {shape && (
        <Shape
          shape={shape}
          color={bgColor}
          direction={"outer"}
          position={"top"}
          invert={false}
        />
      )}
      <div
        style={{ background: bgColor?.color.hex }}
        className=" mx-auto w-full relative"
      >
        <article className="text-center italic container lg:max-w-[50%] mx-auto py-16 px-6 lg:px-12">
          <MultiPurposeParagraph
            type="quote"
            data={description}
            color={color.color}
          />
        </article>
        <div className="svgContainer relative w-full h-24 md:h-20 ">
          <div className="absolute left-0 top-0 w-full h-24  overflow-hidden"></div>
        </div>
        <div className="iconContainer absolute mx-auto left-0 right-0 -bottom-16 z-10 text-center">
          <IconImage data={asset} />
          <MultiPurposeParagraph
            type="default"
            color={subColor.color}
            data={asset.title}
          />
        </div>
      </div>
      {shape && (
        <div
          style={style as React.CSSProperties}
          className={styles.shapeBottom}
        ></div>
      )}
    </>
  );
}

interface List {
  testimonials: any;
  color: any;
  bgColor: any;
  layout: string;
  shape: any;
}

export function TestimonialList({
  testimonials,
  color,
  bgColor,
  layout,
}: List) {
  const style = {
    "--color": color?.color.hex,
    "--bg": bgColor.color?.hex,
  } as React.CSSProperties;
  return (
    <>
      <div className=" mx-auto w-full  relative pb-12 lg:pb-20">
        <article className="QuoteArticle flex flex-col lg:flex-row items-center lg:items-start gap-y-12 justify-center gap-x-12 container pt-12  px-6 lg:px-12  mx-auto">
          {testimonials.map((entry: any, i: number) => (
            <div key={i} className=" text-center italic">
              <MultiPurposeParagraph
                type={"quote"}
                data={entry.statement}
                color={color.color}
              />
            </div>
          ))}
        </article>
      </div>
    </>
  );
}
