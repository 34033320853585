"use client";
import {
  Paragraph,
  FeaturedParagraph,
  MultiPurposeParagraph,
} from "@/components/Atoms/Paragraph";
import Image from "@/components/Atoms/Image";
import { useTranslation } from "next-i18next";
import styles from "./index.module.scss";
import { cva } from "class-variance-authority";
import { MapComponent } from "@/components/Molecules/MapComponent";
import { useEffect, useState } from "react";
import { Marker } from "@react-google-maps/api";
import { useMediaQuery } from "../EstateMap";

const MapContainerVariants = cva(`mx-auto relative w-full px-6 md:px-12`, {
  variants: {
    useSectionMargins: {
      true: "py-6 md:py-12",
      false: "py-0",
    },
    useExtraTopMargins: {
      true: "pt-12 pb-6 md:pt-24 md:pb-12",
      false: "",
    },
    useExtraPadding: {
      true: "pb-32 lg:pb-32",
      false: "",
    },
  },
  defaultVariants: {
    useSectionMargins: false,
    useExtraTopMargins: false,
    useExtraPadding: false,
  },
});

export default function SectionLocation(data: any) {
  const { t } = useTranslation();
  const [zoom, setZoom] = useState<number | null>(null);
  const [width] = useMediaQuery();

  const onZoomChange = (zoom: number | null) => {
    setZoom(zoom);
  };

  return (
    <>
      {data.locationType === "block" ? (
        <div
          style={{ backgroundColor: data.backgroundColor?.hex }}
          className="section w-full px-6 lg:px-12"
        >
          <div className="max-w-[1360px] mx-auto">
            <div className="container grid grid-cols-1 xl:grid-cols-3  gap-12 py-12 mx-auto">
              {data.content.map((entry: any, i: number) => (
                <div key={i} className="col-span-1 flex flex-col gap-4 ">
                  {entry.isPhysicalAdress && (
                    <>
                      <a
                        className="flex flex-col gap-4 "
                        href={entry.googleMapsLocation}
                        target="_blank"
                      >
                        <div className={styles.contactTitle}>
                          <Image data={entry?.icon} />
                          <MultiPurposeParagraph
                            type="subLabel"
                            data={t("location")}
                            color={data.fontColor}
                          />
                        </div>

                        <MultiPurposeParagraph
                          type="image"
                          data={entry.address}
                          color={data.fontColor}
                        />
                      </a>
                    </>
                  )}
                  {entry.isEContacts && (
                    <>
                      <div className={styles.contactTitle}>
                        <Image data={entry?.icon} />
                        <MultiPurposeParagraph
                          type="subLabel"
                          data={t("contacts")}
                          color={data.fontColor}
                        />
                      </div>

                      <div className="flex flex-row gap-2">
                        <MultiPurposeParagraph
                          type="image"
                          data={`${t("email")}: ${entry.email}`}
                          color={data.fontColor}
                        />
                      </div>
                      <div className="flex flex-row gap-2">
                        <MultiPurposeParagraph
                          type="image"
                          data={`${t("mobile")}: ${entry.mobile}`}
                          color={data.fontColor}
                        />
                      </div>
                      <div className="flex flex-row gap-2">
                        <MultiPurposeParagraph
                          type="image"
                          data={`${t("telephone")}: ${entry.telephone}`}
                          color={data.fontColor}
                        />
                      </div>
                    </>
                  )}
                  {entry.isSchedule && (
                    <>
                      <div className={styles.contactTitle}>
                        <Image data={entry?.icon} />
                        <MultiPurposeParagraph
                          type="subLabel"
                          data={t("schedule")}
                          color={data.fontColor}
                        />
                      </div>
                      <div className="flex flex-row gap-2">
                        <MultiPurposeParagraph
                          type="image"
                          data={entry.timeFrames}
                          color={data.fontColor}
                        />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="relative"
          style={{ backgroundColor: data?.backgroundColor?.hex }}
        >
          <div
            className={`${MapContainerVariants({
              useSectionMargins: data?.useSectionMargins,
              useExtraTopMargins: data?.useExtraTopMargins,
              useExtraPadding: data?.useExtraPadding,
            })} `}
          >
            <MapComponent
              width={width}
              key={data.id}
              defaultCenter={{
                lat: data.location.latitude,
                lng: data.location.longitude,
              }}
              zoom={zoom}
              onZoomChange={onZoomChange}
              markers={[data.location]}
            >
              <Marker
                position={{
                  lat: data.location.latitude,
                  lng: data.location.longitude,
                }}
              />
            </MapComponent>
          </div>
        </div>
      )}
    </>
  );
}

export const fragment = `
    fragment SectionLocationFragment on SectionLocationsMapRecord{
      __typename
      id
      locationType
      name
      useSectionMargins
      useExtraTopMargins
      useExtraPadding
      location{
        latitude
        longitude
      }
      fontColor{
        hex
      }
      backgroundColor{
        hex
      }
      content {
        icon{
          url
          width
          height
        }
        isPhysicalAdress
        isSchedule
        isEContacts
        email
        mobile
        telephone
        address
        googleMapsLocation
        timeFrames
      }
    }
`;
