import { VariantProps, cva } from "class-variance-authority";
import Image from "@/components/Atoms/Image";
import styles from "./index.module.scss";

export type ShapeVariantProps = VariantProps<typeof ShapeVariants>;
const ShapeVariants = cva(`left-0 z-10`, {
  variants: {
    direction: {
      Inner: `translate-y-[-38%] md:translate-y-[-30%]`,
      outer: `translate-y-[-60%] md:translate-y-[-70%]`,
      hero: `translate-y-[46%] sm:translate-y-[42%] md:translate-y-[40%] lg:translate-y-[34%]`,
      merge: `translate-y-[-55%] md:translate-y-[-65%]`,
    },
    invert: {
      true: "scale-[-1] translate-y-[-45%]",
      false: "",
    },
    position: {
      top: "top-0",
      bottom: "bottom-0",
      invert: false,
    },
  },
  compoundVariants: [
    {
      direction: "Inner",
      position: "bottom",
      class: "translate-y-[20%] md:translate-y-[15%]",
    },
    {
      direction: "outer",
      position: "bottom",
      class: "translate-y-[85%] md:translate-y-[88%]",
    },
  ],
});

interface Props {
  shape: any;
  color: any;
  direction?: "Inner" | "outer" | "merge" | "hero";
  invert: boolean;
  icon?: any;
  position: "bottom" | "top" | "invert" | null | undefined;
}

export function ShapeTop({ shape, color }: Props): JSX.Element {
  const style = {
    "--shape": `url(${shape?.img?.url})`,
    "--color": color?.color.hex,
  } as React.CSSProperties;
  return (
    <div style={style as React.CSSProperties} className={styles.shapeTop}></div>
  );
}

export function Shape({
  shape,
  color,
  direction,
  icon,
  position,
  invert,
}: Props): JSX.Element {
  const style = {
    "--shape": `url(${shape?.img?.url})`,
    "--color": color?.color.hex,
    "--width": `${shape?.img?.width}px`,
    "--height": `${shape?.img?.height}px`,
  } as React.CSSProperties;

  return (
    <div>
      {icon?.[0] && (
        <Image
          className={`${styles.icon} m-auto absolute left-0 right-0`}
          data={icon[1]?.img}
        />
      )}
      <div
        style={style as React.CSSProperties}
        className={`${ShapeVariants({ direction, position, invert })}  ${
          styles.shape
        } `}
      ></div>
    </div>
  );
}
