import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import styles from "./index.module.scss";

interface Props {
  data: string;
  color: any[any];
  layout?: string;
  columns?: string;
}

export const MultiTextComponent = ({ data, color, layout, columns }: Props) => {
  const style = {
    "--color": color?.hex,
  } as React.CSSProperties;

  return (
    <>
      <article
        style={style as React.CSSProperties}
        className={` ${styles.color}  flex flex-col gap-2 ${
          columns == "single" ? "gap-8 " : ""
        }  ${
          layout === "center"
            ? "text-center"
            : layout === "left"
            ? "text-left"
            : layout === "right"
            ? "text-left"
            : layout === "fixedCenter"
            ? "text-center"
            : "text-left"
        } 
        `}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            h3: ({ node, ...props }) => (
              <h3
                className={` ${
                  data.includes("<p>") ? "max-w-[450px]" : "max-w-[950px]"
                } mx-auto leading-tight MultibrandTitle`}
                {...props}
              />
            ),
            div: React.Fragment,
            p: ({ node, ...props }) => (
              <p className={`brandParagraph mx-auto w-full`} {...props} />
            ),
            blockquote: ({ node, ...props }) => (
              <span className={styles.blockquote} {...props} />
            ),
            ul: ({ node, ...props }) => (
              <ul {...props} className="flex flex-col gap-2" />
            ),
            li: ({ node, ...props }) => (
              <li
                style={{ listStyleType: "disc" }}
                className={` mx-auto w-full`}
                {...props}
              />
            ),
          }}
        >
          {data}
        </ReactMarkdown>
      </article>
    </>
  );
};

export const GalleryText = ({ data, color }: Props) => {
  const style = {
    "--color": color?.hex,
  } as React.CSSProperties;

  return (
    <>
      <article
        style={style as React.CSSProperties}
        className={` ${styles.color}  flex flex-col gap-4
        `}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            h3: ({ node, ...props }) => (
              <h3
                className={` text-md  leading-tight brandParagraph`}
                {...props}
              />
            ),
            h2: ({ node, ...props }) => (
              <h3
                className={` text-md   leading-tight brandParagraph`}
                {...props}
              />
            ),
            div: React.Fragment,
            p: ({ node, ...props }) => (
              <p
                className="text-xs flex flex-col gap-4  brandParagraph"
                {...props}
              />
            ),
            blockquote: ({ node, ...props }) => (
              <span className={styles.blockquote} {...props} />
            ),
          }}
        >
          {data}
        </ReactMarkdown>
      </article>
    </>
  );
};
