import { MultiPurposeTitle, Title } from "@/components/Atoms/Title";
import { MultiPurposeParagraph, Paragraph } from "@/components/Atoms/Paragraph";
import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import { MultiTextComponent } from "@/components/Atoms/MultiText";

interface Props {
  data: any;
}

export default function FeaturedSection({ data }: Props) {
  return (
    <>
      {data.image ? (
        <div className="flex flex-col">
          <div
            style={{ background: data.bgColor?.color.hex }}
            className={`grid grid-cols-2`}
          >
            <div className="col-span-2 lg:col-span-1  order-2 lg:order-1">
              <div
                className={`flex flex-col py-12 px-12 lg:px-24 lg:min-h-[400px] items-center justify-center`}
              >
                <div className="gap-4 flex flex-col">
                  {data.title && (
                    <MultiPurposeTitle
                      tag="h3"
                      data={data.title}
                      color={data.color?.color}
                    />
                  )}
                  {data.description && !data.showDescriptionApart && (
                    <MultiPurposeParagraph
                      type="default"
                      color={data.color?.color}
                      data={data.description}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className=" order-1 lg:order-2 min-h-[350px] lg:min-h-[400px] col-span-2 lg:col-span-1 relative">
              <FullBackgroundImage data={data.image} />
            </div>
          </div>

          {data.showDescriptionApart && (
            <div
              style={{
                backgroundColor: data.bgDescriptionColColor?.color.hex,
              }}
              className="lg:columns-2 gap-12 px-10 py-8 md:min-h-44 md:max-h-full"
            >
              <MultiTextComponent
                columns={"double"}
                color={data.descriptionTextColor?.color}
                data={data.description}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col">
          <div
            style={{ background: data.bgColor?.color.hex }}
            className={` flex flex-row justify-center min-h-[150px] py-6 lg:py-12`}
          >
            <div className="max-w-[850px] mx-auto">
              <div className="px-6 lg:px-12 gap-4 flex flex-col container">
                {data.title && (
                  <MultiPurposeTitle
                    type="default"
                    tag="h3"
                    data={data.title}
                    color={data.color?.color}
                    layout={"featuredCenter"}
                  />
                )}
                {data.description && !data.showDescriptionApart && (
                  <MultiPurposeParagraph
                    color={data.color?.color}
                    data={data.description}
                    align={"center"}
                  />
                )}
              </div>
            </div>
          </div>
          {data.showDescriptionApart && (
            <div>
              <MultiTextComponent
                layout={"left"}
                color={data.descriptionTextColor?.color}
                data={data.description}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
