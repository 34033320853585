import React from "react";

import "react-multi-carousel/lib/styles.css";
import { MultiPurposeTitle, Title } from "@/components/Atoms/Title";
import styles from "./index.module.scss";
import Image from "@/components/Atoms/Image";
import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import { CustomUnderline, Underline } from "@/components/Atoms/Underline";
import { ButtonMaster } from "@/components/Atoms/Buttons";
import { MultiPurposeParagraph } from "@/components/Atoms/Paragraph";
import GeoWrapper from "@/components/Templates/Main/GeoConditional";
import { VideoPost } from "@/components/Atoms/Vectors";
import ImageSlider from "@/components/Molecules/ImageSlider";
import { VariantProps, cva } from "class-variance-authority";

export type SocialWallVariantProps = VariantProps<typeof SocialWallVariants>;
const SocialWallVariants = cva(` flex w-full flex-col gap-4 `, {
  variants: {
    titleBlockPosition: {
      left: "justify-start",
      default: "justify-center",
    },
  },
  defaultVariants: {
    titleBlockPosition: "default",
  },
});

export type ImageBorderSocialWallVariantsProps = VariantProps<
  typeof ImageBorderSocialWallVariants
>;
const ImageBorderSocialWallVariants = cva("", {
  variants: {
    contentBorderRadius: {
      none: "",
      rounded: "rounded-lg overflow-hidden",
      roundedXl: "rounded-3xl overflow-hidden",
    },
  },
  defaultVariants: {
    contentBorderRadius: "none",
  },
});

export interface SocialWallProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    SocialWallVariantProps,
    ImageBorderSocialWallVariantsProps {
  textColor: any;
  bgColor: any;
  halfBackgroundColor: boolean;
  bgImage: any;
  title: string;
  addTitleUnderline: boolean;
  galleryConfiguration: any;
  description: string;
  addBlockBorder: boolean;
  addVisualCta: boolean;
  ctaColor: any;
  ctaIcon: any;
  customUnderlineAsset: any;
  underlineColor: any;
  useExtraPadding: boolean;
}

export default function SocialWall({
  textColor,
  bgColor,
  halfBackgroundColor,
  bgImage,
  title,
  addTitleUnderline,
  galleryConfiguration,
  contentBorderRadius,
  titleBlockPosition,
  description,
  addBlockBorder,
  addVisualCta,
  ctaColor,
  ctaIcon,
  customUnderlineAsset,
  underlineColor,
  useExtraPadding,
}: SocialWallProps) {
  const style = {
    "--color": textColor?.color?.hex,
    "--bg": bgColor?.color.hex,
  } as React.CSSProperties;

  return (
    <div
      style={style}
      className={`relative ${
        halfBackgroundColor ? `${styles.bgHalf}` : `${styles.bg}`
      } `}
    >
      <FullBackgroundImage data={bgImage} />
      <div
        className={` ${
          useExtraPadding ? "pt-12 pb-32 lg:pb-40" : "py-6 md:py-12"
        } flex flex-col gap-4 relative items-center container mx-auto  px-6 lg:px-4 xl:px-2 2xl:px-0 2xl:max-w-[1360px]`}
      >
        <header className={`${SocialWallVariants({ titleBlockPosition })}`}>
          <div className={`flex flex-col gap-2`}>
         
            <MultiPurposeTitle type="default" tag="h3" data={title} color={textColor?.color} />
            {addTitleUnderline && (
              <>
                {customUnderlineAsset?.img ? (
                  <CustomUnderline
                    shape={customUnderlineAsset?.img}
                    color={underlineColor?.color}
                  />
                ) : (
                  <Underline color={underlineColor?.color} />
                )}
              </>
            )}
          </div>
          <MultiPurposeParagraph type="featured" data={description} color={textColor?.color} />
        </header>

        {galleryConfiguration.map((gallery: any, i: number) => {
          return (
            <GeoWrapper
              key={i}
              list={gallery?.listOfCountries}
              country={gallery?.countries}
              initial={gallery?.countryAvailability}
            >
              <ImageSlider>
                {gallery.items.map((entry: any[any], index: number) => {
                  return (
                    <div
                      className={`h-full hover:scale-105 transition-all duration-500 ease-in-out p-2`}
                      key={index}
                    >
                      <a
                        style={style as React.CSSProperties}
                        className={`${
                          addBlockBorder
                            ? `${styles.border} `
                            : `${styles.defImg}`
                        } flex flex-col items-end`}
                        target="_blank"
                        href={entry?.url}
                      >
                        <Image
                          data={entry?.asset}
                          className={`${ImageBorderSocialWallVariants({
                            contentBorderRadius,
                          })}`}
                        />
                        {entry.isVideo && (
                          <div className="icon absolute right-4 top-4">
                            <VideoPost />
                          </div>
                        )}

                        {addVisualCta && (
                          <>
                            <ButtonMaster
                              anim={"slideLeft"}
                              type={"default"}
                              cta={ctaColor?.color}
                              color={textColor?.color}
                              customIcon={ctaIcon?.img}
                              customColor={ctaColor?.color}
                              link={``}
                            >
                              {entry.asset.responsiveImage?.title}
                            </ButtonMaster>
                          </>
                        )}
                      </a>
                    </div>
                  );
                })}
              </ImageSlider>
            </GeoWrapper>
          );
        })}
      </div>
    </div>
  );
}

export const fragment = `
    fragment SectionSocialFragment on SectionSocialWallRecord{
        __typename
        id
        contentBorderRadius
        bgColor {
          color{
            hex
          }
        }
        halfBackgroundColor
        textColor{
            color{
                hex
            }
        }
        addBackgroundImage
        bgImage {
          responsiveImage {
            ... responsiveImageFragment
          }
        }
        title
        description
        layout
		useExtraPadding
        addBlockBorder
        addTitleUnderline
        underlineColor{
          color{
            hex
          }
        }
        customUnderlineAsset{
          img{
            url
            width
            height
          }
        }
        addVisualCta
        ctaColor{
          color{
            hex
          }
        }
        ctaIcon{
          img{
            url
            width
            height
          }
        }
        galleryConfiguration {
          items {
            id
            url
            isVideo
            asset {
              
              responsiveImage {
                  ... responsiveImageFragment
              }
            }
          }
          countryAvailability
          listOfCountries
          countries {
            countryCode
          }
        }
  }
`;
