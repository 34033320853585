import { fetchAPI } from "@/lib/api";

import { responsiveImageFragment, pageRelatedFragment } from "@/lib/fragments";
import {
	SectionBrandArticleFragment,
	TextImageFragment,
	TextFragment,
	HeroImageFragment,
} from "@/lib/section-fragment";

export async function getArticlePage(slug, locale, preview, siteConfig) {
	const data = await fetchAPI(
		`
        query articleBySlug($slug: String, $locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
          article(filter: {slug: {eq: $slug}, websiteRelated: {eq: "${siteConfig}"}}, locale: $locale) {
            __typename
            _publishedAt
            _allSlugLocales {
              locale
              value
            }
            slug
            _seoMetaTags {
              attributes
              content
            }
            title
            excerpt
            downloadReadCta
            pageRelated{
            ...pageRelatedFragment
            }
            content {
              __typename
              ...on TextRecord{
                id
                text
              }
              ... on PageRelatedRecord {
              ...pageRelatedFragment
              }
              ... on ImageRecord{
                id
                image{
                  responsiveImage {
                    ...responsiveImageFragment
                  }
                }
              }
              ... on TextWithImageRecord{
                title
                addSubtitle
                subtitle
                text
                image{
                  responsiveImage {
                    ...responsiveImageFragment
                  }
                }
                textAlignment
                textColumnLayout
                id
              }
            }
            section{
            __typename
            ...TextImageFragment
            ...TextFragment
            }
            image {
              responsiveImage{
                ...responsiveImageFragment
                }
              }
              categories {
                id
                title
              }
                author {
                  id
                  title
                }
            }
          }
          ${responsiveImageFragment}
          ${pageRelatedFragment}
          ${TextImageFragment}
          ${TextFragment}
    `,

		{
			preview,
			variables: {
				slug,
				locale,
			},
		}
	);
	return data;
}

export async function getArticleConfig(locale, preview, siteConfig) {
	const data = await fetchAPI(
		`
        query brandArticleConfig($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
          brandArticle(filter: {websiteConfiguration: {eq: "${siteConfig}"}}, locale: $locale) {
            hideAuthorAndDate
            relatedSections {
              __typename
              ...SectionBrandArticleFragment
            }
            addParalaxImage
            parallaxImage {
              url
              width
              height
            }
            addReturnButton
            returnCta{
            ...pageRelatedFragment
            }
            dateType
            addHeroImage
            detailHeroArticle {
            ... on HeroConfigurationRecord{
              id
              useLabelBeforeTitle
              imagePosition
              heroHeight
              overlay
              imageOverlayBaseColor {
                hex
              }
              textWidth
              textAlignment
              textFlexPosition
              textColor {
                color {
                  hex
                }
              }
              labelColor {
                color {
                  hex
                }
              }
              bgColor {
                color {
                  hex
                }
              }
              addSpacer
              spacerConfiguration {
                id
                spacerDirection
                spacerPosition
                customShape {
                  img {
                    alt
                    url
                    width
                    height
                  }
                }
                addIconElement
                spacerIcon {
                  img {
                    alt
                    url
                    width
                    height
                  }
                }
              }
              addScrollIcon
              scrollIcon {
                scrollIconType
                iconColor {
                  color {
                    hex
                  }
                }
                iconBackgroundColor {
                  color {
                    hex
                  }
                }
                addCustomIconBackgroundElement
                iconBackgroundAsset {
                  img {
                    url
                  }
                }
                customScrollIcon {
                  img {
                    url
                  }
                }
                iconFeatured
              }
            }
            ... on SectionHeroImageRecord{
                ...HeroImageFragment
                }
              }
            backgroundColor {
              color {
                hex
              }
            }
            articleTextColor {
              color {
                hex
              }
            }
            highlightTextColor {
              color {
                hex
              }
            }
          }
        }
        ${HeroImageFragment}
        ${responsiveImageFragment}
        ${SectionBrandArticleFragment}
        ${pageRelatedFragment}
    `,
		{
			preview,
			variables: {
				locale,
			},
		}
	);
	return data;
}
