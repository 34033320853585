"use client";
import Image from "@/components/Atoms/Image";
import CustomLink from "@/components/Atoms/CustomLink";
import { routerHandler } from "@/lib/routes/router-links-handler";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import styles from "./index.module.scss";
import { MultiPurposeParagraph } from "@/atoms/Paragraph";
import { FeaturedTitle, MultiPurposeTitle } from "@/components/Atoms/Title";
import { ButtonMaster } from "@/atoms/Buttons";
import { ProductArticle } from "@/molecules/Article";
import { ProductTable } from "@/molecules/Article";
import { GridList, ZigZagList } from "@/components/Molecules/ProductCard";
import { CustomUnderline, Underline } from "@/components/Atoms/Underline";
import {
  RelatedCollectionDropdown,
  RelatedCollectionSlugs,
  RelatedSlugs,
  RelatedSlugsDropdown,
} from "./Related";
import { GalleryImage } from "@/components/Molecules/ImageCard";
import { MarketingBanner } from "../ImageVideo";

interface Grid {
  product: any;
  productDefinition: any;
  text: any;
  style: any;
  typeCard: any;
  btn: any;
  btnStyle: any;
  portfolioSlug: string;
  bgColor: any;
  type: string;
  buttonConfig: any;
}

export function ProductGrid({
  product,
  productDefinition,
  text,
  style,
  typeCard,
  btn,
  btnStyle,
  portfolioSlug,
  bgColor,
  type,
  buttonConfig,
}: Grid) {
  const { locale } = useRouter();
  return (
    <div style={{ background: bgColor?.color.hex }} className="w-full m-auto">
      <div className="max-w-[1360px] mx-auto">
        <div className="container mx-auto relative w-full flex flex-col py-12 lg:py-24">
          <div className="w-full  overflow-x-auto lg:overflow-visible">
            <div
              className={`${styles.grid} mt-24 pb-4 gap-y-24 gap-4 flex-nowrap pl-6 lg:px-0 lg:mt-10`}
            >
              {product &&
                product.map((entry: any, i: number) => (
                  <div
                    key={i}
                    className=" xs:col-span-2 col-span-1 flex-none lg:flex-1 text-center flex flex-col  gap-2 relative z-[1] translate-y-[-15%] group"
                  >
                    <div className="relative z-1">
                      <CustomLink
                        href={`${routerHandler({
                          typename: entry?.__typename,
                          slug: [
                            portfolioSlug,
                            entry.productTypeSpecific?.slug,
                            entry?.slug,
                          ],
                          locale: locale,
                        })}`}
                      >
                        <Image
                          data={
                            entry?.factSheetImage ||
                            entry?.sku[0]?.skuImageGallery?.[0]
                          }
                          className="max-w-[12rem] mx-auto group-hover:translate-y-[-5%] transition-all ease-in-out duration-300"
                        />
                        <div className=" mx-auto px-4 flex flex-col justify-center items-center max-w-[10rem]">
                          <MultiPurposeTitle
                            tag="p"
                            type="default"
                            data={entry.productName}
                            color={text?.color}
                          />

                          {typeCard === "default" && (
                            <ButtonMaster
                              anim={"slideLeft"}
                              type={buttonConfig?.buttonStyle}
                              color={buttonConfig?.labelColor?.color}
                              cta={buttonConfig?.typeColor?.color}
                              customIcon={buttonConfig?.icon?.img}
                              customColor={buttonConfig?.iconColor?.color}
                              link={`${routerHandler({
                                typename: entry?.__typename,
                                slug: [
                                  portfolioSlug,
                                  entry.productTypeSpecific?.slug,
                                  entry?.slug,
                                ],
                                locale: locale,
                              })}`}
                            >
                              {buttonConfig?.buttonLabel?.label ?? "+"}
                            </ButtonMaster>
                          )}
                        </div>
                      </CustomLink>
                    </div>
                    {typeCard === "pill" && (
                      <span
                        style={{
                          backgroundColor: style?.color.hex,
                        }}
                        className="absolute z-[-1] h-[65%] top-0 w-[12rem] left-0 right-0  m-auto  translate-y-[75%] rounded-t-[25%] rounded-b-[45%]"
                      >
                        <span
                          style={{
                            backgroundColor: style?.color.hex,
                          }}
                          className="absolute z-[-1] h-[12rem] top-0 w-[12rem] left-0 right-0  m-auto   translate-y-[-20%]  rounded-b-[45%]  rounded-t-[45%]"
                        />
                        <div className="absolute -bottom-4 left-0 right-0 m-auto w-fit group-hover:scale-110 transition-all ease-in-out duration-300">
                          <ButtonMaster
                            anim={"slideLeft"}
                            type={buttonConfig?.buttonStyle}
                            color={buttonConfig?.labelColor?.color}
                            cta={buttonConfig?.typeColor?.color}
                            customIcon={buttonConfig?.icon?.img}
                            customColor={buttonConfig?.iconColor?.color}
                            link={`${routerHandler({
                              typename: entry?.__typename,
                              slug: [
                                portfolioSlug,
                                entry.productTypeSpecific?.slug,
                                entry?.slug,
                              ],
                              locale: locale,
                            })}`}
                          >
                            {buttonConfig?.buttonLabel?.label ?? "+"}
                          </ButtonMaster>
                        </div>
                      </span>
                    )}

                    {typeCard === "default" && (
                      <svg
                        viewBox="0 0 259 266"
                        className="absolute z-[-1] top-0 left-0 right-0 m-auto w-[16rem] translate-y-[-10%]"
                      >
                        <path
                          d="M0.5 30.7854C80.5 -8.20996 177.513 -12.1185 258.5 30.7854V265.79H0.5V30.7854Z"
                          fill={style?.color.hex || "#E3DCC0"}
                        />
                      </svg>
                    )}
                  </div>
                ))}

              {productDefinition &&
                productDefinition.map((entry: any, i: number) => {
                  const product = entry?.product;
                  return (
                    <div
                      key={i}
                      className=" xs:col-span-2 col-span-1 flex-none lg:flex-1 text-center flex flex-col  gap-2 relative z-[1] translate-y-[-15%] group"
                    >
                      <div className="relative z-1">
                        <CustomLink
                          href={`${routerHandler({
                            typename: product.__typename,
                            slug: [
                              portfolioSlug,
                              product.productTypeSpecific.slug,
                              product.slug,
                            ],
                            locale: locale,
                          })}`}
                        >
                          <Image
                            data={
                              product.factSheetImage ||
                              product.sku[0]?.skuImageGallery?.[0]
                            }
                            className="max-w-[14rem] mx-auto group-hover:translate-y-[-5%] transition-all ease-in-out duration-300"
                          />
                          <div className="mx-auto px-4 flex flex-col justify-center items-center ">
                            {entry.customLabel ? (
                              <MultiPurposeParagraph
                                type="featured"
                                data={entry.label}
                                color={text?.color}
                                className="min-h-28"
                              />
                            ) : (
                              <MultiPurposeParagraph
                                type="featured"
                                data={product.productName}
                                color={text?.color}
                                className="min-h-28"
                              />
                            )}

                            {typeCard === "default" && (
                              <ButtonMaster
                                anim={"slideLeft"}
                                type={buttonConfig?.buttonStyle}
                                color={buttonConfig?.labelColor?.color}
                                cta={buttonConfig?.typeColor?.color}
                                customIcon={buttonConfig?.icon?.img}
                                customColor={buttonConfig?.iconColor?.color}
                                link={`${routerHandler({
                                  typename: product.__typename,
                                  slug: [
                                    portfolioSlug,
                                    product.productTypeSpecific.slug,
                                    product.slug,
                                  ],
                                  locale: locale,
                                })}`}
                              >
                                {buttonConfig?.buttonLabel?.label ?? "+"}
                              </ButtonMaster>
                            )}
                          </div>
                        </CustomLink>
                      </div>
                      {typeCard === "pill" && (
                        <span
                          style={{
                            backgroundColor: style?.color.hex,
                          }}
                          className="absolute z-[-1] h-[65%] top-0 w-[12rem] left-0 right-0  m-auto  translate-y-[75%] rounded-t-[25%] rounded-b-[45%]"
                        >
                          <span
                            style={{
                              backgroundColor: style?.color.hex,
                            }}
                            className="absolute z-[-1] h-[12rem] top-0 w-[12rem] left-0 right-0  m-auto   translate-y-[-20%]  rounded-b-[45%]  rounded-t-[45%]"
                          />
                          <div className="absolute -bottom-4 left-0 right-0 m-auto w-fit group-hover:scale-110 transition-all ease-in-out duration-300">
                            <ButtonMaster
                              anim={"slideLeft"}
                              type={buttonConfig?.buttonStyle}
                              color={buttonConfig?.labelColor?.color}
                              cta={buttonConfig?.typeColor?.color}
                              customIcon={buttonConfig?.icon?.img}
                              customColor={buttonConfig?.iconColor?.color}
                              link={`${routerHandler({
                                typename: product.__typename,
                                slug: [
                                  portfolioSlug,
                                  product.productTypeSpecific.slug,
                                  product.slug,
                                ],
                                locale: locale,
                              })}`}
                            >
                              +
                            </ButtonMaster>
                          </div>
                        </span>
                      )}

                      {typeCard === "default" && (
                        <svg
                          viewBox="0 0 259 266"
                          className="absolute z-[-1] top-0 left-0 right-0 m-auto w-[16rem] translate-y-[-10%]"
                        >
                          <path
                            d="M0.5 30.7854C80.5 -8.20996 177.513 -12.1185 258.5 30.7854V265.79H0.5V30.7854Z"
                            fill={style?.color.hex || "#E3DCC0"}
                          />
                        </svg>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface DetailProps {
  forwardedRef: any;
  tasting: string;
  harvestYear: string;
  winemaking: string;
  maturation: string;
  store: string;
  serve: string;
  productRegion: any;
  productOrigin: any;
  productCountry: string;
  productType: string;
  productColour: string;
  productCategory: string;
  alcoholContent: string;
  alcoholVolume: string;
  totalSugars: string;
  acidity: string;
  aciditySpecification: string;
  sugars: string;
  sugarsSpecification: string;
  ph: string;
  bottles: string;
  vegetarian: boolean;
  vegan: boolean;
  sulfites: boolean;
  options: any;
  color: any;
  titleColor: any;
  bgColor: any;
  related: any;
  locale: any;
  portfolioSlug: any;
  generic: any;
  query: any;
  underline: any;
  nutritionalInfoFor100: any;
  innerRelated: any;
  energetic: boolean;
  id: string;
  detailBg: any;
  specificNutritional: any[any];
  awardsConf: any[any];
  awards: any;
  ratings: any;
  btnColor: any;
  btnStyle: any;
  btnType:
    | "borderBottom"
    | "round"
    | "default"
    | "borderFull"
    | "borderFullRound"
    | "bgFill"
    | "bgFillRound"
    | null
    | undefined;
  btnIcon: any;
  winemaker: any;
  highlight: any;
  gallery: any;
  specific: any;
  customData: any;
  enjoy: string;
  dropdown: boolean;
  iconDropdownColor: any;
  bgDropdownColor: any;
  buttonConfig: any;
  relatedConf: any;
}

export function ProductDetail({
  forwardedRef,
  tasting,
  harvestYear,
  winemaking,
  maturation,
  dropdown,
  iconDropdownColor,
  bgDropdownColor,
  store,
  serve,
  specificNutritional,
  productRegion,
  productOrigin,
  awardsConf,
  awards,
  ratings,
  alcoholContent,
  acidity,
  ph,
  bottles,
  vegetarian,
  vegan,
  sulfites,
  color,
  titleColor,
  options,
  bgColor,
  related,
  locale,
  portfolioSlug,
  query,
  underline,
  nutritionalInfoFor100,
  innerRelated,
  energetic,
  totalSugars,
  detailBg,
  id,
  winemaker,
  highlight,
  gallery,
  specific,
  customData,
  enjoy,
  buttonConfig,
  relatedConf,
}: DetailProps) {
  const { t } = useTranslation();

  const style = {
    "--r": detailBg?.color.red,
    "--g": detailBg?.color.green,
    "--b": detailBg?.color.blue,
    "--highlight": highlight?.color?.hex,
    "--rAlt": highlight?.color.red,
    "--gAlt": highlight?.color.green,
    "--bAlt": highlight?.color.blue,
  } as React.CSSProperties;

  const router = useRouter() ?? {};

  const url = router?.query?.slug!?.[router.query.slug!?.length - 1];

  const awardsToShow = awards?.allAwards?.filter(
    (x: any) => url === x.product?.slug
  );

  const ratingsToShow = ratings?.allRatings?.filter(
    (x: any) => url === x.product?.slug
  );

  const awardToShowByYear = awardsToShow?.filter(
    (x: any) => x.contestYear >= awardsConf[3]
  );
  const ratingToShowByYear = ratingsToShow?.filter(
    (x: any) => x.ratingYear >= awardsConf[3]
  );

  //filter awards by year with the category marked in configuration
  const filteredAwardsFinal: any = awardToShowByYear?.filter((award: any) => {
    const category = awardsConf[2]?.map((award: any) => award.title);
    return category?.includes(award.award?.title);
  });

  const ratingsAwardsFinal = ratingToShowByYear?.filter(
    (rating: any, i: number) => {
      if (
        rating.scale == "0-20" &&
        rating.rating?.ratingValue * 1 >= awardsConf[6]?.ratingValue * 1
      ) {
        return rating;
      } else return null;
    }
  );

  const ratingsAwardsFinal100 = ratingToShowByYear?.filter(
    (rating: any, i: number) => {
      if (
        rating.scale == "0-100" &&
        rating.rating?.ratingValue * 1 >= awardsConf[5]?.ratingValue * 1
      ) {
        return rating;
      }
    }
  );

  const FinalRatings = [...ratingsAwardsFinal, ...ratingsAwardsFinal100];

  // transform ratingYear property to year

  const accoladesRatings = FinalRatings?.map(
    ({ ratingYear: year, ...props }: any) => ({
      year,
      props,
    })
  );

  // transform contestYear property to year

  const accoladesAwards = filteredAwardsFinal?.map(
    ({ contestYear: year, ...props }: any) => ({
      year,
      props,
    })
  );

  // merge new arrays

  const accolades = [...accoladesAwards, ...accoladesRatings];

  // order array by year

  const orderedAccolades = accolades.sort((a, b) => b.year - a.year);

  ///render just ratings page if awarads is empty

  console.log(related, locale, relatedConf, portfolioSlug);

  return (
    <div
      style={{ background: bgColor?.color.hex || specific?.color?.hex }}
      className="w-full m-auto relative"
    >
      <div
        ref={forwardedRef}
        className="grid  px-6 lg:px-4 xl:px-2 2xl:px-0 col-span-1 lg:grid-cols-2 container mx-auto   gap-y-4 lg:gap-y-12 pb-20"
      >
        <div className="col-start-1 lg:col-start-2 col-span-1 flex flex-col gap-y-12  lg:pr-6">
          {innerRelated?.length < 1 ? (
            <div className="flex flex-row flex-wrap gap-4 text-xs">
              {related && dropdown ? (
                <>
                 
                </>
              ) : (
                <>
                  <RelatedSlugs
                    data={related[0]}
                    color={color}
                    query={query}
                    locale={locale}
                    config={relatedConf}
                    portfolioSlug={portfolioSlug}
                  />
                </>
              )}
            </div>
          ) : (
            <>
              {innerRelated && dropdown ? (
                <>
                  <RelatedCollectionDropdown
                    data={innerRelated?.[0]}
                    iconColor={iconDropdownColor}
                    bgColor={bgDropdownColor}
                    query={query}
                    locale={locale}
                    id={id}
                    portfolioSlug={portfolioSlug}
                  />
                </>
              ) : (
                <>
                  <RelatedCollectionSlugs
                    data={innerRelated?.[0]}
                    color={color}
                    query={query}
                    locale={locale}
                    id={id}
                    config={relatedConf}
                    portfolioSlug={portfolioSlug}
                  />
                </>
              )}
            </>
          )}

          {customData[0] && (
            <>
              {customData[1]
                .filter((x: any) => x.typeofcontent == "pdetail")
                .map((entry: any, i: number) => {
                  return (
                    <div
                      key={i}
                      className="col-start-1 lg:col-start-2 flex flex-col gap-y-2"
                    >
                      <ProductArticle
                        underline={underline}
                        color={color}
                        title={t("concept")}
                        content={entry?.content.text}
                        highlight={highlight}
                      />
                    </div>
                  );
                })}
            </>
          )}

          {options.showWineMaker && winemaker && (
            <>
              {winemaker.map((people: any, i: number) => (
                <ProductArticle
                  key={i}
                  underline={underline}
                  color={color}
                  titleColor={titleColor}
                  title={`${t("winemaker")} ${people?.title} `}
                  content={people?.excerpt}
                  highlight={highlight}
                />
              ))}
            </>
          )}

          {options.showTasting && tasting && (
            <ProductArticle
              underline={underline}
              color={color}
              titleColor={titleColor}
              title={t("tasting")}
              content={tasting}
              highlight={highlight}
            />
          )}
          {options.showHarvestYear && harvestYear && (
            <ProductArticle
              color={color}
              titleColor={titleColor}
              underline={underline}
              title={t("harvestYear")}
              content={harvestYear}
              highlight={highlight}
            />
          )}

          {options.showMaturation && maturation && (
            <ProductArticle
              underline={underline}
              color={color}
              titleColor={titleColor}
              title={t("maturation")}
              content={maturation}
              highlight={highlight}
            />
          )}
          {options.showStore && store && (
            <ProductArticle
              underline={underline}
              color={color}
              titleColor={titleColor}
              title={t("store")}
              content={store}
              highlight={highlight}
            />
          )}
          {options.showServe && serve && (
            <ProductArticle
              underline={underline}
              color={color}
              titleColor={titleColor}
              title={t("serve")}
              content={serve}
              highlight={highlight}
            />
          )}
          {options.showEnjoy && enjoy && (
            <ProductArticle
              underline={underline}
              color={color}
              titleColor={titleColor}
              title={t("enjoy")}
              content={enjoy}
              highlight={highlight}
            />
          )}
        </div>
        {options.showTechnicalDetails && (
          <div className="col-start-1 lg:col-start-2  flex flex-col mt-4 lg:gap-y-6  technicalDetails">
            <div className="flex flex-col w-fit gap-2 lg:gap-4">
              <MultiPurposeTitle
                tag="p"
                type="headline"
                data={t("technicalDetails")}
                color={titleColor ?? color}
              />
              {underline?.length > 0 && (
                <>
                  {underline[1]?.img ? (
                    <CustomUnderline
                      shape={underline[1]?.img}
                      color={highlight || underline[2]?.color}
                    />
                  ) : (
                    <Underline color={underline[2]?.color} />
                  )}
                </>
              )}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 mt-4 lg:mt-0">
              {productOrigin && (
                <div
                  style={style as React.CSSProperties}
                  className={` ${
                    highlight ? `${styles.bgAlt}` : `${styles.bg}`
                  }   flex flex-col `}
                >
                  <MultiPurposeParagraph
                    type="default"
                    weight={"bold"}
                    data={t("originDesignation")}
                    color={color}
                  />
                  <MultiPurposeParagraph
                    type="default"
                    data={productOrigin?.originDesignation}
                    color={color}
                  />
                </div>
              )}
              {productRegion && (
                <div
                  style={style as React.CSSProperties}
                  className={` ${
                    highlight ? `${styles.bgAlt}` : `${styles.bg}`
                  }   flex flex-col `}
                >
                  <MultiPurposeParagraph
                    type="default"
                    weight={"bold"}
                    data={t("region")}
                    color={color}
                  />
                  <MultiPurposeParagraph
                    type="default"
                    data={productRegion?.name}
                    color={color}
                  />
                </div>
              )}
              {alcoholContent.length > 3 && (
                <div
                  style={style as React.CSSProperties}
                  className={` ${
                    highlight ? `${styles.bgAlt}` : `${styles.bg}`
                  }   flex flex-col `}
                >
                  <MultiPurposeParagraph
                    type="default"
                    weight={"bold"}
                    data={t("alcohol")}
                    color={color}
                  />
                  <MultiPurposeParagraph
                    type="default"
                    data={alcoholContent}
                    color={color}
                  />
                </div>
              )}
              {totalSugars.length > 3 && (
                <div
                  style={style as React.CSSProperties}
                  className={` ${
                    highlight ? `${styles.bgAlt}` : `${styles.bg}`
                  }   flex flex-col `}
                >
                  <MultiPurposeParagraph
                    type="default"
                    weight={"bold"}
                    data={t("totalSugars")}
                    color={color}
                  />
                  <MultiPurposeParagraph
                    type="default"
                    data={totalSugars}
                    color={color}
                  />
                </div>
              )}
              {acidity.length > 3 && (
                <div
                  style={style as React.CSSProperties}
                  className={` ${
                    highlight ? `${styles.bgAlt}` : `${styles.bg}`
                  }   flex flex-col `}
                >
                  <MultiPurposeParagraph
                    type="default"
                    weight={"bold"}
                    data={t("totalAcidity")}
                    color={color}
                  />
                  <MultiPurposeParagraph
                    type="default"
                    data={`${acidity} <span className="text-xs">${t(
                      "acidT"
                    )}</span>`}
                    color={color}
                  />
                </div>
              )}
              {ph && (
                <div
                  style={style as React.CSSProperties}
                  className={` ${
                    highlight ? `${styles.bgAlt}` : `${styles.bg}`
                  }   flex flex-col `}
                >
                  <MultiPurposeParagraph
                    type="default"
                    weight={"bold"}
                    data={t("ph")}
                    color={color}
                  />
                  <MultiPurposeParagraph
                    type="default"
                    data={ph}
                    color={color}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {options.showNutritionalInformation && (
          <div className="col-start-1 lg:col-start-2  col-span-1 flex flex-col mt-4 lg:gap-y-6  nutricionalInfo">
            <div className="flex flex-col w-fit gap-4">
              <MultiPurposeTitle
                tag="p"
                type="headline"
                data={t("nutritionalInformation")}
                color={titleColor ?? color}
              />
              {underline[0] && (
                <>
                  {underline[1]?.img ? (
                    <CustomUnderline
                      shape={underline[1]?.img}
                      color={highlight?.color || underline[2]?.color}
                    />
                  ) : (
                    <Underline
                      color={highlight?.color || underline[2]?.color}
                    />
                  )}
                </>
              )}
            </div>
            <ProductTable
              energetic={energetic}
              type="nutritional"
              underline={underline}
              content={[vegetarian, vegan, sulfites]}
              nutritionalInfoFor100={nutritionalInfoFor100}
              specificNutritional={specificNutritional}
              color={color}
              title={""}
              highlight={highlight}
            />
          </div>
        )}
        {options.showBottlesProduced && (
          <div className="col-start-1 lg:col-start-2 flex flex-col gap-y-2 pb-12 bottles">
            <div className="flex flex-col w-fit gap-4">
              <ProductArticle
                color={color}
                titleColor={titleColor}
                title={t("bottlesProduced")}
                content={``}
                highlight={highlight}
              />
              {underline[0] && (
                <>
                  {underline[1]?.img ? (
                    <CustomUnderline
                      shape={underline[1]?.img}
                      color={highlight?.color || underline[2]?.color}
                    />
                  ) : (
                    <Underline
                      color={highlight?.color || underline[2]?.color}
                    />
                  )}
                </>
              )}
            </div>
            <p
              style={{ color: color?.hex }}
              className="py-0 brandParagraph text-sm lg:text-base"
            >
              {bottles}
            </p>
          </div>
        )}

        {customData[0] && (
          <>
            {customData[1]
              .filter((x: any) => x.typeofcontent == "mbanner")
              .map((entry: any, i: number) => {
                return (
                  <div
                    key={i}
                    className="col-start-1 lg:col-start-2 flex flex-col gap-y-2 "
                  >
                    <MarketingBanner
                      title={entry.content.title}
                      description={entry.content.description}
                      image={entry.content.image}
                      label={[
                        entry.content.addLabel,
                        entry.content.swapLabelForImage,
                        entry.content.imageReplacer,
                      ]}
                      textColor={entry.content.textColor}
                    />
                  </div>
                );
              })}
          </>
        )}
        {awardsConf[1] | awardsConf[0] ? (
          <>
            {" "}
            {orderedAccolades.length > 0 && (
              <div className="col-start-1 lg:col-start-2 flex flex-col pb-12  gap-y-10">
                <div className="flex flex-col w-fit gap-4">
                  <ProductArticle
                    titleColor={titleColor}
                    color={color}
                    title={t("accolades")}
                    content={``}
                  />
                  {underline[0] && (
                    <>
                      {underline[1]?.img ? (
                        <CustomUnderline
                          shape={underline[1]?.img}
                          color={highlight?.color || underline[2]?.color}
                        />
                      ) : (
                        <Underline
                          color={highlight?.color || underline[2]?.color}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="flex flex-row flex-nowrap md:grid grid-cols-3 gap-4 pt-12 overflow-auto">
                  {orderedAccolades.slice(0, 3).map((entry: any, i: number) => {
                    return (
                      <>
                        <div
                          className={`${styles.accoladeImg}  flex flex-none flex-col  align-center justify-start text-center col-span-1`}
                          key={i}
                        >
                          <div className="flex flex-col gap-2">
                            <Image
                              className="m-auto max-w-[8rem]"
                              data={
                                entry.props?.award?.awardImage ||
                                entry.props?.rating?.ratingImage
                              }
                            />
                          </div>
                          <div className="max-w-[10rem]  m-auto">
                            <MultiPurposeParagraph
                              type="default"
                              data={entry?.year}
                              color={color}
                            />
                            <MultiPurposeParagraph
                              type="default"
                              data={
                                entry.props?.wineContest?.title ||
                                entry.props?.mediaPublication?.title ||
                                entry.props?.taster?.[0]?.title
                              }
                              color={color}
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="col-start-1 lg:col-start-2 flex flex-col pb-12  gap-y-10">
                  <ButtonMaster
                    anim={"slideLeft"}
                    type={buttonConfig?.buttonStyle}
                    color={buttonConfig?.labelColor?.color}
                    cta={buttonConfig?.typeColor?.color}
                    customIcon={buttonConfig?.icon?.img}
                    customColor={buttonConfig?.iconColor?.color}
                    link={`${routerHandler({
                      typename: "AwardRecord",
                      slug: [awardsConf[7], url],
                      locale: locale,
                    })}`}
                  >
                    {t("seeMore")}
                  </ButtonMaster>
                </div>
              </div>
            )}
          </>
        ) : (
          <></>
        )}

        {gallery[0] && (
          <div className="col-start-1 lg:col-start-2  col-span-1 flex flex-col gap-y-6">
            <div className="flex flex-wrap gap-6">
              {gallery[1].map((entry: any, i: number) => {
                const style = {
                  "--x": `${entry.focalPoint?.x}%`,
                  "--y": `${entry.focalPoint?.y}%`,
                  "--ar": entry.responsiveImage.aspectRatio,
                } as React.CSSProperties;

                return (
                  <div
                    style={style as React.CSSProperties}
                    className={`
											${styles.galleryImageHalf}
											${
                        gallery[1].length > 1
                          ? `w-full md:w-[calc(50%-12px)] ${
                              i % 4 === 0 || i % 4 === 3
                                ? "md:w-[calc(55%-18px)]"
                                : "md:w-[calc(45%-18px)]"
                            } lg:h-[15rem] xl:h-[20rem]`
                          : ""
                      }`}
                    key={i}
                  >
                    <GalleryImage options={""} data={entry} />
                    {!gallery[2] && (
                      <MultiPurposeParagraph
                        data={entry?.responsiveImage?.title}
                        color={color}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

interface ProductsListProps {
  awards: any;
  productsDefinition: any[any];
  bgColor: any;
  text: any;
  style: any;
  btn: any;
  btnStyle: any;
  showTypeGeneric: boolean;
  portfolioSlug: string;
  btnLabel: string;
  btnType:
    | "borderBottom"
    | "round"
    | "default"
    | "borderFull"
    | "borderFullRound"
    | "bgFill"
    | "bgFillRound"
    | null
    | undefined;
  btnIcon: any;
  parallax: any;
  type: "vertical" | "default" | "bg" | undefined;
  layout: string;
  spacer?: any;
  bgImage: any;
  order: boolean;
  column: string;
  reduce: boolean;
  underline: any;
  featuredSize: string;
  typeOfNavigation: boolean;
  description: boolean;
  invertProductImageOrder: boolean;
  buttonConfig: any;
  brand: boolean;
}
export function ProductsDefinitionList({
  awards,
  productsDefinition,
  layout,
  bgColor,
  text,
  showTypeGeneric,
  portfolioSlug,
  parallax,
  type,
  spacer,
  bgImage,
  order,
  column,
  reduce,
  underline,
  featuredSize,
  typeOfNavigation,
  description,
  invertProductImageOrder,
  buttonConfig,
  brand,
}: ProductsListProps) {
  const { locale } = useRouter();

  const bg = {
    "--bg": `url(${bgImage[1]?.url})`,
    "--bgColor": bgColor?.color?.hex,
  } as React.CSSProperties;

  console.log(productsDefinition);

  return (
    <div
      style={bg as React.CSSProperties}
      className={`w-full ${styles.bgColor} `}
    >
      {layout == "zig" ? (
        <div
          style={bg as React.CSSProperties}
          className={`w-full m-auto  ${
            bgImage[2] ? `` : `${styles.bgImage}`
          }   ${
            reduce ? "pt-6 lg:pt-[8rem] " : "pt-[10rem] ˙md:pt-[12rem] pb-32"
          } flex flex-col ${
            type == "bg"
              ? "gap-12 lg:gap-[5rem]  lg:pb-20"
              : `${
                  reduce ? `lg:gap-20` : `gap-12 md:gap-[10rem] lg:gap-[14rem]`
                } px-6 xl:px-0 `
          }`}
        >
          <ZigZagList
            buttonConfig={buttonConfig}
            awards={awards}
            productsDefinition={productsDefinition}
            portfolioSlug={portfolioSlug}
            locale={locale}
            parallax={parallax}
            bgImage={bgImage}
            productImageOrder={invertProductImageOrder}
            order={order}
            text={text}
            column={column}
            reduce={reduce}
            showTypeGeneric={showTypeGeneric}
            underline={underline}
            featuredSize={featuredSize}
            type={type}
            typeOfNavigation={typeOfNavigation}
            description={description}
          />
        </div>
      ) : layout == "grid" ? (
        <div
          className="w-full m-auto relative"
          style={{ background: bgColor?.color.hex }}
        >
          <GridList
            buttonConfig={buttonConfig}
            spacer={spacer}
            bgColor={bgColor}
            productsDefinition={productsDefinition}
            portfolioSlug={portfolioSlug}
            locale={locale}
            text={text}
            type={type}
            brand={brand}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
