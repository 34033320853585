import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import styles from "./index.module.scss";

interface Props {
  type: string;
  color: any;
  bg?: any;
  layout?: string;
}

export function OverlayBackground({
  type,
  color,
  layout,
  bg,
}: Props): JSX.Element {
  const style = {
    "--color": color?.hex,
  } as React.CSSProperties;
  return (
    <span
      style={style as React.CSSProperties}
      className={` ${
        type == "solid" ? `${styles.solid}` : `${styles.gradient}`
      }  absolute w-full h-[50%] lg:h-[40%] bottom flex flex-row justify-end items-center top-0`}
    >
      {layout == "fullRow" && (
        <div className=" 2xl:max-w-[40rem] w-full relative h-[80%]">
          {" "}
          <FullBackgroundImage position="Contain" data={bg} />{" "}
        </div>
      )}
    </span>
  );
}
