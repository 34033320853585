import { MultiPurposeTitle, Title } from "@/components/Atoms/Title";
import Image from "@/components/Atoms/Image";
import { MultiPurposeParagraph, Paragraph } from "@/components/Atoms/Paragraph";

interface TimelineVerticalProps {
  title: string;
  titleColor: any;
  yearColor: any;
  titleHistoryColor: any;
  textColor: any;
  historyMoments: any[];
}

export const TimelineVertical = ({
  historyMoments,
  textColor,
  title,
  titleColor,
  titleHistoryColor,
  yearColor,
}: TimelineVerticalProps) => {
  return (
    <div className="flex flex-col items-center gap-4">
      <MultiPurposeTitle
        tag="h3"
        type="default"
        data={title}
        color={titleColor || textColor}
      />
      <div className="flex flex-row md:flex-col md:items-center overflow-x-auto">
        {historyMoments.map((history: any, index: number) => (
          <div
            className={`flex flex-col container items-center gap-8 ${
              index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
            } flex-shrink-0 w-full`}
            key={index}
          >
            <div
              className={`flex flex-col w-full md:max-w-[50%] min-h-[320px] gap-2 items-center md:items-start justify-start`}
            >
              <MultiPurposeParagraph
                type={"default"}
                color={titleColor || textColor}
                data={
                  history.addRangeOfYears ? history.rangeYear : history.year
                }
              />
              {history.title && (
                <MultiPurposeParagraph
                  type={"label"}
                  color={textColor}
                  data={history.title}
                />
              )}
              {history.description && (
                <MultiPurposeParagraph
                  type={"default"}
                  color={textColor}
                  data={history.description}
                />
              )}
            </div>

            {/* DIVIDER */}
            <div className="flex relative w-full md:w-auto md:h-full">
              <div
                className="relative w-full h-0.5 md:w-0.5 md:h-[350px] lg:h-[500px] xl:h-[600px] flex items-center justify-center"
                style={{ backgroundColor: yearColor.hex }}
              >
                <div
                  className="h-5 w-5 rounded-full absolute -translate-x-1/2 md:translate-x-0 md:-translate-y-1/2"
                  style={{ backgroundColor: yearColor.hex }}
                />
              </div>
            </div>

            {/* IMAGE */}
            <div className="flex w-full items-center justify-center">
              <Image data={history.image} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
