import { MultiPurposeParagraph } from "@/components/Atoms/Paragraph";
import { useTranslation } from "next-i18next";
import { Title } from "@/components/Atoms/Title";
import VideoPlayerBlock from "@/components/Atoms/Video";

interface Props {
  data: any;
  config: any;
  layout?: string;
}

export default function DirectionsBlock({ data, config, layout }: Props) {
  const { t } = useTranslation();

  return (
    <div className=" flex flex-col gap-4 pb-12">
      <Title
        tag={"h3"}
        data={t("directions")}
        color={config?.headlineTextColor.color}
      />
      {data.recipeInstructions.map((main: any, i: number) => (
        <div key={i}>
          <ol className="flex flex-col gap-4">
            {" "}
            <MultiPurposeParagraph
              data={`${main.headline}`}
              color={config?.highlightTextColor.color}
            />
            {main.step.map((step: any, index: number) => (
              <li key={index} className="">
                {" "}
                <p
                  style={{ color: config?.headlineTextColor.color.hex }}
                  className="text-sm lg:text-base  brandParagraph  flex flex-col gap-2 font-bold"
                >
                  {index + 1}. {step.name}
                </p>
                <MultiPurposeParagraph
                  data={step.text}
                  color={config?.headlineTextColor.color}
                />
              </li>
            ))}
          </ol>
        </div>
      ))}
      {data.addPrepVideo && (
        <VideoPlayerBlock
          videoUrl={data.prepVideo.video.url}
          loop={data.prepVideo.loop}
          autoplay={data.prepVideo.autoplay}
          textColor={"#fff"}
        />
      )}
      {data.tip && (
        <div
          style={{ backgroundColor: config?.cardBackgroundColor?.color.hex }}
          className={`${
            layout == "cocktail" ? "rounded-xl overflow-hidden" : ""
          } p-4 text-center mt-4`}
        >
          <MultiPurposeParagraph
            data={t("tips")}
            color={config?.highlightTextColor.color}
          />
          <MultiPurposeParagraph
            data={data.tip}
            color={config?.headlineTextColor.color}
          />
        </div>
      )}
    </div>
  );
}
