import Image from "@/components/Atoms/Image";
import { useState } from "react";
import { TagLabel } from "@/components/Atoms/Label";
import styles from "./index.module.scss";
import RecipeCard from "../RecipeCard";
import { motion } from "framer-motion";
import { ButtonMaster } from "@/components/Atoms/Buttons";
import { useRouter } from "next/router";

interface RecipeBlockProps {
  data: any;
  layout: "default" | "featured" | "gridRounded" | null | undefined;
  color: any;
  excerpt: any;
  cta: any;
  highlight: any;
  load: any;
  labelColor: any;
}

export default function RecipeBlock({
  data,
  layout,
  color,
  excerpt,
  cta,
  highlight,
  load,
  labelColor,
}: RecipeBlockProps) {
  // Initial state for displayed posts count
  const [visiblePostsCount, setVisiblePostsCount] = useState(4);

  // Function to load more posts
  const loadMorePosts = () => {
    setVisiblePostsCount((prevCount) => prevCount + 4);
  };

  const router: any = useRouter();

  const noSlugRecipes = data.allRecipes?.filter(
    (recipes: any) => recipes.slug !== router.query?.slug?.[0]
  );

  switch (layout) {
    case "default":
      return (
        <div className="container mx-auto grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
          {noSlugRecipes
            .slice(0, visiblePostsCount)
            .map((post: any, i: number) => {
              const style = {
                "--x": `${post.featuredImage.focalPoint?.x}%`,
                "--y": `${post.featuredImage.focalPoint?.y}%`,
              } as React.CSSProperties;
              return (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                  className={"col-span-1 flex flex-col bg-white"}
                  key={i}
                >
                  <div
                    className={`${styles.imageContainerGrid}  h-[200px] lg:h-[300px] col-span-1 relative `}
                  >
                    <TagLabel
                      highlight={highlight}
                      label={post.layoutType}
                      color={color}
                    />
                    <div style={style} className={styles.imageContainer}>
                      <Image className={``} data={post.featuredImage} />
                    </div>
                  </div>
                  <div className=" mx-auto w-full relative">
                    <RecipeCard
                      layout={layout}
                      post={post}
                      cta={cta[0]}
                      color={color}
                      excerpt={excerpt}
                    />
                  </div>
                </motion.div>
              );
            })}
          {visiblePostsCount < data.allRecipes.length && (
            <div className="w-full mx-auto flex justify-center py-4   md:col-span-2 lg:col-span-4">
              <ButtonMaster
                onClick={loadMorePosts}
                type={load[0].buttonStyle}
                cta={load[0].styleColor?.color}
                color={load[0].labelColor?.color}
                customIcon={load[0].icon?.img}
                customColor={load[0].iconColor?.color}
                link={``}
              >
                {load[0]?.buttonLabel?.label}
              </ButtonMaster>
            </div>
          )}
        </div>
      );
    case "featured":
      return (
        <div className="container mx-auto grid grid-cols-2 gap-6 mt-6">
          {noSlugRecipes
            .slice(0, visiblePostsCount)
            .map((post: any, i: number) => {
              const style = {
                "--x": `${post.featuredImage.focalPoint?.x}%`,
                "--y": `${post.featuredImage.focalPoint?.y}%`,
              } as React.CSSProperties;
              if (i == 0) {
                return (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                    className={"grid col-span-2 grid-cols-5"}
                    key={i}
                  >
                    <div className="col-span-3 relative">
                      <TagLabel
                        highlight={highlight}
                        label={post.layoutType}
                        color={color}
                      />

                      <div style={style} className={styles.imageContainer}>
                        <Image
                          className={`top-0 w-full h-full object-center md:object-bottom lg:min-h-[25rem] object-cover`}
                          data={post.featuredImage}
                        />
                      </div>
                      <RecipeCard
                        layout={layout}
                        post={post}
                        cta={cta[0]}
                        color={color}
                        excerpt={excerpt}
                      />
                    </div>
                  </motion.div>
                );
              } else {
                return (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                    className={"grid grid-cols-2"}
                    key={i}
                  >
                    <div className="col-span-2 relative">
                      <TagLabel
                        highlight={highlight}
                        label={"Recipe"}
                        color={color}
                      />
                      <div style={style} className={styles.imageContainer}>
                        <Image
                          className={`top-0 w-full h-full object-center md:object-bottom lg:min-h-[25rem] object-cover`}
                          data={post.featuredImage}
                        />
                      </div>
                      <RecipeCard
                        post={post}
                        cta={cta[0]}
                        color={color}
                        excerpt={excerpt}
                      />
                    </div>
                  </motion.div>
                );
              }
            })}
          {visiblePostsCount < data.allRecipes.length && (
            <div className="w-full mx-auto flex justify-center py-4  md:col-span-2 lg:col-span-4">
              <ButtonMaster
                onClick={loadMorePosts}
                type={load[0].buttonStyle}
                cta={load[0].styleColor?.color}
                color={load[0].labelColor?.color}
                customIcon={load[0].icon?.img}
                customColor={load[0].iconColor?.color}
                link={``}
              >
                {load[0]?.buttonLabel?.label}
              </ButtonMaster>
            </div>
          )}
        </div>
      );
    default:
      return (
        <div className="container mx-auto grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6 ">
          {noSlugRecipes
            .slice(0, visiblePostsCount)
            .map((post: any, i: number) => {
              const style = {
                "--x": `${post.featuredImage.focalPoint?.x}%`,
                "--y": `${post.featuredImage.focalPoint?.y}%`,
              } as React.CSSProperties;
              return (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                  className={
                    "col-span-1 flex flex-col rounded-3xl overflow-hidden bg-white"
                  }
                  key={i}
                >
                  <div
                    className={`${styles.imageContainerGrid}  h-[200px] col-span-1 relative`}
                  >
                    <TagLabel
                      layout={layout}
                      highlight={highlight}
                      label={post.layoutType}
                      color={labelColor}
                    />
                    <div style={style} className={styles.imageContainer}>
                      <Image
                        className={`rounded-3xl `}
                        data={post.featuredImage}
                      />
                    </div>
                  </div>
                  <div className=" mx-auto w-full relative">
                    <RecipeCard
                      layout={layout}
                      post={post}
                      cta={cta[0]}
                      color={color}
                      excerpt={excerpt}
                      labelColor={labelColor}
                    />
                  </div>
                </motion.div>
              );
            })}
          {visiblePostsCount < data.allRecipes.length && (
            <div className="w-full mx-auto flex justify-center py-4   md:col-span-2 lg:col-span-4">
              <ButtonMaster
                onClick={loadMorePosts}
                type={load[0].buttonStyle}
                cta={load[0].styleColor?.color}
                color={load[0].labelColor?.color}
                customIcon={load[0].icon?.img}
                customColor={load[0].iconColor?.color}
                link={``}
              >
                {load[0]?.buttonLabel?.label}
              </ButtonMaster>
            </div>
          )}
        </div>
      );
  }
}
