import { MultiTextComponent } from "@/components/Atoms/MultiText";
import { MultiPurposeParagraph } from "@/components/Atoms/Paragraph";
import { GalleryImage } from "@/components/Molecules/ImageCard";
import {
	formatDate,
	formatDateMonthYear,
	pageRelatedLink,
} from "@/lib/functions";
import { useTranslation } from "next-i18next";
import { ButtonMaster } from "@/components/Atoms/Buttons";
import { routerHandler } from "@/lib/routes/router-links-handler";
import { MultiPurposeTitle } from "@/components/Atoms/Title";

export default function ArticleContent({
	data,
	config,
	date,
	author,
	hideDate,
	addReturnButton,
	returnCta,
	dateType,
	locale,
}: any) {
	const formatDateToShow =
		dateType == "detailed"
			? formatDateMonthYear(date, locale)
			: formatDate(date);

	const { t } = useTranslation();
	return (
		<div>
			{addReturnButton && returnCta && (
				<div className="flex px-6 lg:px-16 pt-8">
					<ButtonMaster
						anim={"slideLeft"}
						invertOrder
						type={returnCta.buttonStyle}
						cta={returnCta.typeColor?.color}
						color={returnCta.labelColor?.color}
						customIcon={returnCta.icon?.img}
						customColor={returnCta.iconColor?.color}
						link={` ${
							returnCta.page
								? ` ${routerHandler({
										typename: returnCta.__typename,
										slug: pageRelatedLink(
											returnCta.page,
											locale,
											returnCta.sectionAnchor?.title
										),
										locale: locale,
								  })}`
								: `${returnCta.externalUrl}`
						}`}
					>
						{returnCta?.buttonLabel?.label}
					</ButtonMaster>
				</div>
			)}
			<div className="container mx-auto flex flex-col gap-10  max-w-[950px] px-6 lg:px-4 xl:px-2 2xl:px-0 py-6 lg:py-12 ">
				<div className="flex flex-col gap-4">
					{!hideDate && (
						<div>
							<MultiPurposeParagraph
								type={"excerpt"}
								data={formatDateToShow}
								color={config?.highlightTextColor?.color}
							/>
							{author && (
								<MultiPurposeParagraph
									weight={"bold"}
									data={`${t("by")}: ${author.title}`}
									color={config?.articleTextColor?.color}
								/>
							)}
						</div>
					)}
					{/* CONTENT */}
					{data.map((block: any, i: number) => {
						if (block.__typename == "TextRecord") {
							return (
								<MultiTextComponent
									key={i}
									data={block.text}
									color={config?.articleTextColor?.color}
								/>
							);
						} else if (block.__typename == "ImageRecord") {
							return <GalleryImage key={i} data={block?.image} />;
						} else if (block.__typename == "PageRelatedRecord") {
							return (
								<div className="flex w-full justify-center">
									<ButtonMaster
										anim={"slideLeft"}
										type={block.buttonStyle}
										cta={block.typeColor?.color}
										color={block.labelColor?.color}
										customIcon={block.icon?.img}
										customColor={block.iconColor?.color}
										link={` ${
											block.page
												? ` ${routerHandler({
														typename: block.__typename,
														slug: pageRelatedLink(
															block.page,
															locale,
															block.sectionAnchor?.title
														),
														locale: locale,
												  })}`
												: `${block.externalUrl}`
										}`}
									>
										{block?.buttonLabel?.label}
									</ButtonMaster>
								</div>
							);
						} else if (block.__typename == "TextWithImageRecord") {
							return (
								<div
									key={i}
									className={`flex gap-10 lg:gap-20 py-10 lg:py-20 ${
										block.textAlignment == "left"
											? "flex-col lg:flex-row"
											: "flex-col lg:flex-row-reverse"
									}`}
								>
									<div className="flex flex-col gap-4 w-full">
										<MultiPurposeTitle
											tag="h3"
											type="headline"
											data={block.title}
											color={config?.articleTextColor?.color}
										/>
										<MultiPurposeTitle
											tag="p"
											type="featured"
											data={block.subtitle}
											color={config?.articleTextColor?.color}
										/>
										<MultiPurposeParagraph
											type={"default"}
											data={block.text}
											color={config?.articleTextColor?.color}
										/>
									</div>

									<div className="flex justify-center w-full">
										<GalleryImage data={block?.image} />
									</div>
								</div>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
}
