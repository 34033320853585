import { pageHandler } from "@/lib/pages-handler";
import { useLayoutEffect, useRef, useState } from "react";
import {
  getAwardsById,
  getReferencingPortfolioByProductId,
} from "@/dato-api/awards";
import { getRatingsById } from "@/dato-api/ratings";
import dynamic from "next/dynamic";
import { getPortfolioSlug } from "@/lib/functions";
import { useRouter } from "next/router";
import { AwardsDetail } from "@/organisms/Awards";
import { getProductIdBySlug } from "@/dato-api/product";

const HeroAwardProduct = dynamic(() =>
  import("@/components/Organisms/Hero/HeroProduct").then(
    (c) => c.HeroAwardProduct
  )
);

export default function BrandsPortfolioPage({ data, relations }) {
  const { locale } = useRouter();
  const { awards, ratings } = data;
  const { query } = useRouter();
  const detailRef = useRef(null);
  const [detailSize, setDetailSize] = useState();

  useLayoutEffect(() => {
    setDetailSize(detailRef?.current?.clientHeight);
  }, []);

  const portfolioConf = data?.portfolioConf || [];

  const awardsConf = awards?.allAwards[0] || ratings.allRatings[0];

  const portfolioSlug = getPortfolioSlug(portfolioConf, locale);

  if (query.slug?.length > 1) {
    //Get main product list related //
    var related = portfolioConf?.productsList?.[0]?.productsDefinition?.filter(
      (x) => x.product.id == awardsConf.product.id
    );

    // get Inner product list related parent product //
    var innerRelated =
      portfolioConf?.productsList?.[0]?.productsDefinition?.filter((x) =>
        x.relatedProducts.some((y) => y.id == awardsConf.product.id)
      );
  }

  return (
    <>
      <HeroAwardProduct
        buttonConfig={portfolioConf?.productDetailButtonConfig}
        color={portfolioConf?.heroTextColor?.color}
        btnStyle={portfolioConf?.productDetailButtonStyleColor?.color}
        btnColor={portfolioConf?.productDetailButtonTextColor?.color}
        icon={[
          portfolioConf.buyButtonIcon,
          portfolioConf.technicalSheetButtonIcon,
          portfolioConf.veganCertificateButtonIcon,
        ]}
        title={awardsConf?.product?.productName}
        description={awardsConf?.product?.productDescription}
        showImage={portfolioConf?.showImage}
        bg={awardsConf?.product?.productBrand.image}
        definitionBg={related}
        innerRelated={innerRelated}
        image={
          awardsConf?.product?.factSheetImage ||
          awardsConf?.product?.sku[0]?.skuImageGallery[0]
        }
        follow={portfolioConf?.follow}
        detailSize={detailSize}
        bgColor={portfolioConf?.detailBgColor}
        align={portfolioConf?.mobileProductAlignment}
        mobileColor={portfolioConf?.mobileProductDetailColor}
        overlay={[
          portfolioConf?.addShadowOverlay,
          portfolioConf?.overlayShadowColor,
        ]}
        underline={[
          portfolioConf?.addTitleUnderline,
          portfolioConf?.customUnderlineAsset,
          portfolioConf?.underlineColor,
        ]}
        cta={[
          portfolioSlug,
          portfolioConf?.productsList[0]?.productsDefinition[0]?.product
            .__typename ||
            portfolioConf?.productsCollection[0]?.productsCollection[0]
              .__typename,

          portfolioConf?.productsList[0]?.productsDefinition[0]?.product
            .productTypeSpecific.slug ||
            portfolioConf?.productsCollection[0]?.productsCollection[0]
              ?.productTypeSpecific.slug,
          awardsConf?.product.slug,
          locale,
        ]}
      />
      <AwardsDetail
        innerRelated={innerRelated}
        forwardedRef={detailRef}
        awards={awards.allAwards}
        ratings={ratings.allRatings}
        bgColor={portfolioConf?.detailBgColor}
        blockBgColor={portfolioConf?.nutritionalTableBackground}
        color={portfolioConf?.productDetailTextColor}
        configuration={[
          portfolioConf?.accoladesShowAwards,
          portfolioConf?.accoladesShowRatings,
          portfolioConf?.accoladesAwardsType,
          portfolioConf?.awardsYear,
          portfolioConf?.accoladesRatingsYear,
          portfolioConf?.accoladesRatings100,
          portfolioConf?.accoladesRatings20,
          portfolioConf?.accoladesSlug,
          portfolioConf?.showMediaPublicationLogo,
        ]}
      />
    </>
  );
}

export async function _getStaticProps(
  context,
  pageType,
  serverSideTranslations,
  siteConfig
) {
  const preview = context.preview !== undefined ? context.preview : false;
  const currentSlug = context.params.slug.at(-1);
  const slugType = context.params.slug.at(-2);

  const { product } = await getProductIdBySlug(
    context.locale,
    preview,
    currentSlug
  );

  product?.product?._allSlugLocales.map((productSlug) => {
    product?.product?.productTypeSpecific._allSlugLocales.map(
      (typeSpecificSlug) => {
        if (typeSpecificSlug.locale === productSlug.locale) {
          const portfolio = portfolioSlugs?.[0]?._allSlugLocales.filter(
            (p) => p.locale === typeSpecificSlug.locale
          )?.[0];
          productSlug.value = `${portfolio.value}/${typeSpecificSlug.value}/${productSlug.value}`;
        }
      }
    );
  });

  const awards = await getAwardsById(context.locale, preview, product?.id);
  const ratings = await getRatingsById(context.locale, preview, product?.id);
  const _allSlugLocales = product?._allSlugLocales || [];
  _allSlugLocales?.map((slug) => (slug.value = `/${slugType}/${slug.value}`));

  // Get portfolio configuration
  const { productPortfolio } = await getReferencingPortfolioByProductId(
    context.locale,
    preview,
    product?.id,
    siteConfig.siteConfiguration.id
  );

  const { portfolioConf } = productPortfolio;

  const pageData = await pageHandler(
    context,
    serverSideTranslations,
    [],
    "",
    true
  );

  return {
    props: {
      siteConfig: siteConfig.siteConfiguration,
      ...pageData.sst,
      data: {
        ...pageData.data,
        ...product,
        currentSlug,
        siteConfig,
        awards,
        ratings,
        _allSlugLocales,
        portfolioConf: portfolioConf?.[0],
      },
      preview: pageData.preview,
      pageType,
    },
  };
}
