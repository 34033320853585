"use client";
import { useState, useContext } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Notification from "@/organisms/Notification";
import NotificationContext from "@/lib/functions";
import { ButtonMaster } from "@/components/Atoms/Buttons";
import Image from "@/components/Atoms/Image";

import styles from "./index.module.scss";

import { MultiPurposeTitle} from "@/atoms/Title";
import { MultiPurposeParagraph } from "@/atoms/Paragraph";
import { ContactCard } from "@/components/Molecules/ContactCard";
import { CustomUnderline, Underline } from "@/components/Atoms/Underline";

export default function FormContainer(data: any) {
  const router = useRouter();
  const { t } = useTranslation();
  const [submit, setSubmit] = useState(false);

  const notificationCtx: any = useContext(NotificationContext);

  const activeNotification: any = notificationCtx.Notification;

  const [fieldState, setFieldState] = useState<Record<string, string>>({});
  const [errors, setErrorsState] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const getFieldClass = (value: string) => {
    return value.trim() !== ""
      ? styles.dontChangeLabelPos
      : styles.changeLabelPos;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setSubmit(true);

    let response = await fetch("/api/form-submission", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        id: data.id,
        language: router.locale,
        ...fieldState,
      }),
    });

    if (response.status === 422) {
      let json = await response.json();
      setErrorsState(json);
      setSubmit(false);
    }

    if (response.status === 200) {
      setSubmitted(true);
      setSubmit(false);
      notificationCtx.showNotification({
        title: `${data.successTitle?.label}`,
        message: `${data.successLabel?.label}`,
        status: "success",
      });
    }
    if (response.status === 404) {
      notificationCtx.showNotification({
        title: `${data.errorTitle?.label}`,
        message: `${data.errorLabel?.label}`,
        status: "error",
      });
      setSubmit(false);
    }
  };

  const handleChange = (e: any) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    setFieldState((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const asteriskToLabel = (field: any) => {
    return t(field.label) + (field.required ? "*" : "");
  };

  const style = {
    "--color": data.textColor?.color.hex,
    "--labelcolor": data.labelColor?.color.hex,
    "--bg": data.bgColor?.color.hex,
    "--img": `url(${data.backgroundImage?.url})`,
  } as React.CSSProperties;

  const office = data.section.filter(
    (x: any) => x?.__typename == "OfficeRecord"
  );

  return (
    <div
      style={style as React.CSSProperties}
      className={`${
        data.sidebar || data.showSidebarBrand
          ? "grid grid-cols-1 lg:grid-cols-2"
          : ""
      } w-full pt-4 lg:pt-24 pb-12 relative ${styles.bg}`}
    >
      <form
        className="relative flex flex-row px-6 lg:px-0 max-w-[650px] mx-auto items-center "
        action="/api/form-submission"
        encType="multipart/form-data"
        onSubmit={handleSubmit}
      >
        <div
          className={`form-fields container mx-auto pt-12 lg:p-12 mb-6  lg:mb-24  flex flex-col gap-y-6  ${
            !data.sidebar ? `text-center` : ``
          }`}
        >
          {!activeNotification && (
            <>
              {data.title && (
                <div className="flex flex-col gap-2">
                  {data.showFeaturedTitle ? (
                    <MultiPurposeTitle
                      tag="p"
                      type="featured"
                      data={data.title}
                      color={data.titleColor?.color}
                      layout={data.titleAlign}
                    />
                  ) : (
                    <MultiPurposeTitle
                      tag="h3"
                      type="default"
                      data={data.title}
                      color={data.titleColor?.color}
                      layout={data.titleAlign}
                    />
                  )}
                  {data.addTitleUnderline && (
                    <>
                      {data.customUnderlineAsset?.img ? (
                        <CustomUnderline
                          shape={data.customUnderlineAsset?.img}
                          color={data.underlineColor?.color}
                        />
                      ) : (
                        <Underline color={data.underlineColor?.color} />
                      )}
                    </>
                  )}
                </div>
              )}
              {data.description && (
                <MultiPurposeParagraph
                  type="headline"
                  data={data.description}
                  color={data.textColor?.color}
                  className="brandParagraphGate"
                />
              )}
              {data.field &&
                data.field.map((entry: any, i: number) => {
                  let control = <></>;
                  let formGroupClass = null;
                  if (
                    entry.__typename === "TextInputRecord" ||
                    entry.__typename === "EmailInputRecord"
                  ) {
                    control = (
                      <>
                        <input
                          style={style as React.CSSProperties}
                          className={`${styles.formControl}`}
                          id={entry.id}
                          name={entry.id}
                          // placeholder={t(entry.label)}
                          required={entry.required}
                          onChange={handleChange}
                          type={
                            entry.__typename === "TextInputRecord"
                              ? "text"
                              : "email"
                          }
                        />
                        <label
                          htmlFor={entry.id}
                          style={style as React.CSSProperties}
                          className={`${getFieldClass(
                            fieldState[entry.id] || ""
                          )}`}
                        >
                          {t(entry.label)}
                        </label>
                      </>
                    );
                  } else if (entry.__typename === "TextareaInputRecord") {
                    control = (
                      <>
                        <textarea
                          rows={6}
                          style={style as React.CSSProperties}
                          className={styles.formTextArea}
                          name={entry.id}
                          // placeholder={t(entry.label)}
                          onChange={handleChange}
                        ></textarea>
                        <label
                          htmlFor={entry.id}
                          style={style as React.CSSProperties}
                          className={`${getFieldClass(
                            fieldState[entry.id] || ""
                          )}`}
                        >
                          {t(entry.label)}
                        </label>
                      </>
                    );
                  } else if (entry.__typename === "DropdownInputRecord") {
                    let options = entry.options.split(",");
                    let values = entry.value.split(",");
                    control = (
                      <select
                        style={style as React.CSSProperties}
                        className={styles.formSelect}
                        name={entry.id}
                        onChange={handleChange}
                      >
                        <option value="">{asteriskToLabel(entry)}</option>
                        {options &&
                          options.map((option: any, i: number) => {
                            return (
                              <option key={i} value={values[i]}>
                                {option}
                              </option>
                            );
                          })}
                      </select>
                    );
                  }

                  return (
                    <div key={i} className={styles.formGroup}>
                      {control}
                    </div>
                  );
                })}
              <MultiPurposeParagraph
                align={"left"}
                type={"image"}
                className={`${
                  data.showSidebarBrand ? "text-left" : "text-center"
                }`}
                data={data.disclaimer}
                color={data.textColor?.color}
              />
              {errors.length ? (
                <div>
                  <ul>
                    {errors.map((error: any) =>
                      error.conditions.map((condition: any, i: number) => (
                        <li key={`li-error-${i}`}>{condition.message}</li>
                      ))
                    )}
                  </ul>
                </div>
              ) : (
                <></>
              )}
              <div
                className={`flex ${
                  data.sidebar || data.showSidebarBrand
                    ? "justify-start"
                    : "justify-start"
                } mt-4 lg:pb-12`}
              >
                <ButtonMaster
                  submit={submit}
                  type={data?.submitButtonConfig?.buttonStyle}
                  color={data?.submitButtonConfig?.labelColor?.color}
                  cta={data?.submitButtonConfig?.typeColor?.color}
                  link={undefined}
                  customIcon={data?.submitButtonConfig?.icon?.img}
                  customColor={data?.submitButtonConfig?.iconColor?.color}
                >
                  {!submit ? t(data.submitLabel) : t("sending")}
                </ButtonMaster>
              </div>
            </>
          )}
          {activeNotification && (
            <Notification
              title={activeNotification.title}
              message={activeNotification.message}
              status={activeNotification.status}
              color={data.fontColor}
            />
          )}
        </div>
      </form>
      {data.sidebar && (
        <div
          className={`form-fields container mx-auto pt-12 pb-24  px-6 lg:px-0 flex flex-col gap-y-12 max-w-[650px]`}
        >
          <div className="flex flex-col gap-4">
            <MultiPurposeTitle
              tag="p"
              type="featured"
              data={office?.[0]?.title}
              color={data.textColor?.color}
              layout={data.titleAlign}
            />
            <MultiPurposeParagraph
              data={office?.[0]?.description}
              color={data.textColor?.color}
            />
          </div>
          <ContactCard
            data={office?.[0]}
            color={data.textColor?.color}
            styleColor={data.typeStyleColor?.color}
          />
        </div>
      )}
      {data.showSidebarBrand && (
        <div
          className={`relative mx-auto flex flex-col gap-y-6 px-6 lg:px-0 min-w-[650px] pt-12`}
        >
          {data.showFeaturedTitle && (
            <MultiPurposeTitle
              tag="p"
              type="featured"
              data={data.sidebarTitle}
              color={data.titleColor?.color}
              layout={data.titleAlign}
            />
          )}
          {data.customInformation ? (
            <>
              {data.information.map((entry: any, i: number) => {
                if (entry.__typename === "TextRecord") {
                  return (
                    <MultiPurposeParagraph
                      type={"default"}
                      key={i}
                      data={entry.text}
                      color={data.textColor?.color}
                    />
                  );
                } else if (entry.__typename === "ImageRecord") {
                  return (
                    <div key={i} className="relative flex max-w-md">
                      <Image className="m-0" data={entry.image} />
                    </div>
                  );
                }
              })}
            </>
          ) : (
            <div>
              {/* Phone */}
              <div className="flex gap-11 items-center">
                {data.sectionBrand.addPhoneIcon && (
                  <div
                    className="flex items-center justify-center h-16 w-16"
                    style={{
                      backgroundColor: data.sectionBrand.iconBgColor?.color.hex,
                    }}
                  ></div>
                )}

                <MultiPurposeParagraph
                  color={data.textColor?.color}
                  data={
                    data.sectionBrand.addPhoneLabel &&
                    data.sectionBrand.phoneLabel + data.sectionBrand.phone
                  }
                />
              </div>

              {/* Directions */}
              <div className="flex gap-11 items-center">
                {data.sectionBrand.addAddressIcon && (
                  <div
                    className="flex items-center justify-center h-16 w-16"
                    style={{
                      backgroundColor: data.sectionBrand.iconBgColor?.color.hex,
                    }}
                  ></div>
                )}
                <MultiPurposeParagraph
                  color={data.textColor?.color}
                  data={data.sectionBrand.address}
                />
              </div>

              {/* Email */}
              <div className="flex gap-11 items-center">
                {data.sectionBrand.addEmailIcon && (
                  <div
                    className="flex items-center justify-center h-16 w-16"
                    style={{
                      backgroundColor: data.sectionBrand.iconBgColor?.color.hex,
                    }}
                  >
                    {/*    <Image data={data?.sectionBrand.emailIcon} /> */}
                  </div>
                )}
                <MultiPurposeParagraph
                  color={data.textColor?.color}
                  data={
                    data.sectionBrand.addEmailLabel &&
                    data.sectionBrand.emailLabel + data.sectionBrand.email
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export const fragment = `
    fragment FormFragment on SectionFormRecord{
        __typename
        id
        name
        title
        description
        newsletterForm
        layout
        gdprCompliance
        emailTo
        submitValue
        submitLabel
        disclaimer
        hubspotForm
        hubspotFormGuid
        hubspotPortalId
        entitygroup
        sidebar
        showSidebarBrand
		    sidebarTitle
        addBackgroundImage
        backgroundImage{
          url
          responsiveImage {
            ... responsiveImageFragment
          }
        }
        successTitle{
          label
        }
        successLabel {
          label
        }
        errorTitle{
          label
        }
        errorLabel {
          label
        }
        bgColor {
          color {
            hex
          }
        }
        textColor {
          color {
            hex
          }
        }
          typeStyleColor {
          color {
            hex
          }
        }
 customUnderlineAsset {
        img {
          url
          width
          height
        }
      }
      underlineColor{
        color{
          hex
        }
      }
    addTitleUnderline
    titleAlign
		showFeaturedTitle
		titleColor {
			color {
			  hex
			}
		  }
        submitButtonConfig {
          ...pageRelatedFragment
        }
        labelColor {
          color {
            hex
          }
        }
		customInformation
		information {
		... on ImageRecord {
			__typename
		   image {
          responsiveImage {
         ... responsiveImageFragment
          }
		    }
    }
		... on TextRecord {
			__typename
			id
			title
			text
			}
		}
		sectionBrand {
			... on OfficeRecord {
            __typename
            id
            email
            title
            fax
            address
            geolocation {
              latitude
              longitude
            }
            addAddressIcon
            addEmailIcon
            addFaxIcon
            addGeolocationIcon
            addPhoneIcon
            addressIcon {
              img {
                url
                width
                height
              }
            }
            emailIcon {
              img {
                url
                width
                height
              }
            }
            faxIcon {
              img {
                url
                width
                height
              }
            }
            geolocationIcon {
              img {
                url
                width
                height
              }
            }
            phoneIcon {
              img {
                url
                width
                height
              }
				}
			iconColor {
				  color {
					hex
				  }
				}
			iconBgColor {
				  color {
					hex
				  }
				}
			addEmailLabel
			emailLabel
			addPhoneLabel
			phoneLabel
            description
            phone
          }
		}
        section {
          ... on OfficeRecord {
            __typename
            id
            email
            title
            fax
            address
            geolocation {
              latitude
              longitude
            }
            addAddressIcon
            addEmailIcon
            addFaxIcon
            addGeolocationIcon
            addPhoneIcon
            addressIcon {
              img {
                url
                width
                height
              }
            }
            emailIcon {
              img {
                url
                width
                height
              }
            }
            faxIcon {
              img {
                url
                width
                height
              }
            }
            geolocationIcon {
              img {
                url
                width
                height
              }
            }
            phoneIcon {
              img {
                url
                width
                height
              }
            }
            description
            phone
          }
        }
        field {
          ... on DropdownInputRecord {
            __typename
            id
            value
            options
            required
            multipleSelection
            attributecode
            label
          }
          ... on EmailInputRecord {
            __typename
            id
            placeholder
            label
            required
            attributecode
          }
          ... on TextareaInputRecord {
            __typename
            id
            label
            placeholder
            required
            attributecode
          }
          ... on TextInputRecord {
            __typename
            id
            required
            placeholder
            label
            attributecode
          }
          ... on CheckboxInputRecord {
            __typename
            id
            label
            value
            required
            attributecode
          }
        }
    }
`;
