"use client";
import { Title, MultiPurposeTitle } from "@/atoms/Title";
import { Paragraph, Label, MultiPurposeParagraph } from "@/atoms/Paragraph";
import { Underline, CustomUnderline } from "@/atoms/Underline";
import { Spacer } from "@/components/Atoms/Vectors";
import { ButtonMaster } from "@/components/Atoms/Buttons";
import { useTranslation } from "next-i18next";
import { routerHandler } from "@/lib/routes/router-links-handler";
import { useRouter } from "next/router";
import Image from "@/components/Atoms/Image";

import styles from "./index.module.scss";
import { getPortfolioSlug, pageRelatedLink } from "@/lib/functions";
import { FullBackgroundImage } from "../ImageCard";
import { useState } from "react";
import { StoreLocatorIframe } from "@/components/Atoms/Iframes";
import React from "react";

interface Props {
	title: string;
	color: any[any];
	colorTitle?: any;
	underline: boolean;
	underlineColor: any[any];
	layout?: string;
	description: string;

	pageRelated: any;
	align?: string;
	card?: string;
	cardColor?: any[any];
	column?: string;
	label?: any[any];
	parallax?: string;
	imageLabel?: any;
	portfolioSlug?: string;
	bg?: any;
	customUnderlineAsset?: any;
	removeCTA?: boolean;
}

export const CopyCard = ({
	title,
	color,
	underline,
	underlineColor,
	description,
	card,
	cardColor,
	pageRelated,
	column,
	align,
	label,
	imageLabel,
	bg,
	customUnderlineAsset,
	removeCTA,
}: Props) => {
	const { locale }: any = useRouter();
	const style = {
		"--color": color?.hex,
		"--cta": pageRelated[0]?.typeColor?.color?.hex,
		"--bg": cardColor?.hex,
	} as React.CSSProperties;

	const [click, setClick] = useState(false);

	function loadIframe() {
		setClick(!click);
	}

	switch (card) {
		case "oblique":
			return (
				<>
					<div className="card max-w-[100%] md:max-w-[500px] relative ">
						<Spacer style={card} type="card" color={cardColor} />
						<div
							style={style as React.CSSProperties}
							className={`${styles.cardContainer}  z-10 w-full  p-6 md:p-16 flex flex-col gap-8 relative`}
						>
							<div className="flex flex-col gap-4">
								<MultiPurposeTitle
									type="default"
									tag="h3"
									data={title}
									color={color}
								/>
								{underline && (
									<>
										{customUnderlineAsset?.img ? (
											<CustomUnderline
												shape={customUnderlineAsset?.img}
												color={underlineColor}
											/>
										) : (
											<Underline color={underlineColor} />
										)}
									</>
								)}
							</div>
							<Paragraph
								className="brandParagraph"
								column={column}
								copy={description}
								color={color}
								layout={undefined}
							/>

							{pageRelated?.length > 0 && !removeCTA && (
								<ButtonMaster
									anim={"slideLeft"}
									type={pageRelated[0]?.buttonStyle}
									cta={pageRelated[0]?.typeColor?.color}
									color={pageRelated[0]?.labelColor?.color}
									customIcon={pageRelated[0]?.icon?.img}
									customColor={pageRelated[0]?.iconColor?.color}
									target={`${pageRelated[0]?.page ? `_self` : `_blank`}`}
									link={` ${
										pageRelated[0]?.page
											? ` ${routerHandler({
													typename: pageRelated[0]?.__typename,
													slug: pageRelatedLink(
														pageRelated[0]?.page,
														locale,
														pageRelated[0]?.sectionAnchor?.title
													),
													locale: locale,
											  })}`
											: `${pageRelated[0]?.externalUrl}`
									}`}
								>
									{pageRelated[0]?.buttonLabel?.label}
								</ButtonMaster>
							)}
						</div>
						<span
							style={style as React.CSSProperties}
							className={`${styles.spanOblique}  liners absolute w-full h-full  top-4 left-4 `}
						></span>
					</div>
				</>
			);
			break;
		case "default":
			return (
				<>
					<div className="card max-w-[650px] h-fit  px-6 lg:px-0">
						<div
							style={style as React.CSSProperties}
							className={`  z-10 w-full  flex flex-col gap-8 relative`}
						>
							<div className="gap-2 lg:gap-4 flex flex-col">
								{label[0] && (
									<MultiPurposeParagraph
										type="label"
										data={label[1]}
										color={label[2]}
									/>
								)}
								<MultiPurposeTitle
									type="default"
									tag="h3"
									data={title}
									color={color}
								/>
								{underline && (
									<>
										{customUnderlineAsset?.img ? (
											<CustomUnderline
												shape={customUnderlineAsset?.img}
												color={underlineColor}
											/>
										) : (
											<Underline color={underlineColor} />
										)}
									</>
								)}
								{description && (
									<Paragraph
										className="brandParagraph"
										column={column}
										copy={description}
										color={color}
										layout={undefined}
									/>
								)}
							</div>
							{pageRelated?.length > 0 && !removeCTA && (
								<>
									{" "}
									{pageRelated[0].linksToIframe ? (
										<ButtonMaster
											onClick={() => loadIframe()}
											anim={"slideLeft"}
											type={pageRelated[0]?.buttonStyle}
											cta={pageRelated[0]?.typeColor?.color}
											color={pageRelated[0]?.labelColor?.color}
											customIcon={pageRelated[0]?.icon?.img}
											customColor={pageRelated[0]?.iconColor?.color}
											target={``}
											link={``}
										>
											{pageRelated[0]?.buttonLabel?.label}
										</ButtonMaster>
									) : (
										<>
											<ButtonMaster
												anim={"slideLeft"}
												type={pageRelated[0]?.buttonStyle}
												cta={pageRelated[0]?.typeColor?.color}
												color={pageRelated[0]?.labelColor?.color}
												customIcon={pageRelated[0]?.icon?.img}
												customColor={pageRelated[0]?.iconColor?.color}
												target={`${pageRelated[0]?.page ? `_self` : `_blank`}`}
												link={` ${
													pageRelated[0]?.page
														? ` ${routerHandler({
																typename: pageRelated[0]?.__typename,
																slug: pageRelatedLink(
																	pageRelated[0]?.page,
																	locale,
																	pageRelated[0]?.sectionAnchor?.title
																),
																locale: locale,
														  })}`
														: `${pageRelated[0]?.externalUrl}`
												}`}
											>
												{pageRelated[0]?.buttonLabel?.label}
											</ButtonMaster>
										</>
									)}
								</>
							)}
						</div>
						{click && (
							<div className="absolute w-full left-0 top-0 max-h-[420px] z-[20]">
								{" "}
								{pageRelated[0].linksToIframe && (
									<>
										{pageRelated[0]?.iframeType == "store" && (
											<StoreLocatorIframe />
										)}
									</>
								)}
							</div>
						)}
					</div>
				</>
			);
			break;
		case "bgImage":
			return (
				<>
					<div className="card  relative h-full flex justify-center items-center">
						<FullBackgroundImage data={bg} />
						<div
							style={style as React.CSSProperties}
							className={`  z-10 w-full  flex flex-col gap-8 relative p-6 h-full lg:p-24 justify-center`}
						>
							<div className="gap-2 lg:gap-4 flex flex-col ">
								{label[0] && (
									<MultiPurposeTitle
										tag="p"
										type="default"
										data={label[1]}
										color={label[2]}
									/>
								)}
								<MultiPurposeTitle
									type="default"
									tag="h3"
									data={title}
									color={color}
								/>
								{underline && (
									<>
										{customUnderlineAsset?.img ? (
											<CustomUnderline
												shape={customUnderlineAsset?.img}
												color={underlineColor}
											/>
										) : (
											<Underline color={underlineColor} />
										)}
									</>
								)}
								{description && (
									<Paragraph
										column={column}
										copy={description}
										color={color}
										layout={undefined}
									/>
								)}
							</div>
							{pageRelated?.length > 0 && !removeCTA && (
								<ButtonMaster
									anim={"slideLeft"}
									type={pageRelated[0]?.buttonStyle}
									cta={pageRelated[0]?.typeColor?.color}
									color={pageRelated[0]?.labelColor?.color}
									customIcon={pageRelated[0]?.icon?.img}
									customColor={pageRelated[0]?.iconColor?.color}
									target={`${pageRelated[0]?.page ? `_self` : `_blank`}`}
									link={` ${
										pageRelated[0]?.page
											? ` ${routerHandler({
													typename: pageRelated[0]?.__typename,
													slug: pageRelatedLink(
														pageRelated[0]?.page,
														locale,
														pageRelated[0]?.sectionAnchor?.title
													),
													locale: locale,
											  })}`
											: `${pageRelated[0]?.externalUrl}`
									}`}
								>
									{pageRelated[0]?.buttonLabel?.label}
								</ButtonMaster>
							)}
						</div>
					</div>
				</>
			);
			break;
		case "featured":
			return (
				<>
					<div
						className={`${
							align == "center" || align == "fixedCenter"
								? " lg:max-w-[650px] text-center"
								: align === "left"
								? "lg:max-w-[30rem] "
								: "lg:max-w-[30rem] "
						}    relative flex flex-col `}
					>
						<div
							style={style as React.CSSProperties}
							className={`${
								styles.cardContainer
							}  z-10 w-full  flex flex-col gap-24 relative ${
								align === "center" || "fixedCenter" ? " items-center" : ""
							} `}
						>
							{imageLabel[0] ? (
								<Image data={imageLabel[1]} className="max-w-[15rem] mx-auto" />
							) : (
								<>
									{label[0] && (
										<Label
											className=""
											copy={label[1]}
											color={label[2]}
											layout={undefined}
										/>
									)}
								</>
							)}

							<div className="flex flex-col gap-6">
								<div className="flex flex-col gap-4">
									{title && (
										<MultiPurposeTitle
											tag="h2"
											data={title}
											color={color}
											weight={"default"}
											type="featured"
										/>
									)}

									{underline && (
										<>
											{customUnderlineAsset?.img ? (
												<CustomUnderline
													shape={customUnderlineAsset?.img}
													color={underlineColor}
												/>
											) : (
												<Underline color={underlineColor} />
											)}
										</>
									)}
								</div>
								{description && (
									<Paragraph
										className="brandParagraph"
										card={card}
										column={column}
										layout={align}
										copy={description}
										color={color}
									/>
								)}
							</div>
							{pageRelated?.length > 0 && !removeCTA && (
								<ButtonMaster
									anim={"slideLeft"}
									type={pageRelated[0]?.buttonStyle}
									cta={pageRelated[0]?.typeColor?.color}
									color={pageRelated[0]?.labelColor?.color}
									customIcon={pageRelated[0]?.icon?.img}
									customColor={pageRelated[0]?.iconColor?.color}
									target={`${pageRelated[0]?.page ? `_self` : `_blank`}`}
									link={` ${
										pageRelated[0]?.page
											? ` ${routerHandler({
													typename: pageRelated[0]?.__typename,
													slug: pageRelatedLink(
														pageRelated[0]?.page,
														locale,
														pageRelated[0]?.sectionAnchor?.title
													),
													locale: locale,
											  })}`
											: `${pageRelated[0]?.externalUrl}`
									}`}
								>
									{pageRelated[0]?.buttonLabel?.label}
								</ButtonMaster>
							)}
						</div>
					</div>
				</>
			);
			break;
		case "bgColor":
			return (
				<>
					<div className="card relative h-full flex justify-center items-center">
						<FullBackgroundImage data={bg} />
						<div
							style={style as React.CSSProperties}
							className={` ${styles.cardContainer} z-10 w-full  flex flex-col gap-4 lg:gap-8 relative p-6 h-full lg:p-24`}
						>
							<div className="gap-2 lg:gap-4 flex flex-col">
								{label[0] && (
									<MultiPurposeTitle
										tag="p"
										type="default"
										data={label[1]}
										color={label[2]}
									/>
								)}
								<MultiPurposeTitle
									type="default"
									tag="h3"
									data={title}
									color={color}
								/>
								{underline && (
									<>
										{customUnderlineAsset?.img ? (
											<CustomUnderline
												shape={customUnderlineAsset?.img}
												color={underlineColor}
											/>
										) : (
											<Underline color={underlineColor} />
										)}
									</>
								)}
								{description && (
									<Paragraph
										className="brandParagraph"
										column={column}
										copy={description}
										color={color}
										layout={undefined}
									/>
								)}
							</div>
							{pageRelated?.length > 0 && !removeCTA && (
								<ButtonMaster
									anim={"slideLeft"}
									type={pageRelated[0]?.buttonStyle}
									cta={pageRelated[0]?.typeColor?.color}
									color={pageRelated[0]?.labelColor?.color}
									customIcon={pageRelated[0]?.icon?.img}
									customColor={pageRelated[0]?.iconColor?.color}
									target={`${pageRelated[0]?.page ? `_self` : `_blank`}`}
									link={` ${
										pageRelated[0]?.page
											? ` ${routerHandler({
													typename: pageRelated[0]?.__typename,
													slug: pageRelatedLink(
														pageRelated[0]?.page,
														locale,
														pageRelated[0]?.sectionAnchor?.title
													),
													locale: locale,
											  })}`
											: `${pageRelated[0]?.externalUrl}`
									}`}
								>
									{pageRelated[0]?.buttonLabel?.label}
								</ButtonMaster>
							)}
						</div>
					</div>
				</>
			);
			break;
		case "bgFullColor":
			return (
				<>
					<div
						style={style as React.CSSProperties}
						className="relative h-full w-full flex flex-col justify-center items-start gap-8 p-6 min-h-[22rem] lg:pl-16 z-10"
					>
						<div className="gap-4 flex flex-col ">
							{label[0] && (
								<MultiPurposeTitle
									tag="p"
									type="default"
									data={label[1]}
									color={label[2]}
								/>
							)}
							<MultiPurposeTitle
								type="default"
								tag="h3"
								data={title}
								color={color}
							/>
							{underline && (
								<>
									{customUnderlineAsset?.img ? (
										<CustomUnderline
											shape={customUnderlineAsset?.img}
											color={underlineColor}
										/>
									) : (
										<Underline color={underlineColor} />
									)}
								</>
							)}
							{description && (
								<Paragraph
									className="brandParagraph"
									column={column}
									copy={description}
									color={color}
									layout={undefined}
								/>
							)}
						</div>
						{pageRelated?.length > 0 && !removeCTA && (
							<ButtonMaster
								anim={"slideLeft"}
								type={pageRelated[0]?.buttonStyle}
								cta={pageRelated[0]?.typeColor?.color}
								color={pageRelated[0]?.labelColor?.color}
								customIcon={pageRelated[0]?.icon?.img}
								customColor={pageRelated[0]?.iconColor?.color}
								target={`${pageRelated[0]?.page ? `_self` : `_blank`}`}
								link={` ${
									pageRelated[0]?.page
										? ` ${routerHandler({
												typename: pageRelated[0]?.__typename,
												slug: pageRelatedLink(
													pageRelated[0]?.page,
													locale,
													pageRelated[0]?.sectionAnchor?.title
												),
												locale: locale,
										  })}`
										: `${pageRelated[0]?.externalUrl}`
								}`}
							>
								{pageRelated[0]?.buttonLabel?.label}
							</ButtonMaster>
						)}
					</div>
				</>
			);
			break;
		case "noCard":
			return (
				<>
					<div
						className={`${
							align == "center" ? "m-auto mb-12" : ""
						} w-full h-full  md:max-w-[75%] `}
					>
						<div
							style={style as React.CSSProperties}
							className={`  z-10 w-full  flex flex-col gap-8 relative`}
						>
							<div className="gap-4 flex flex-col">
								{label[0] && (
									<MultiPurposeTitle
										tag="p"
										type="default"
										data={label[1]}
										color={label[2]}
									/>
								)}
								<MultiPurposeTitle
									type="default"
									tag="h3"
									data={title}
									color={color}
								/>
								{underline && (
									<>
										{customUnderlineAsset?.img ? (
											<CustomUnderline
												shape={customUnderlineAsset?.img}
												color={underlineColor}
											/>
										) : (
											<Underline color={underlineColor} />
										)}
									</>
								)}
								<div className="">
									{description && (
										<Paragraph
											className="brandParagraph"
											layout={align}
											column={column}
											copy={description}
											color={color}
										/>
									)}
								</div>
							</div>
							{pageRelated?.length > 0 && !removeCTA && (
								<>
									{" "}
									{pageRelated[0].linksToIframe ? (
										<ButtonMaster
											onClick={() => loadIframe()}
											anim={"slideLeft"}
											type={pageRelated[0]?.buttonStyle}
											cta={pageRelated[0]?.typeColor?.color}
											color={pageRelated[0]?.labelColor?.color}
											customIcon={pageRelated[0]?.icon?.img}
											customColor={pageRelated[0]?.iconColor?.color}
											target={``}
											link={``}
										>
											{pageRelated[0]?.buttonLabel?.label}
										</ButtonMaster>
									) : (
										<>
											<ButtonMaster
												anim={"slideLeft"}
												type={pageRelated[0]?.buttonStyle}
												cta={pageRelated[0]?.typeColor?.color}
												color={pageRelated[0]?.labelColor?.color}
												customIcon={pageRelated[0]?.icon?.img}
												customColor={pageRelated[0]?.iconColor?.color}
												target={`${pageRelated[0]?.page ? `_self` : `_blank`}`}
												link={` ${
													pageRelated[0]?.page
														? ` ${routerHandler({
																typename: pageRelated[0]?.__typename,
																slug: pageRelatedLink(
																	pageRelated[0]?.page,
																	locale,
																	pageRelated[0]?.sectionAnchor?.title
																),
																locale: locale,
														  })}`
														: `${pageRelated[0]?.externalUrl}`
												}`}
											>
												{pageRelated[0]?.buttonLabel?.label}
											</ButtonMaster>
										</>
									)}
								</>
							)}
						</div>
						{click && (
							<div className="absolute w-full left-0 top-0 max-h-[420px] z-[20]">
								{" "}
								{pageRelated[0].linksToIframe && (
									<>
										{pageRelated[0]?.iframeType == "store" && (
											<StoreLocatorIframe />
										)}
									</>
								)}
							</div>
						)}
					</div>
				</>
			);
			break;
		default:
			return <></>;
			break;
	}
};

export const FeaturedCopyCard = ({
	title,
	color,
	underline,
	underlineColor,
	description,
	cardColor,
	pageRelated,
	customUnderlineAsset,
	column,
	label,
}: Props) => {
	const { locale }: any = useRouter();
	const style = {
		"--color": color?.hex,
		"--cta": `${pageRelated && `${pageRelated[0]?.buttonStyleColor?.hex}`}`,
		"--bg": cardColor?.hex,
	} as React.CSSProperties;

	return (
		<>
			<div className="card max-w-[500px] relative ">
				<div
					style={style as React.CSSProperties}
					className={`${styles.cardContainer}  z-10 w-full  flex flex-col gap-8 relative`}
				>
					<div className="gap-2 flex flex-col">
						{label && (
							<MultiPurposeParagraph
								type="label"
								data={label[1]}
								color={label[2]}
							/>
						)}
						<div className="flex flex-col gap-2 lg:gap-4">
							<MultiPurposeTitle
								tag="h3"
								type="headline"
								data={title}
								color={color}
							/>
							{underline && (
								<>
									{customUnderlineAsset?.img ? (
										<CustomUnderline
											shape={customUnderlineAsset?.img}
											color={underlineColor}
										/>
									) : (
										<Underline color={underlineColor} />
									)}
								</>
							)}
						</div>
						{description && (
							<Paragraph
								className="brandParagraph"
								column={column}
								copy={description}
								color={color}
								layout={undefined}
							/>
						)}
					</div>
					{pageRelated && (
						<>
							{pageRelated[0].linksToProductPage ? (
								<>
									{" "}
									<ButtonMaster
										anim={"slideLeft"}
										type={pageRelated[0]?.buttonStyle}
										cta={pageRelated[0]?.typeColor?.color}
										color={pageRelated[0]?.labelColor?.color}
										customIcon={pageRelated[0]?.icon?.img}
										customColor={pageRelated[0]?.iconColor?.color}
										link={`${routerHandler({
											typename: pageRelated[0]?.product.product.__typename,
											slug: [
												pageRelated[0]?.portfolioConfiguration?.slug,
												pageRelated[0]?.product.product.productTypeSpecific
													?.slug,
												pageRelated[0]?.product.product.slug,
											],
											locale: locale,
										})}`}
									>
										{pageRelated[0]?.buttonLabel?.label}
									</ButtonMaster>
									;
								</>
							) : (
								<ButtonMaster
									anim={"slideLeft"}
									type={pageRelated[0]?.buttonStyle}
									cta={pageRelated[0]?.typeColor?.color}
									color={pageRelated[0]?.labelColor?.color}
									customIcon={pageRelated[0]?.icon?.img}
									customColor={pageRelated[0]?.iconColor?.color}
									link={` ${
										pageRelated[0]?.page
											? ` ${routerHandler({
													typename: pageRelated[0]?.__typename,
													slug: pageRelatedLink(
														pageRelated[0]?.page,
														locale,
														pageRelated[0]?.sectionAnchor?.title
													),
													locale: locale,
											  })}`
											: `${pageRelated[0]?.externalUrl}`
									}`}
								>
									{pageRelated[0]?.buttonLabel?.label}
								</ButtonMaster>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

interface TextProps {
	data: any;
	layout: string;
	ctaLayout?: string;
	column?: string;
	portfolioSlug?: string;
	placeEnd?: boolean;
}

export const TextCopyCard = ({
	data,
	layout,
	placeEnd,
	ctaLayout,
}: TextProps) => {
	const { locale }: any = useRouter();
	return (
		<article
			className={`flex flex-col gap-4 md:gap-8 relative z-[1]  ${
				data.textColumnLayout === "single" && !data.blockColumnLayout
					? "lg:max-w-[50%]"
					: "max-w-full"
			} ${
				data.mobileTextAlignment == "center"
					? "items-center text-center lg:text-left lg:items-start"
					: ""
			} `}
		>
			{data.title && (
				<div
					className={`flex flex-col gap-4 ${
						layout == "fixedCenter" ? "items-center" : ""
					}`}
				>
					{data.addLabel && (
						<>
							{data.textLabel && (
								<MultiPurposeParagraph
									type="label"
									data={data.textLabel}
									color={data.textLabelColor?.color || data.textColor?.color}
								/>
							)}
						</>
					)}

					{data.title && (
						<div className="flex flex-col w-fit gap-4">
							<MultiPurposeTitle
								tag="h3"
								type="default"
								data={data.title}
								color={data.titleColor?.color || data.textColor?.color}
							/>

							{data.addTitleUnderline && (
								<>
									{data.customUnderlineAsset?.img ? (
										<CustomUnderline
											shape={data.customUnderlineAsset?.img}
											color={data.underlineColor?.color}
										/>
									) : (
										<Underline color={data.underlineColor?.color} />
									)}
								</>
							)}
						</div>
					)}
				</div>
			)}

			{data.subtitle && (
				<MultiPurposeTitle
					tag="p"
					type="featured"
					data={data.subtitle}
					color={data.titleColor?.color || data.textColor?.color}
				/>
			)}

			<div
				className={`${
					data.textColumnLayout !== "single"
						? `${styles.doubleColumn}`
						: `${styles.singleColumn}`
				}`}
			>
				{data.text ||
					(data.content && (
						<MultiPurposeParagraph
							type={"default"}
							titleColor={data.titleColor?.color}
							data={data.content || data.text}
							color={data.textColor?.color}
						/>
					))}

				{data.pageRelated?.length > 0 && (
					<div
						className={`btnContainer flex ${
							ctaLayout === "flexCol" && "flex-col"
						} gap-2 mt-8 ${layout == "fixedCenter" ? "justify-center" : ""}`}
					>
						{data.pageRelated.map((related: any, index: number) => {
							if (related.linksToProductPage) {
								const portfolioSlug = getPortfolioSlug(
									related?.portfolioConfiguration,
									locale
								);

								return (
									<ButtonMaster
										key={index}
										anim={"slideLeft"}
										type={related.buttonStyle}
										cta={related.typeColor?.color}
										color={related.labelColor?.color}
										customIcon={related.icon?.img}
										customColor={related.iconColor?.color}
										link={`${routerHandler({
											typename: related.product.product.__typename,
											slug: [
												portfolioSlug,
												related.product.product.productTypeSpecific?.slug,
												related.product.product.slug,
											],
											locale: locale,
										})}`}
									>
										{related?.buttonLabel?.label}
									</ButtonMaster>
								);
							} else {
								return (
									<ButtonMaster
										key={index}
										anim={"slideLeft"}
										type={related.buttonStyle}
										cta={related.typeColor?.color}
										color={related.labelColor?.color}
										customIcon={related.icon?.img}
										customColor={related.iconColor?.color}
										link={` ${
											related.page
												? ` ${routerHandler({
														typename: related.__typename,
														slug: pageRelatedLink(
															related.page,
															locale,
															related.sectionAnchor?.title
														),
														locale: locale,
												  })}`
												: `${related.externalUrl}`
										}`}
									>
										{related?.buttonLabel?.label}
									</ButtonMaster>
								);
							}
						})}
					</div>
				)}
			</div>
			{placeEnd && (
				<>
					{/* The title is already rendered above, so you can remove it here */}
				</>
			)}
		</article>
	);
};

interface FeaturedProps {
	title: string;
	fontColor: any[any];
	content: string;
	pageRelated?: any;
	link?: string;
	options?: any;
}

export const FeaturedCard = ({
	title,
	fontColor,
	content,
	link,
	options,
}: FeaturedProps) => {
	const { t } = useTranslation();
	return (
		<a href={link} target="_blank">
			<article className={`${styles.featuredBanner} `}>
				<MultiPurposeTitle
					tag="p"
					type="headlineAlt"
					data={title}
					layout={"fixedCenter"}
					color={fontColor}
				/>
				<MultiPurposeParagraph
					type="default"
					data={content}
					color={fontColor}
				/>

				<ButtonMaster
					anim={"slideLeft"}
					type={options.buttonStyle}
					cta={options.typeColor?.color}
					color={options.labelColor?.color}
					customIcon={options?.icon?.img}
					customColor={options?.iconColor?.color}
					link={``}
				>
					{t(options?.buttonLabelText?.label)}
				</ButtonMaster>
				<span></span>
			</article>
		</a>
	);
};
