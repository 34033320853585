import { MultiPurposeParagraph } from "@/components/Atoms/Paragraph";
import { MultiPurposeTitle } from "@/components/Atoms/Title";
import { ButtonMaster } from "@/components/Atoms/Buttons";

interface ArticleCardProps {
  layout: string;
  post: any;
  cta: any;
  color: any;
  excerpt: any;
  className?: string;
  highlight?: any;
}

export default function ArticleCard({
  layout,
  post,
  color,
  excerpt,
  cta,
  highlight,
  className,
}: ArticleCardProps) {
  switch (layout) {
    case "cardSmall": {
      return (
        <article
          style={{ backgroundColor: `${highlight?.color.hex}` }}
          className={`col-span-2  p-8 gap-6 flex flex-col justify-between ${className} md:min-h-[25rem]`}
        >
          <div className="flex flex-col gap-2">
            <MultiPurposeTitle
              tag="p"
              type="excerpt"
              data={post.title}
              color={color?.color}
            />
            <MultiPurposeParagraph
              type="label"
              align={"left"}
              clamp={true}
              data={post.excerpt}
              color={excerpt?.color}
            />
          </div>
          <ButtonMaster
            type={cta?.buttonStyle}
            cta={cta?.typeColor?.color}
            color={cta?.labelColor?.color}
            customIcon={cta?.icon?.img ?? cta?.icon}
            customColor={cta?.iconColor?.color}
            link={`news/${post.slug}
				`}
          >
            {cta?.buttonLabel?.label}
          </ButtonMaster>
        </article>
      );
      break;
    }
    case "card":
      return (
        <article
          style={{ backgroundColor: `${highlight?.color.hex}` }}
          className={`col-span-2  p-8 gap-6 flex flex-col ${className}`}
        >
          <MultiPurposeTitle
            tag="h4"
            type="featured"
            data={post.title}
            color={color?.color}
          />
          <MultiPurposeParagraph
            type="default"
            align={"left"}
            clamp={true}
            data={post.excerpt}
            color={excerpt?.color}
          />
          <ButtonMaster
            type={cta?.buttonStyle}
            cta={cta?.typeColor?.color}
            color={cta?.labelColor?.color}
            customIcon={cta?.icon?.img ?? cta?.icon}
            customColor={cta?.iconColor?.color}
            link={`news/${post.slug}
				`}
          >
            {cta?.buttonLabel?.label}
          </ButtonMaster>
        </article>
      );
      break;
    case "default":
      return (
        <article className="col-span-2 p-4 px-6 gap-2 flex flex-col">
          <MultiPurposeTitle
            tag="p"
            type="featured"
            data={post.title}
            color={color?.color}
          />

          <MultiPurposeParagraph
            type="default"
            data={post.excerpt}
            color={excerpt?.color}
          />
          <ButtonMaster
            type={cta?.buttonStyle}
            cta={cta?.styleColor?.color}
            color={cta?.labelColor?.color}
            customIcon={cta?.icon?.img ?? cta?.icon}
            customColor={cta?.iconColor?.color}
            link={`news/${post.slug}
				`}
          >
            {cta?.buttonLabel?.label}
          </ButtonMaster>
        </article>
      );
      break;
    default:
      return <></>;
      break;
  }
}
