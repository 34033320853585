import TextImageHalf from "./TextImageHalf";
import TextImageFull from "./TextImageFull";
import React, { useMemo } from "react";
import { TextImageProps } from "types/types";


const SectionTextImage: React.FC<TextImageProps> = (data) => {
  // Create a stable key for forcing re-render when layout changes
  const layoutKey = useMemo(() => {
    return `${data.textAlignment}-${data.textColumnLayout}-${Date.now()}`;
  }, [data.textAlignment, data.textColumnLayout]);

  return (
    <div key={layoutKey} className="relative">
      {data.blockColumnLayout ? (
        <TextImageHalf
          contentHeight={data.contentHeight}
          useSectionMargins={data.useSectionMargins}
          useExtraTopMargins={data.useExtraTopMargins}
          useExtraPadding={data.useExtraPadding}
          radius={data.contentBorderRadius}
          data={data}
        />
      ) : (
        <TextImageFull data={data} />
      )}
    </div>
  );
};

export default SectionTextImage;

export const fragment = `
    fragment TextImageFragment on SectionTextImageRecord{
      id
      title
      subtitle
      content
      externalUrl
      ctaUrl
      ctaLabel
      ctaLink {
        __typename
        ... on SograpeBrandPageRecord {
          id
          slug
        }
      }
      geo {
        countryAvailability
        listOfCountries
        countries {
          countryCode
        }
      }
 
      loop
      muted
      controls
      videoPreview
      autoplay
      addBackground
      backgroundType
      useExtraPadding
      useSmallerContainer
      copyPlacement
      useExtraTopMargins
      addLabel
      textLabel
      placeTitleEndOfContent
      useSectionMargins
      blockColumnLayout
      contentBorderRadius
      mainAssetHeight
      parallaxImagePosition
      mobileColumnDisplay
      addSpacer
      spacerConfiguration {
        id
        spacerDirection
        spacerPosition
        customShape {
          img {
            alt
            url
            width
            height
          }
        }
        addIconElement
        spacerIcon {
          img {
            alt
            url
            width
            height
          }
        }
      }
      imagePosition

      titleColor{
        color{
          hex
        }
      }
      textColor {
        color {
          hex
          alpha
        }
      }
      textLabelColor {

        color {
          hex
          alpha
        }
      }
      bgColor {
  
        color {
          hex
          alpha
        }
      }
      underlineColor {
        color {
          hex
          alpha
        }

      }
      assetLabelColor {
  
        color {
          hex
          alpha
        }
      }
      layout
      blockColumnLayout
      assetLabelColor {
 
        color {
          hex
          alpha
        }
      }
      bgColor {

        color {
          hex
          alpha
        }
      }
      textColor {
 
        color {
          hex
          alpha
        }
      }
      textLabelColor {

        color {
          hex
          alpha
        }
      }

      contentHeight
      textColumnLayout
      ctaLayout
      addOverlayParallaxImage
      parallaxImage {
        format
        responsiveImage {
          ... responsiveImageFragment
        }
      }

      addTitleUnderline
      customUnderlineAsset {
        img {
          url
          width
          height
        }
      }
      underlineColor {
        color {
          hex
          alpha
        }
      }
      backgroundImage {
        responsiveImage (imgixParams: { w: 1650, fit: crop, crop: focalpoint, auto:format, q:85}) {
          ...responsiveImageFragment
        }
        url
        format
        width
        height
      }
      textAlignment
      mobileTextAlignment
      showLabel
      pageRelated {
       ...pageRelatedFragment
      }
      image {
        url
        format
        video {
          mp4Url
          muxAssetId
          thumbnailUrl
          streamingUrl
          mp4High: mp4Url(res: high)
          mp4Med: mp4Url(res: medium)
          mp4Low: mp4Url(res: low)
          duration
          framerate
          thumbJpg: thumbnailUrl(format: jpg)
          thumbPng: thumbnailUrl(format: png)
          thumbGif: thumbnailUrl(format: gif)
        }
        responsiveImage (imgixParams: { h: 900, fit: crop, crop: focalpoint, auto:format, q:85}) {
          ...responsiveImageFragment
        }
        title
    }
  }
`;

