import {
  ImageLabel,
  Label,
  MultiPurposeParagraph,
} from "@/components/Atoms/Paragraph";

interface Props {
  label: string;
  value: any;
  color: any;
  bg: any;
  labelColor: any;
  layout: string;
}

export default function OverviewCard({
  label,
  value,
  color,
  bg,
  labelColor,
  layout,
}: Props) {
  return (
    <div
      style={{ backgroundColor: `${bg?.hex}` }}
      className={`${
        layout == "cocktail" ? "rounded-xl overflow-hidden" : ""
      } px-4  py-2 flex-auto`}
    >
      <div className="flex flex-col text-center">
        <MultiPurposeParagraph data={label} color={labelColor} />
        <MultiPurposeParagraph
          data={`${value}${label == "nutrition" ? " cal." : ""}`}
          color={color}
        />
      </div>
    </div>
  );
}
