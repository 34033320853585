import classes from "./index.module.scss";
import Link from "next/link";
import { string_to_slug } from "@/lib/functions";

interface Props {
  bg: any;
  text: any;
  style: any;
  productsDefinition: any;
}

export default function AnchorsPortfolio({
  bg,
  text,
  style,
  productsDefinition,
}: Props) {
  const styles = {
    "--color": text?.color.hex,
    "--border-color": style?.color.hex,
  } as React.CSSProperties;

  const anchorExecuteScroll = (e: any) => {
    e.preventDefault();
    document
      .getElementById(e.currentTarget.hash.slice(1))!
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      style={{ backgroundColor: bg?.color.hex }}
      className="w-full px-12 md:px-0 pt-12"
    >
      <div className="container mx-auto flex gap-4 pb-4 overflow-auto ">
        {productsDefinition.map((entry: any, i: number) => {
          return (
            <Link href={`./#${string_to_slug(entry.product.slug)}`} key={i}>
              <a
                style={styles}
                onClick={anchorExecuteScroll}
                className={`${classes.borderfull} py-2 px-4 rounded-sm secondary shrink-0`}
              >
                {entry.label != undefined && entry.label != ""
                  ? entry.label
                  : entry.product.productName}
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
